import { MeusDadosComponent } from './meus-dados.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ImageCropperModule } from 'ngx-image-cropper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentesModule } from '../componentes/componentes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { NgxMaskModule } from 'ngx-mask';
import { NgxViacepModule } from '@brunoc/ngx-viacep';
import { DarkenOnHoverModule } from '../../_diretivas/darken-on-hover/darken-on-hover.module';
import { DiretivasModule } from 'app/_diretivas/diretivas.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppRoutingModule } from 'app/app.routing';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { AlterarPerfilComponent } from './alterar-perfil/alterar-perfil/alterar-perfil.component';
import { AlterarSenhaComponent } from './alterar-senha/alterar-senha/alterar-senha.component';
import { DadosPagamentoComponent } from './dados-pagamento/dados-pagamento.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule,
        MatCardModule,
        MatExpansionModule,
        MatInputModule,
        MatButtonModule,
        MatDialogModule,
        FormsModule,
        FlexLayoutModule,
        MatMomentDateModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSelectModule,
        NgxMaskModule,
        NgxViacepModule,
        DarkenOnHoverModule,
        TranslateModule,
        ComponentesModule,
        DiretivasModule,
        MatSidenavModule,
        MatTableModule,
        MatTabsModule,
        MatSelectModule,
        MatTooltipModule,
        AppRoutingModule,
        MatStepperModule,
        CdkStepperModule,
        MatRadioModule,
        MatCheckboxModule,
        ImageCropperModule,
    ],
    declarations: [AlterarPerfilComponent, AlterarSenhaComponent, DadosPagamentoComponent, MeusDadosComponent],
    exports: [AlterarPerfilComponent],
    entryComponents: [AlterarPerfilComponent, AlterarSenhaComponent, DadosPagamentoComponent],
})
export class MeusDadosModule {}
