import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ListaColunas, ListaAcoes, orderByColunasByPosition } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { DialogService } from 'app/_services/dialogs/dialog.service';
import { FuseUtils } from '@fuse/utils';
import { WIDTH_XS } from 'app/_constants/tamanhos-telas';
import { dataExtenso } from 'app/_functions/data-extenso';
import { Indicadores } from 'app/main/componentes/tabela-com-filtro-indicadores/indicadores';
import { SubMenu } from 'app/_models/configuracoes/financeiro/submenus';
import { Indicacoes } from 'app/_models/conta-profissional';
import { Conversoes } from 'app/_models/conta-profissional/conversoes';
import { ListaColunasAcoesEvento } from '../lista-colunas-acoes/lista-colunas-acoes-evento.model';

@Component({
    selector: 'tabela-com-filtro-indicadores',
    templateUrl: './tabela-com-filtro-indicadores.component.html',
    styleUrls: ['./tabela-com-filtro-indicadores.component.scss'],
})
export class TabelaComFiltroIndicadoresComponent implements OnInit {
    @Input() public colunas: Array<ListaColunas>;
    @Input() public acoes: Array<ListaAcoes>;
    @Input() public itens: Array<Indicacoes | Conversoes>;
    @Input() public subMenus: Array<SubMenu>;
    @Input() public indicadores: Array<Indicadores>;
    @Input() public carregamentoInicial: boolean;
    @Input() public exibirBtnExportar: boolean = true;
    @Input() public exibirBtnResgate: boolean = false;
    @Input() public exibirBtnInstrucao: boolean = true;
    @Input() private mensagemIntrucao: string;
    @Input() public funcaoAcoesLinha: CallableFunction;
    @Output() private solictarResgate: EventEmitter<null> = new EventEmitter();
    @Output() private exportarPdf: EventEmitter<Array<Indicacoes | Conversoes>> = new EventEmitter();
    @Output() private exportarExcel: EventEmitter<Array<Indicacoes | Conversoes>> = new EventEmitter();
    @Output() public retornoAcoesLinha: EventEmitter<any> = new EventEmitter();
    @Output() eventoAcao: EventEmitter<ListaColunasAcoesEvento> = new EventEmitter();
    public itensFiltrados: Array<Indicacoes | Conversoes>;

    constructor(private _dialogService: DialogService) {}

    ngOnInit(): void {
        this.colunas = orderByColunasByPosition(this.colunas);
        this.prepararItensParaExibicao();
        this.itensFiltrados = this.itens;
    }

    public openInformacoesIndicadores(): void {
        this._dialogService.dialogInformacaoHtml('', `${this.mensagemIntrucao}`, 'auto', '50%');
    }

    public setIndicacoesFiltradas(query: string): void {
        const filtro = FuseUtils.filterArrayByString(this.itens, query);
        this.itensFiltrados = filtro;
    }

    private prepararItensParaExibicao(): void {
        this.itens.forEach((item) => {
            const nome = item.nome.length > 26 ? item.nome.substr(0, 26 - 3) + '...' : item.nome;

            if (item.caminhoFoto && window.innerWidth > WIDTH_XS) {
                item.nomeFoto =
                    '<span style="display:none;">' +
                    nome.toUpperCase() +
                    '</span><span><img class="avatar" src="' +
                    item.caminhoFoto +
                    '">' +
                    nome +
                    '</span>';
            } else {
                item.nomeFoto = nome.toUpperCase();
            }

            if (!!item && !!item.nomeAfiliado) {
                item.nomeAfiliado = this._primeiraLetraUppCase(item.nomeAfiliado);
            }

            if (!!item && !!item.nome) {
                item.nome = this._primeiraLetraUppCase(item.nome);
            }
        });
    }

    public exportarParaPdf(): void {
        this.exportarPdf.emit(this.itensFiltrados);
    }

    public exportarParaExcel(): void {
        this.exportarExcel.emit(this.itensFiltrados);
    }

    public solicitarResgate(): void {
        this.solictarResgate.emit(null);
    }

    public eventoDeAcao(evento: ListaColunasAcoesEvento): void {
        this.eventoAcao.emit(evento);
    }

    private _primeiraLetraUppCase(texto: string): string {
        var words = texto.toLowerCase().split(' ');
        for (var a = 0; a < words.length; a++) {
            var w = words[a];
            if (w.length > 0) {
                words[a] = w[0].toUpperCase() + w.slice(1);
            }
        }
        return words.join(' ');
    }
}
