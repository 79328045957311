<tabela-com-filtro-indicadores
    *ngIf="!this.carregamentoInicial"
    [colunas]="this.colunas"
    [acoes]="this.acoes"
    [subMenus]="this.subMenus"
    [itens]="this.itens"
    [indicadores]="this.indicadores"
    [mensagemIntrucao]="this.mensagemInstrucao"
    (exportarPdf)="this.exportarParaPdf($event)"
    (exportarExcel)="this.exportarParaXLSX($event)"
>
</tabela-com-filtro-indicadores>

<div *ngIf="this.carregamentoInicial">
    <div fxLayout="row" fxLayoutAlign="center center" class="mt-40">
        <mat-spinner></mat-spinner>
    </div>
</div>
