<div class="navbar-top" [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
    <div class="logo">
        <img class="logo-icon" src="assets/images/logos/afiliados_128_quadrado.png" />
        <span class="logo-text secondary-text">Afiliados Amplum</span>
    </div>

    <div class="buttons">
        <button mat-icon-button class="toggle-sidebar-opened" (click)="toggleSidebarOpened()" fxHide.gt-md>
            <mat-icon class="secondary-text">arrow_back</mat-icon>
        </button>
    </div>
</div>

<div
    class="navbar-scroll-container"
    [ngClass]="fuseConfig.layout.navbar.primaryBackground"
    fusePerfectScrollbar
    [fusePerfectScrollbarOptions]="{ suppressScrollX: true }"
>
    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>
    </div>

    <div class="logo" fxFlex="100" fxLayout="row" fxLayoutAlign="start end">
        <div class="logo-whatsapp" fxLayout="row" (click)="this.abrirWhatsapp()">
            <img class="h-40 w-40 m-16 contato-whatsapp logo-icon" src="assets/icons/Whatsapp_original.svg" />
            <span class="logo-text secondary-text mt-28">Fale conosco</span>
        </div>
    </div>
</div>
