<card-com-header-footer class="w-100-p">
    <div class="icone-padrao">attach_file</div>
    <div class="titulo-padrao">{{ 'TITULOS.MATERIAL_APOIO' | translate }}</div>
    <div class="body-card-padrao">
        <lista-colunas-acoes
            fxFlex="100"
            [colunas]="this.colunas"
            [acoes]="this.acoes"
            [dados]="this.listagemMaterial"
            [funcaoAcoesLinha]="this.getAcoesLinha"
            (eventoAcao)="this.acao($event)"
        ></lista-colunas-acoes>
    </div>
    <div class="footer-card-padrao" fxFlex="100" fxLayoutAlign="end end">
        <div fxLayout="row">
            <div fxFlex="100" *ngIf="this.mostrarBtnInserir">
                <button id="btn-exportar" color="accent" mat-raised-button (click)="this.modalIncluir()">
                    {{ 'BOTOES.INSERIR' | translate }}
                </button>
            </div>
        </div>
    </div>
</card-com-header-footer>
