import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CapturarFotoService } from '../capturar-foto/capturar-foto.service';
import { Platform } from '@angular/cdk/platform';
import { locale as portugues } from '../../../_i18n/pt-br';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ToastService } from 'app/_services';
import { Foto } from '../../../_models/alterar-foto/alterar-foto.model';
import { IAlterarFotoService } from 'app/_models/alterar-foto/alterar-foto.interface';
import { ModoSalvar } from 'app/_models/enums/modo-salvar';

@Component({
    selector: 'alterar-foto',
    templateUrl: './alterar-foto.component.html',
    styleUrls: ['./alterar-foto.component.css']
})
export class AlterarFotoComponent<TService extends IAlterarFotoService> implements OnInit {
    /**
     * Constructor
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: Foto<TService>,
        public dialogRef: MatDialogRef<AlterarFotoComponent<TService>>,
        private _toastService: ToastService,
        private _capturarFotoService: CapturarFotoService,
        private _platform: Platform,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService
    ) {
        if (this._platform.ANDROID || this._platform.IOS) {
            this.isMobile = true;
        }
        this._fuseTranslationLoaderService.loadTranslations(portugues);
    }

    public titulo: string;
    public src: string;
    public imgBase64: string;
    public imageReady: boolean = false;
    public capturarFoto: boolean = false;
    public isMobile: boolean;
    public errorsCamera: boolean = false;
    imageChangedEvent: any = '';
    uploadImage: File;
    public imagemLogoMarca: boolean = false;
    public width: string;

    ngOnInit(): void {
        this.width = this.data.width ? this.data.width : '150px';

        this._capturarFotoService.imageBase64.subscribe(imgBase64 => {
            this.imgBase64 = `data:image/png;base64,${imgBase64}`;
        });

        this.src = this.data.src;
        this.titulo = this.data.titulo;
        this.verificaErrosCamera();
        this.verificarRota();
    }

    verificarRota(): void {
        if (this.data.logoMarca !== null && this.data.logoMarca) {
            this.imagemLogoMarca = !this.imagemLogoMarca;
        }
    }

    fileChangeEvent(event: any): void {
        this.imgBase64 = event.logoBase64;
        this.imageChangedEvent = event;
    }

    uploadFoto(): void {
        this.uploadImage = this._capturarFotoService.getFile();
        if (this.uploadImage) {
            const formData = new FormData();
            formData.append('file', this.uploadImage as File, this.uploadImage.name);
            if (this.data.modoSalvar === ModoSalvar.Put) {
                this.data.service.uploadImagem(formData, this.data.id).subscribe(
                    dados => {
                        this._toastService.mensagemSuccess('Foto alterada com sucesso!');
                        this.data.service.setImagem(dados.caminhoImagem);
                    },
                    err => {
                        this._toastService.mensagemError('Foto com problemas para ser alterada!');
                        console.log(err);
                    }
                );
            } else {
                this.data.service.setFormData(formData);
                this.data.service.setImagem(this.imgBase64);
            }
            this.uploadImage = null;
            const data = {
                form: formData,
                imgBase64: this.imgBase64
            };
            this.dialogRef.close(data);
            this._capturarFotoService.cleanFile();
        } else {
            this._toastService.mensagemInfo('Por favor, selecione uma foto!');
        }
    }

    imageCropped(event: ImageCroppedEvent): void {
        this.imgBase64 = event.base64;
        this._capturarFotoService.base64ToImageFile(event.base64);
    }

    imageLoaded(): void {
        this.imageReady = true;
    }

    setCaptura(): void {
        this.capturarFoto = !this.capturarFoto;
    }

    verificaErrosCamera(): void {
        this._capturarFotoService.getErrorsCamera().subscribe(errors => {
            if (errors) {
                this.capturarFoto = false;
                this.errorsCamera = true;
            }
        });
    }
}
