<div class="page-layout carded fullwidth tabbed inner-scroll mt-12">
    <div class="center">
        <div class="content-card mb-12">
            <nav id="navbar-configuracoes" mat-tab-nav-bar backgroundColor="warn">
                <a
                    mat-tab-link
                    *ngFor="let link of navLinks"
                    [routerLink]="link.path"
                    routerLinkActive
                    #rla="routerLinkActive"
                    [active]="rla.isActive"
                >
                    {{ link.label }}
                </a>
            </nav>

            <div class="corpo-card-config-com-nav-bar">
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
