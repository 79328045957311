import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
    selector: 'filtro-lista',
    templateUrl: './filtro-lista.component.html',
})
export class FiltroListaComponent implements OnInit, OnDestroy {
    @Input() possuiBotaoPesquisa: boolean;
    @Input() placeholderFiltro: string = 'Filtrar';
    @Input() limparFiltro: Observable<void>;
    @Output() textoInput: EventEmitter<string> = new EventEmitter();
    @Output() textoInputBotao: EventEmitter<string> = new EventEmitter();

    public inputFiltro: FormControl;

    constructor() {
        this.inputFiltro = new FormControl('');
    }

    ngOnInit(): void {}

    public filtrarTexto(filterValue: string): void {
        this.textoInput.emit(filterValue);
    }

    public filtrarTextoBotao(filterValue: string): void {
        this.textoInputBotao.emit(filterValue);
    }

    ngOnDestroy(): void {}
}
