<div mat-dialog-title class="title">
    <div id="alterar-pagamento-titulo" fxLayout="row">
        <span>{{ 'TITULOS.DETALHES_PAGAMENTO' | translate }}</span>
    </div>
</div>

<div mat-dialog-content>
    <form id="alterar-pagamento-form" name="form" [formGroup]="this.form">
        <div fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div class="mb-20" fxFlex="18" fxFlex.xs="49" fxFlex.lt-lg="24" fxLayout="column" fxLayoutAlign="space-between center">
                <div matTooltip="{{ this.dadosPagamento.afiliadoNome }}">
                    <b>{{ this.retornarNomeAfiliado() }}</b>
                </div>
                <div *ngIf="this.ehCpf()">
                    <b>{{ this.dadosPagamento.afiliadoCpf | mask: '000.000.000-00' }}</b>
                </div>
                <div *ngIf="!this.ehCpf()">
                    <b>{{ this.dadosPagamento.afiliadoCpf | mask: '00.000.000/0000-00' }}</b>
                </div>
            </div>

            <div class="mb-20" fxFlex="15" fxFlex.lt-lg="24" fxFlex.xs="49" fxLayout="column" fxLayoutAlign="space-between center">
                <div>
                    {{ 'LABELS.TOTAL_BONUS' | translate }}
                </div>
                <div>
                    <b>{{ this.formatarTotalBonus() }}</b>
                </div>
            </div>

            <!-- Data pagamento -->
            <mat-form-field ngClass.xs="mt-8" appearance="outline" fxFlex="21" fxFlex.lt-lg="49" fxFlex.xs="100">
                <mat-label>{{ 'LABELS.DATA_PAGAMENTO' | translate }}</mat-label>
                <input
                    id="input-data-pagamento"
                    matInput
                    required
                    [matDatepicker]="picker"
                    formControlName="dataPagamento"
                    maxlength="20"
                    (keypress)="this.onKeyPressDataPagamento($event)"
                    [min]="this.dataMinima"
                />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

                <mat-error id="error-data-pagamento-obrigatorio" *ngIf="form.get('dataPagamento').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>

                <mat-error id="error-data-pagamento" *ngIf="this.form.get('dataPagamento').hasError('matDatepickerMin')">
                    {{ 'FORM.VALIDACOES.DATA_MINIMA' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Forma pagamento -->
            <mat-form-field id="input-forma-pagamento" appearance="outline" fxFlex="21" fxFlex.lt-lg="49" fxFlex.xs="100">
                <mat-label>{{ 'LABELS.FORMA_PAGAMENTO' | translate }}</mat-label>
                <mat-select required formControlName="formaPagamentoId">
                    <mat-option *ngFor="let formaPagamento of this.dadosPagamento.formasPagamento" [value]="formaPagamento.id">
                        {{ formaPagamento.descricao }}
                    </mat-option>
                </mat-select>
                <mat-error id="error-forma-pagamento-obrigatorio" *ngIf="form.get('formaPagamentoId').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>

            <!-- Nota Fiscal -->
            <mat-form-field appearance="outline" fxFlex="21" fxFlex.lt-lg="49" fxFlex.xs="100">
                <mat-label> {{ 'LABELS.NOTA_FISCAL' | translate }} </mat-label>
                <input id="input-nota-fiscal" required matInput maxlength="25" type="text" formControlName="notaFiscal" />
                <mat-error id="error-nota-fiscal-obrigatorio" *ngIf="form.get('notaFiscal').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayoutAlign="start">
            <!-- Observacao -->
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label> {{ 'LABELS.OBSERVACAO' | translate }} </mat-label>
                <textarea id="input-observacao" matInput maxlength="300" type="text" formControlName="observacao"></textarea>
            </mat-form-field>
        </div>
    </form>

    <div fxLayout="row">
        <!-- BANCO -->
        <mat-form-field appearance="outline" floatLabel="always" fxFlex="49" fxFlex.lt-md="100" class="mr-10 mat-form-field-no-padding">
            <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.BANCO' | translate }}</mat-label>
            <input matInput [value]="this.dadosParaPagamento?.banco" disabled />
        </mat-form-field>
    </div>

    <div fxLayout="row">
        <!-- OPERACAO -->
        <mat-form-field appearance="outline" floatLabel="always" fxFlex="20" fxFlex.lt-md="100" class="mr-10 mat-form-field-no-padding">
            <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.OPERACAO' | translate }}</mat-label>
            <input matInput [value]="this.dadosParaPagamento?.operacao" disabled />
        </mat-form-field>
        <!-- AGENCIA -->
        <mat-form-field appearance="outline" floatLabel="always" fxFlex="15" fxFlex.lt-md="100" class="mr-10 mat-form-field-no-padding">
            <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.AGENCIA' | translate }}</mat-label>
            <input matInput [value]="this.dadosParaPagamento?.agencia" disabled />
        </mat-form-field>
        <!-- CONTA -->
        <mat-form-field appearance="outline" floatLabel="always" fxFlex="23" fxFlex.lt-md="100" class="mr-10 mat-form-field-no-padding">
            <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.CONTA' | translate }}/dígito</mat-label>
            <input matInput [value]="this.dadosParaPagamento?.conta" disabled />
        </mat-form-field>
        <!-- PIX -->
        <mat-form-field appearance="outline" floatLabel="always" fxFlex="39" fxFlex.lt-md="100" class="mat-form-field-no-padding">
            <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.PIX' | translate }}</mat-label>
            <input matInput [value]="this.dadosParaPagamento?.pix" disabled />
        </mat-form-field>
    </div>

    <div fxLayout="row">
        <!-- NOME -->
        <mat-form-field appearance="outline" floatLabel="always" fxFlex="49" class="mr-10 mat-form-field-no-padding" fxFlex.lt-md="100">
            <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.NOME_COMPLETO' | translate }}</mat-label>
            <input matInput [value]="this.dadosParaPagamento?.nomeCompleto" disabled />
        </mat-form-field>
        <!-- CPF/CNPJ -->
        <mat-form-field appearance="outline" floatLabel="always" fxFlex="50" fxFlex.lt-md="100" class="mat-form-field-no-padding">
            <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.CPF_TITULAR_CONTA' | translate }}</mat-label>
            <input matInput [mask]="this.mascaraCpfCnpj" [value]="this.dadosParaPagamento?.cpfTitular | mask: '000.000.000-00'" disabled />
        </mat-form-field>
    </div>
    <hr class="w-100-p" />
    <span class="mat-subheading-2">{{ 'LABELS.CONVERSOES_REALIZADAS' | translate }}</span>
    <div>
        <lista-colunas-acoes fxFlex="100" [colunas]="this.colunas" [dados]="this.dadosPagamento.conversoes"></lista-colunas-acoes>
    </div>
</div>

<salvar-cancelar-footer
    (cancelarEmitter)="this.dialogRef.close(false)"
    (salvarEmitter)="this.alterarPagamento()"
    [disabledSave]="this.fuseProgressBarService.visibleValue"
>
</salvar-cancelar-footer>
