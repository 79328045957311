<form id="intervalo-data-form" name="form" [formGroup]="form" fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
    <mat-form-field appearance="outline" [fxFlex.xs]="this.mesmaLinha ? '49' : '100'" fxFlex.gt-xs="49">
        <mat-label>{{ this.labelInicio }}</mat-label>
        <input
            id="data-intervalo-inicio"
            matInput
            [matDatepicker]="dataInicio"
            formControlName="dataInicio"
            startView="multi-year"
            required="{{ this.requiredInicio }}"
            maxlength="10"
            (keypress)="this.onKeyPressDataInicio($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="dataInicio"></mat-datepicker-toggle>
        <mat-datepicker #dataInicio></mat-datepicker>

        <mat-error id="data-inicio-obrigatoria" *ngIf="this.form.get('dataInicio').hasError('required')">{{
            'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate
        }}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" [fxFlex.xs]="this.mesmaLinha ? '49' : '100'" fxFlex.gt-xs="49">
        <mat-label>{{ this.labelFim }}</mat-label>
        <input
            matInput
            id="data-intervalo-fim"
            [matDatepicker]="dataFim"
            formControlName="dataFim"
            [min]="this.form.get('dataInicio').value"
            startView="multi-year"
            required="{{ this.requiredFim }}"
            maxlength="10"
            (keypress)="this.onKeyPressDataFim($event)"
        />
        <mat-datepicker-toggle matSuffix [for]="dataFim"></mat-datepicker-toggle>
        <mat-datepicker #dataFim></mat-datepicker>

        <mat-error id="data-fim-obrigatoria" *ngIf="this.form.get('dataFim').hasError('required')">{{
            'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate
        }}</mat-error>
        <mat-error id="data-fim-menor" *ngIf="this.form.get('dataFim').hasError('datafimMenor')">{{ this.erroDataFimMenor | translate }}</mat-error>
    </mat-form-field>
</form>
