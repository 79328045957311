import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, FormGroup } from '@angular/forms';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';
import { locale as portugues } from 'app/_i18n/pt-br';
import { SubMenuRestricao } from 'app/_models/global/sub-menus';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { AuthenticationService, ToastService, TraducaoService, DialogService } from 'app/_services';
import { FuseCopierService } from '@fuse/services/copier.service';
import { environment } from 'environments/environment';
import { User } from 'app/_models/dominio/user';
import { TermoUsoComponent } from 'app/authentication/registrar/termo-uso/termo-uso.component';
import { Overlay } from '@angular/cdk/overlay';
import { Router } from '@angular/router';
import { ContaUsuarioRolesControllerService } from 'app/_services/controllers/conta-usuario-roles-controller.service';
import { RdStationTag } from 'app/_models/enums/rd-station-tag.enum';
import { MeuConsultorioControllerService } from 'app/_services/controllers/meu-consultorio-controller.service';
import { ContaProfissionalControllerService } from 'app/_services/controllers/conta-profissional-controller.service';
import { AlterarPerfilComponent } from '../meus-dados/alterar-perfil/alterar-perfil/alterar-perfil.component';
import { ContaColaboradorControllerService } from 'app/_services/controllers/conta-colaborador-controller.service';
import { stringParaEnum } from 'app/_functions/converte-string-para-enum';

@Component({
    selector: 'inicio',
    templateUrl: './inicio.component.html',
    styleUrls: ['./inicio.component.scss'],
})
export class InicioComponent implements OnInit {
    public inputFiltro: FormControl;
    public background: string = 'warn';
    public form: FormGroup;
    public loading: boolean = false;
    public navLinks: Array<SubMenuRestricao>;

    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _formBuilder: FormBuilder,
        private _clipboard: FuseCopierService,
        private _toastService: ToastService,
        private _i18n: TraducaoService,
        private _authenticationService: AuthenticationService,
        private _dialogService: DialogService,
        private _overlay: Overlay,
        private _router: Router,
        private _contaProfissionalControllerService: ContaProfissionalControllerService,
        private _contaColaboradorControllerService: ContaColaboradorControllerService,
        private _contaUsuarioRolesControllerService: ContaUsuarioRolesControllerService,
        private _meuConsultorioControllerService: MeuConsultorioControllerService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(portugues);
        this.inputFiltro = new FormControl('');
        this.setNavLinks();
    }

    ngOnInit(): void {
        console.log(this.usuario.perfil);
        if (this.usuario.perfil != RoleUsuario.ADMINISTRADOR_AFILIADO) {
            this._router.navigate(['/inicio/indicacoes']);
        }
        this.formValidation();
        this.termoContrato();
        this.validarDadosObrigatoriosCadastro();
    }

    private get usuario(): User {
        return this._authenticationService.currentUserValue;
    }

    private get roleUsuarioMeuConsultorio(): RoleUsuario {
        if (!!this.usuario) {
            const stringValueEnum = this.usuario.customProperties.RoleMeuConsultorio;
            return stringParaEnum<typeof RoleUsuario, RoleUsuario>(RoleUsuario, stringValueEnum);
        }
    }

    private setNavLinks(): void {
        let labelAfiliados = 'Afiliados';
        let labelIndicacoes = this._i18n.get('LABELS.MINHAS_INDICACOES');
        let labelConversoes = this._i18n.get('LABELS.MINHAS_CONVERSOES');

        switch (this.usuario.perfil) {
            case RoleUsuario.ADMINISTRADOR_AFILIADO:
                labelAfiliados = 'Afiliados';
                labelIndicacoes = this._i18n.get('LABELS.INDICACOES');
                labelConversoes = this._i18n.get('LABELS.CONVERSOES');
                break;
        }

        this.navLinks = new Array<SubMenuRestricao>(
            {
                path: 'afiliados',
                label: labelAfiliados,
                perfisAcesso: [RoleUsuario.ADMINISTRADOR_AFILIADO],
                planoGratuito: false,
            },
            {
                path: 'indicacoes',
                label: labelIndicacoes,
                perfisAcesso: [RoleUsuario.ADMINISTRADOR_AFILIADO, RoleUsuario.AFILIADO],
                planoGratuito: false,
            },
            {
                path: 'conversoes',
                label: labelConversoes,
                perfisAcesso: [RoleUsuario.ADMINISTRADOR_AFILIADO, RoleUsuario.AFILIADO],
                planoGratuito: false,
            }
        );

        if (this.usuario.perfil != RoleUsuario.ADMINISTRADOR_AFILIADO) {
            this.navLinks.splice(0, 1);
        }
    }

    private termoContrato(): void {
        if (this.usuario.customProperties.ExibirTermoUso) {
            this._dialogService
                .dialogGenerico(TermoUsoComponent, {
                    scrollStrategy: this._overlay.scrollStrategies.noop(),
                    autoFocus: false,
                })
                .subscribe((aceito: boolean) => {
                    if (aceito) {
                        this._authenticationService.updateAposAceitarTermoUso();
                        this.formValidation();
                        this.incluirRoleUsuarioMeuConsultorio();
                        this.alterarRegistroRdStation();
                        this.validarDadosObrigatoriosCadastro();
                    } else {
                        this._router.navigate(['/login']);
                        this._authenticationService.logout();
                    }
                });
        }
    }

    private validarDadosObrigatoriosCadastro(): void {
        if (this.usuario.customProperties.ExibirConfirmacaoCadastro && !this.usuario.customProperties.ExibirTermoUso) {
            if (this.roleUsuarioMeuConsultorio === RoleUsuario.ADMIN) {
                this._contaProfissionalControllerService.selecionarContaProfissional().subscribe(
                    (res) => {
                        this._dialogService.dialogGenerico(AlterarPerfilComponent, {
                            data: {
                                afiliado: res,
                                currentUser: this._authenticationService.currentUserValue,
                                roleUsuarioMeuConsultorio: this.roleUsuarioMeuConsultorio,
                            },
                        });
                    },
                    (err) => {
                        console.log(err);
                        this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOGRUPOSYM'));
                    }
                );
            } else if (this.roleUsuarioMeuConsultorio === RoleUsuario.SECRETARIA) {
                this._contaColaboradorControllerService.selecionarContaColaborador().subscribe(
                    (res) => {
                        this._dialogService.dialogGenerico(AlterarPerfilComponent, {
                            data: {
                                afiliado: res,
                                currentUser: this._authenticationService.currentUserValue,
                                roleUsuarioMeuConsultorio: this.roleUsuarioMeuConsultorio,
                            },
                        });
                    },
                    (err) => {
                        console.log(err);
                        this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOGRUPOSYM'));
                    }
                );
            }
        }
    }

    private incluirRoleUsuarioMeuConsultorio(): void {
        const roleUsuarioMeuConsultorio: RoleUsuario = this._authenticationService.currentUserValue.customProperties.RoleMeuConsultorio;

        this._contaUsuarioRolesControllerService.incluirRoleParaClienteMeuConsultorio(roleUsuarioMeuConsultorio).subscribe(
            (res) => {},
            (err) => {
                console.log(`Erro ao incluir role usuário: ${err}`);
            }
        );
    }

    private alterarRegistroRdStation(): void {
        let roleUsuarioMeuConsultorio: RoleUsuario = this._authenticationService.currentUserValue.customProperties.RoleMeuConsultorio;

        if (!roleUsuarioMeuConsultorio) {
            roleUsuarioMeuConsultorio = RoleUsuario.AFILIADO;
        }

        this._meuConsultorioControllerService.incluirRegistroRdStation(RdStationTag.PARCEIRO_AFILIADO, roleUsuarioMeuConsultorio).subscribe(
            (res) => {},
            (err) => {
                console.log(`Erro cadastro RD Station: ${err}`);
            }
        );
    }

    public formValidation(): void {
        let codigoAfiliado = '';
        let linkDivulgacao = '';
        let exibirTermoUso = false;

        if (!!this.usuario) {
            exibirTermoUso = this.usuario.customProperties.ExibirTermoUso;

            if (!exibirTermoUso) {
                codigoAfiliado = this.usuario.customProperties.CodigoDivulgacao;
                linkDivulgacao = `${environment.linkDivulgacao}${codigoAfiliado}`;
            }
        }

        this.form = this._formBuilder.group({
            codigoAfiliado: [codigoAfiliado],
            linkDivulgacao: [linkDivulgacao],
        });
    }

    public copiarCodigoAfiliado(): void {
        this._clipboard.copyText(this.selecionarCodigoAfiliado());
        this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.CODIGO_AFILIADO_COPIADO'));
    }

    public copiarLinkDivulgacao(): void {
        this._clipboard.copyText(this.selecionarLinkDivulgacao());
        this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.LINK_DIVULGACAO_COPIADO'));
    }

    public selecionarLinkDivulgacao(): string {
        if (!this.usuario) {
            return '';
        }

        return this.form.get('linkDivulgacao').value;
    }

    public selecionarCodigoAfiliado(): string {
        if (!this.usuario) {
            return '';
        }

        return this.form.get('codigoAfiliado').value;
    }
}
