import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AjudaComponent } from './ajuda.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentesModule } from '../componentes/componentes.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatExpansionModule } from '@angular/material/expansion';

@NgModule({
    imports: [MatExpansionModule, CommonModule, MatIconModule, MatCardModule, MatTooltipModule, ComponentesModule, TranslateModule],
    declarations: [AjudaComponent]
})
export class AjudaModule {}
