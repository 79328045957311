<div fxLayout="row">
    <mat-form-field appearance="outline" fxFlex="100" fxFlex.xs="100">
        <mat-label><mat-icon>search</mat-icon> Filtro </mat-label>
        <input
            id="configuracoes-input-search"
            matInput
            type="text"
            [formControl]="this.inputFiltro"
            (keyup)="this.filtrarTexto($event.target.value)"
            placeholder="{{ placeholderFiltro }}"
        />
    </mat-form-field>
    <div ngClass.gt-xs="ml-20 mt-8 mr-20" ngClass.xs="ml-20 mt-8" *ngIf="this.possuiBotaoPesquisa">
        <button
            id="configuracoes-btn-pesquisa-filtro"
            mat-mini-fab
            class="btn-pesquisa-filtro mt-4"
            (click)="this.filtrarTextoBotao(this.inputFiltro.value)"
        >
            <mat-icon class="icone-search">search</mat-icon>
        </button>
    </div>
    <ng-content select=".lista-sidebar"></ng-content>
</div>
