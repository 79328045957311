<div>
    <table
        id="tabela-lista-colunas-acoes"
        mat-table
        [dataSource]="this.matDados"
        matSort
        [hidden]="!this.matDados.data || !this.matDados.data.length"
    >
        <!-- DADOS -->
        <ng-container [matColumnDef]="coluna.key" *ngFor="let coluna of this.colunas">
            <th [ngClass]="coluna.width" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{ coluna.decricaoAlt }}">
                {{ coluna.descricao }}
            </th>

            <td [ngClass]="coluna.width" mat-cell *matCellDef="let linha">
                <ng-container *ngIf="coluna.tipoDado === this.tipoDado.DATA">
                    <span matTooltip="{{ linha[coluna.key] | date: 'dd/MM/yyyy' }}">
                        {{ linha[coluna.key] | date: 'dd/MM/yyyy' || '--' }}
                    </span>
                </ng-container>

                <ng-container *ngIf="coluna.tipoDado === this.tipoDado.MONETARIO">
                    <span
                        matTooltip="{{ linha[coluna.key] | currency: 'BRL' }}"
                        [class]="linha[coluna.key] >= 0 ? 'monetarioPositivo' : 'monetarioNegativo'"
                    >
                        {{ linha[coluna.key] | currency: 'BRL' || '--' }}
                    </span>
                </ng-container>

                <ng-container *ngIf="coluna.tipoDado === this.tipoDado.TELEFONE">
                    <span matTooltip="{{ this.formatarTelefone(linha[coluna.key]) }}">
                        {{ this.formatarTelefone(linha[coluna.key]) || '--' }}
                    </span>
                </ng-container>

                <ng-container *ngIf="coluna.tipoDado === this.tipoDado.CPF">
                    <span matTooltip="{{ linha[coluna.key] | mask: '000.000.000-00' }}">
                        <span *ngIf="!!linha[coluna.key]">
                            {{ linha[coluna.key] | mask: '000.000.000-00' || '--' }}
                        </span>
                        <span *ngIf="!linha[coluna.key]">
                            {{ '--' }}
                        </span>
                    </span>
                </ng-container>

                <ng-container *ngIf="coluna.tipoDado === this.tipoDado.INNER_HTML">
                    <span matTooltip="{{ linha[coluna.tooltipKey] }}" [innerHTML]="linha[coluna.key] | safeHtml" ngClass.gt-xs="altura-gt-xs"></span>
                </ng-container>

                <ng-container *ngIf="coluna.tipoDado === this.tipoDado.INNER_HTML_CENTRALIZADO">
                    <span
                        matTooltip="{{ linha[coluna.tooltipKey] }}"
                        [innerHTML]="linha[coluna.key] | safeHtml"
                        ngClass.gt-xs="altura-gt-xs colJustifyContentCenter"
                    ></span>
                </ng-container>

                <ng-container *ngIf="coluna.tipoDado === this.tipoDado.TEXTO || coluna.tipoDado === undefined">
                    <span matTooltip="{{ linha[coluna.key] }}">
                        {{ this.formatarTexto(linha[coluna.key], coluna.maxLengthKey) || '--' }}
                    </span>
                </ng-container>
            </td>
        </ng-container>

        <!-- AÇÕES -->
        <ng-container matColumnDef="acoes" *ngIf="this.possuiAcoes">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let linha" (click)="$event.stopPropagation()">
                <div fxLayoutAlign="end center">
                    <ng-container *ngFor="let acao of this.acoes">
                        <ng-container *ngIf="this.possuiAcao(linha, acao.codigo)">
                            <mat-slide-toggle
                                *ngIf="acao.tipoComponenteAcao === tipoComponenteAcao.SLIDE_TOGGLE"
                                matTooltip="{{ acao.decricaoAlt }}"
                                (click)="this.onClickAcao(linha, acao.codigo)"
                                [(ngModel)]="linha[acao.ngModel]"
                            >
                            </mat-slide-toggle>
                            <button
                                *ngIf="acao.tipoComponenteAcao === tipoComponenteAcao.BUTTON"
                                mat-icon-button
                                matTooltip="{{ acao.decricaoAlt }}"
                                (click)="this.onClickAcao(linha, acao.codigo)"
                            >
                                <mat-icon>{{ acao.icon }}</mat-icon>
                            </button>
                        </ng-container>
                    </ng-container>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="this.colunasVisiveis; sticky: true"></tr>
        <tr
            mat-row
            *matRowDef="let linha; columns: this.colunasVisiveis"
            (click)="this.onClickLinha(linha)"
            class="font-weight-600 linha-ativa"
            [ngClass]="{ 'linha-ativa-acao': this.possuiAcao(linha, this.codigoAcaoClickLinha) }"
        ></tr>
    </table>

    <div class="text-center" *ngIf="!this.matDados.data || this.matDados.data.length === 0">
        <p>{{ 'MENSAGENS.SEMINFORMACAO' | translate }}</p>
    </div>
</div>
