export function titleCase(str: string): string {
    if (!str) {
        return '';
    }

    const array = str.toLowerCase().split(' ');
    const final = [];

    for (const word of array) {
        final.push(word.charAt(0).toUpperCase() + word.slice(1));
    }

    return final.join(' ');
}
