import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'titulo-com-quebra',
    templateUrl: './titulo-com-quebra.component.html',
    styleUrls: []
})
export class TituloComQuebraComponent implements OnInit {
    @Input() titulo: string;
    @Input() tamanhoMaximo: number;

    ngOnInit(): void {
        if (this.tamanhoMaximo === null || this.tamanhoMaximo === 0) {
            this.tamanhoMaximo = 50;
        }
    }

    formatarTexto(): string {
        if (this.titulo.length >= this.tamanhoMaximo) {
            return this.titulo.substring(0, this.tamanhoMaximo - 3) + '...';
        }

        return this.titulo;
    }
}
