import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogInformacaoComponent } from './dialog-informacao.component';
import { DialogData } from './models/dialog-data';
import { DialogPerguntaComponent } from './dialog-pergunta.component';
import { ComponentType } from '@angular/cdk/overlay/index';
import { Observable } from 'rxjs';
import { WIDTH_XS, WIDTH_XL, WIDTH_SM, WIDTH_MD, WIDTH_LG } from 'app/_constants/tamanhos-telas';
import { MODAL_HEIGHT_XL, MODAL_WIDTH_XL, MODAL_WIDTH_LG, MODAL_HEIGHT_LG } from 'app/_constants/tamanhos-modais';

@Injectable({
    providedIn: 'root',
})
export class DialogService {
    constructor(public dialog: MatDialog) {}

    public dialogInformacao(title: string = 'Atenção', mensagem: string, fnFechar: VoidFunction = null): void {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.msg = mensagem;
        diagData.type = 'texto';
        diagData.fnFechar = fnFechar;

        this.dialog.open(DialogInformacaoComponent, {
            data: diagData,
            disableClose: true,
            restoreFocus: false,
        });
    }

    public dialogInformacaoHtml(title: string = 'Atenção', html: string, height: string, width: string, fnFechar: VoidFunction = null): void {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.html = html;
        diagData.type = 'html';
        diagData.fnFechar = fnFechar;

        this.dialog.open(DialogInformacaoComponent, {
            data: diagData,
            height: height,
            width: width,
        });
    }

    public dialogPergunta(
        title: string = 'Atenção',
        mensagem: string,
        width: string = null,
        fnSim: VoidFunction = null,
        fnNao: VoidFunction = null
    ): MatDialogRef<DialogPerguntaComponent> {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.msg = mensagem;
        diagData.type = 'texto';
        diagData.fnSim = fnSim;
        diagData.fnNao = fnNao;

        return this.dialog.open(DialogPerguntaComponent, {
            data: diagData,
            width: width,
        });
    }

    public dialogPerguntaAsync(
        title: string = 'Atenção',
        mensagem: string,
        width: string = null,
        fnSim: VoidFunction = null,
        fnNao: VoidFunction = null
        // tslint:disable-next-line: no-any
    ): Observable<any> {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.msg = mensagem;
        diagData.type = 'texto';
        diagData.fnSim = fnSim;
        diagData.fnNao = fnNao;

        return this.dialog
            .open(DialogPerguntaComponent, {
                data: diagData,
                width: width,
            })
            .afterClosed();
    }

    public dialogPerguntaHtml(
        title: string = 'Atenção',
        html: string,
        fnSim: VoidFunction = null,
        fnNao: VoidFunction = null
    ): MatDialogRef<DialogPerguntaComponent> {
        const diagData = new DialogData();

        diagData.title = title;
        diagData.html = html;
        diagData.type = 'html';
        diagData.fnSim = fnSim;
        diagData.fnNao = fnNao;

        return this.dialog.open(DialogPerguntaComponent, {
            data: diagData,
        });
    }

    // tslint:disable-next-line: no-any
    public dialogGenerico<T, D = any, R = any>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config?: MatDialogConfig<D>
    ): Observable<R | undefined> {
        if (!config.width) {
            config = this._verificaWidth(config);
        }

        config.disableClose = config.disableClose !== null && config.disableClose !== undefined ? config.disableClose : true;
        config.restoreFocus = false;

        return this.dialog.open(componentOrTemplateRef, config).afterClosed();
    }

    private _verificaWidth(config: MatDialogConfig): MatDialogConfig {
        switch (true) {
            case innerWidth < WIDTH_XS:
                config.width = config.width ? config.width : MODAL_WIDTH_XL;
                break;
            case innerWidth > WIDTH_XS && innerWidth < WIDTH_SM:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
            case innerWidth > WIDTH_SM && innerWidth < WIDTH_MD:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
            case innerWidth > WIDTH_MD && innerWidth < WIDTH_LG:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
            case innerWidth > WIDTH_LG && innerWidth < WIDTH_XL:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
            default:
                config.width = config.width ? config.width : MODAL_WIDTH_LG;
                break;
        }
        return config;
    }
}
