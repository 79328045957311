import { format } from 'date-fns';

export abstract class FiltroData {
    dataInicio: string;
    dataFim: string;

    constructor(dataInicio?: string, dataFim?: string) {
        if (!dataInicio && !dataFim) {
            const dataAtual = new Date();
            dataInicio = format(new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1), 'YYYY-MM-DD 00:00:00');
            dataFim = format(new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0), 'YYYY-MM-DD 23:59:59');
        }

        this.dataInicio = dataInicio;
        this.dataFim = dataFim;
    }
}
