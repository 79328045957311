import { RoleUsuario } from '../_enums/role-usuario';

export class DadosBasicoUsuarioDTO {
    nome: string;
    cpf: string;
    email: string;
    telefone: string;
    dadosConselho: string;
    dataCadastro: Date | string;
    id?: number;
    perfil?: RoleUsuario;
}
