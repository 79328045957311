import { Routes, RouterModule, Router, Event, NavigationEnd } from '@angular/router';
import { InicioComponent } from './main/inicio/inicio.component';
import { LoginComponent } from 'app/authentication/login/login.component';
import { AuthGuard } from 'app/_helpers';
import { NgModule } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as portugues } from 'app/_i18n/pt-br';
import { AjudaComponent } from 'app/main/ajuda/ajuda.component';
import { InicioGuard } from './_helpers/inicio.guard';
import { PerfilGuard } from './_helpers/perfil.guard';
import { RoleUsuario } from './_models/_enums/role-usuario';
import { MeusDadosComponent } from './main/meus-dados/meus-dados.component';
import { IndicacoesComponent } from 'app/main/inicio/indicacoes-conversoes/indicacoes/indicacoes.component';
import { ConversoesComponent } from 'app/main/inicio/indicacoes-conversoes/conversoes/conversoes.component';
import { PagamentosComponent } from 'app/main/pagamentos/pagamentos.component';
import { AfiliadosComponent } from './main/inicio/afiliados/afiliados.component';
import { MaterialApoioComponent } from './main/material-apoio/material-apoio.component';

const routes: Routes = [
    {
        path: 'inicio',
        component: InicioComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {
                path: '',
                redirectTo: 'afiliados',
                pathMatch: 'full',
            },
            {
                path: 'indicacoes',
                component: IndicacoesComponent,
                canActivate: [InicioGuard, PerfilGuard, AuthGuard],
                data: {
                    perfis: [RoleUsuario.ADMINISTRADOR_AFILIADO, RoleUsuario.AFILIADO],
                },
            },
            {
                path: 'conversoes',
                component: ConversoesComponent,
                canActivate: [InicioGuard, PerfilGuard, AuthGuard],
                data: {
                    perfis: [RoleUsuario.ADMINISTRADOR_AFILIADO, RoleUsuario.AFILIADO],
                },
            },
            {
                path: 'afiliados',
                component: AfiliadosComponent,
                canActivate: [InicioGuard, PerfilGuard, AuthGuard],
                data: {
                    perfis: [RoleUsuario.ADMINISTRADOR_AFILIADO],
                },
            },
        ],
    },
    {
        path: 'pagamentos',
        component: PagamentosComponent,
        canActivate: [AuthGuard, PerfilGuard],
        data: {
            perfis: [RoleUsuario.ADMINISTRADOR_AFILIADO],
        },
    },
    {
        path: 'material-apoio',
        component: MaterialApoioComponent,
        canActivate: [AuthGuard, PerfilGuard],
        data: {
            perfis: [RoleUsuario.ADMINISTRADOR_AFILIADO, RoleUsuario.AFILIADO],
        },
    },
    {
        path: 'ajuda',
        component: AjudaComponent,
        canActivate: [AuthGuard],
        runGuardsAndResolvers: 'always',
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'meus-dados',
        component: MeusDadosComponent,
    },
    {
        path: '**',
        redirectTo: 'login',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard, InicioGuard],
})
export class AppRoutingModule {
    constructor(private _router: Router, private _fuseTranslationLoaderService: FuseTranslationLoaderService) {
        this.init();
    }

    init(): void {
        this._router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                this._fuseTranslationLoaderService.loadTranslations(portugues);
            }
        });
    }
}
