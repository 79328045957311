<h2 mat-dialog-title>Termo de uso.</h2>
<div id="content-dialog-registrar">
    <mat-dialog-content class="mat-typography modalTermo">
        <div>
            <h4>CONTRATO PADRÃO DO PROGRAMA DE AFILIAÇÃO ‘AMPLUM’. 003.2020.</h4>
            <p>
                Celebram o presente contrato WMI TECNOLOGIA LTDA EPP, sito à Rua Fortaleza 170 - 3º Andar |
                Bairro Industrial | Divinópolis - MG | CEP 35.500-198 | (37)2102 5532, CNPJ: 07.511.666/0001-53, neste ato representada na forma de
                seus atos constitutivos, na forma da última alteração contratual em vigor, aqui denominada WMI TECNOLOGIA LTDA, PRODUTORA, DESENVOLVEDORA e/ou
                LICENCIANTE, na qualidade de produtora de software e prestadora de serviços e, do outro lado, na condição de AFILIADO, este
                qualificado no TERMO DE ADESÃO RESPECTIVO, nos termos das cláusulas e condições a seguir aduzidas, sendo o TERMO DE ADESÃO ao presente
                contrato diretamente vinculado ao instrumento abaixo, ainda que eletrônico.
            </p>

            <p>
                1. DO OBJETO - O presente instrumento visa estabelecer a parceria e a cooperação entre as partes com vistas a realizar ações conjuntas
                ligadas a prospecção de novos clientes para o ‘AMPLUM’. A PRODUTORA remunerará através de comissões o AFILIADO que trouxer
                novos clientes, e estes fecharem Contrato com a PRODUTORA, conforme especificações previstas neste contrato e aceita pelo AFILIADO.
            </p>

            <p>
                1.1 O AFILIADO tem ciência de que os serviços prestados são exatamente aqueles previstos neste termo, não estando a PRODUTORA obrigada
                a fornecer qualquer funcionalidade, melhoria ou recurso incorporado ao serviço após o aceite deste Contrato de forma eletrônica ou
                escrita.
            </p>

            <p>
                2. DO PROGRAMA DE AFILIAÇÃO – O programa de afiliação consiste na parceria entre PRODUTORA e AFILIADO para a divulgação e venda dos
                serviços da PRODUTORA, em especial do uso do software e plataforma do Amplum, hospedado em www.amplum.solutions/app;
            </p>

            <p>
                2.1 A contratação inicial não garante qualquer resultado, pagamento ou vantagem ao AFILIADO, somente sendo devidas as vantagens
                previstas neste instrumento se as premissas a pagamento forem todas cumpridas;
            </p>

            <p>
                2.2 O AFILIADO oferecerá aos clientes finais os serviços ofertados pela PRODUTORA no valor estabelecido por esta, segundo a categoria
                ao qual o cliente se enquadrar, na forma da tabela de preços em vigor, pela distribuição de links e cupoms vinculados ao AFILIADO,
                fornecidos pela PRODUTORA;
            </p>

            <p>2.3 Em caso de sucesso na contratação de novos clientes, o AFILIADO receberá o valor de 89,00 , uma única vez para cada conversão.</p>

            <p>
                2.3.1 Em nenhuma hipótese serão devidos valores após o prazo inicial da contratação, ainda que o CLIENTE migre de plano; da mesma
                forma, os valores somente serão devidos após a liquidação dos valores e sua disponibilização à PRODUTORA.
            </p>

            <p>
                2.3.2 Os valores serão disponibilizados para “resgate” após 5 dias a contar do dia do efetivo recebimento pela PRODUTORA. O AFILIADO
                deverá solicitar o resgate do valor por meio da PLATAFORMA DE AFILIADOS onde indicará banco e conta, devendo a conta corrente ser
                disponibilizada com o mesmo CPF de cadastro do AFILIADO;
            </p>

            <p>2.3.3 Após a solicitação de Resgate, o valor será depositado em conta corrente, CADASTRADA do AFILIADO em até 5 dias úteis.</p>

            <p>
                2.3.4 A PRODUTORA se resguarda ao direto de efetuar alterações nos valores dos planos e de bonificação ao AFILIADO, e a propor
                alterações na metodologia, devendo para isso notificar o afiliado por meio da plataforma de afiliação.
            </p>

            <p>
                2.4 Em caso de estorno ou questionamento dos valores contratados pelo CLIENTE, em fraude, o AFILIADO será solidariamente responsável
                pelas reparações dos danos advindos bem como pela devolução de eventuais valores recebidos, sob as penas da Lei, sem prejuízo da
                responsabilidade criminal.
            </p>

            <p>
                2.5 Em caso de conflito de interesses entre PRODUTORA, AFILIADO E CLIENTE, prevalecerá o 1º contato de venda e esforço documentado,
                sendo que o AFILIADO não poderá solicitar pagamentos de contatos que foi negociado em primeiro pela PRODUTORA ou outro AFILIADO;
            </p>

            <p>2.6 É responsabilidade da PRODUTORA:</p>

            <p>
                (i) Manter disponíveis os serviços contratados durante todo o período de contrato com o CLIENTE;
                <br />(ii) Oferecer suporte e auxilio gratuito para execução do serviço ao CLIENTE final; <br />(iii) Responsabilizar-se integralmente
                pelo cumprimento de suas obrigações com o cliente final, respondendo pelo adimplemento substancial do contrato celebrado, isentando de
                quaisquer responsabilidades o AFILIADO pela inexecução do contrato; <br />(iv) Arcar com todos os impostos, taxas e custos da
                prestação dos serviços contratados pelo CLIENTE e responder por atos de seus prepostos;
            </p>

            <p>
                2.6 O AFILIADO deverá realizar um treinamento no formato EAD que será disponibilizado pela PRODUTORA, para conhecimento da ferramenta
                objeto deste contrato.
            </p>

            <p>
                3. CONFIDENCIALIDADE E PROTEÇÃO DE DADOS - Cada parte se compromete a manter e tratar como confidencial e não revelar a terceiros
                qualquer Informação Confidencial relacionada ao serviço, e dados da PRODUTORA, de AFILIADO e de CLIENTES ou usar referidas informações
                para qualquer propósito que não aquele previsto no presente Termo, exceto por força de lei e ordem judicial.
            </p>

            <p>
                3.2 Tanto as partes quanto os seus representantes legais, diretores, empregados, agentes e consultores, incluindo advogados, auditores
                e consultores financeiros, estão sujeitos ao dever de sigilo e confidencialidade previsto neste Termo. Qualquer divulgação referente a
                este Termo deverá ser consentida previamente por escrito pela outra parte.
            </p>

            <p>3.3 A Obrigação de confidencialidade aqui prevista permanecerá vigente por 5 (cinco) anos após a rescisão deste instrumento.</p>

            <p>
                3.4 PROTEÇÃO DE DADOS. Sem limitação do precedente, a PRODUTORA se compromete em manter as defesas administrativas, físicas e técnicas
                adequadas para proteger a segurança, confidencialidade e integridade dos dados do AFILIADO.
            </p>

            <p>
                4. PRAZO E RESCISÃO. Prazo. O prazo de vigência deste contrato é indeterminado, vigendo a partir do aceite declarado pelo AFILIADO. O
                presente contrato poderá ser rescindido por qualquer das partes, com aviso prévio de 30 dias em caso de denúncia vazia ou,
                imediatamente, por infração a qualquer clausula ou condição.
            </p>

            <p>
                5. A PRODUTORA desenvolverá suas atividades com independência e sob sua responsabilidade, seja por si ou seus prepostos, nos termos
                das tratativas das partes, pelo que se responsabiliza pelos atos de seus empregados, prepostos e demais agentes. O presente Contrato
                não cria entre A PRODUTORA e o AFILIADO ou entre uma delas e os funcionários e Representantes da outra, qualquer vínculo empregatício
                ou de trabalho, permanecendo cada Parte responsável pelo recolhimento dos encargos fiscais, trabalhistas e previdenciários de seu
                pessoal, bem como pelo pagamento dos tributos e contribuições, inclusive sociais, incidentes sobre suas respectivas atividades. O
                presente Contrato não importa, tampouco, na criação de qualquer vínculo societário, de parceria ou associativo entre as Partes, seus
                sócios, afiliadas, controladas e/ou respectivos funcionários, inexistindo qualquer presunção de solidariedade entre as Partes, à
                exceção do aqui disposto.
            </p>

            <p>
                6. Durante a vigência deste contrato e por 2 (dois) anos após seu encerramento, o AFILIADO está vedado de explorar, direta ou
                indiretamente, atividades que sejam consideradas concorrentes ao ramo da atividade da PRODUTORA ou de desenvolver software ou serviço
                similar, sob pena de, o fazendo, arcar com penalidade no total de 200% de todos os valores recebidos pela PRODUTORA, nos últimos 5
                (cinco) anos.
            </p>

            <p>
                7. FORO - Fica eleito o foro da Comarca de Divinópolis, no Estado de Minas Gerais, com expressa renúncia de qualquer outro, por mais
                privilegiado que seja, para dirimir quaisquer dúvidas ou ações decorrentes deste CONTRATO.
            </p>
        </div>
    </mat-dialog-content>
</div>

<div mat-dialog-actions fxLayoutAlign="end center">
    <div class="flex-grow-1">
        <mat-checkbox id="checkbox-termo-aceito" [(ngModel)]="this.termoAceito" class="mt-sm-8">
            {{ 'BOTOES.LI_ACEITO' | translate }}
        </mat-checkbox>
        <a href="assets/TermoUso/Termo de uso.pdf" mat-button mat-dialog-close target="_blank"> <mat-icon>save_alt</mat-icon> Baixar PDF</a>
    </div>

    <div mat-dialog-actions fxLayoutAlign="end">
        <button
            id="cancelar"
            type="button"
            class="btn-cancelar mr-4"
            ngClass.gt-xs="mr-8"
            color="accent"
            (click)="this.dialogRef.close(false)"
            mat-stroked-button
        >
            {{ 'BOTOES.CANCELAR' | translate }}
        </button>

        <div [matTooltipDisabled]="this.termoAceito" matTooltip="{{ 'MENSAGENS.EH_NECESSARIO_ACEITAR_TERMO' | translate }}">
            <button
                id="button-continuar"
                type="button"
                color="accent"
                (click)="this.dialogRef.close(true)"
                [disabled]="!this.termoAceito"
                mat-raised-button
            >
                {{ 'BOTOES.CONTINUAR' | translate }}
            </button>
        </div>
    </div>
</div>
