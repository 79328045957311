<h2 class="title" mat-dialog-title>
    {{ this.titulo }}
</h2>

<mat-dialog-content style="overflow: overlay;">
    <div class="container" fxLayout="column" fxLayoutAlign="start center" fxLayout.gt-md="row">
        <div fxLayout="column" fxLayoutAlign="start" fxFlex="1 0 auto">
            <div
                *ngIf="!capturarFoto"
                fxLayout="row"
                fxLayout.xs="column"
                fxLayoutAlign="center center"
                fxLayoutAlign.xs="center center"
                fxFlex="1 0 auto"
            >
                <div fxLayout="column" fxLayoutAlign="center center" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="start center" *ngIf="!imageReady">
                    <img id="imagem-padrao" *ngIf="!!src" class="profile-image avatar huge" [src]="src" />
                </div>
            </div>

            <!--Logo marca-->
            <image-cropper
                *ngIf="!capturarFoto && !!imagemLogoMarca"
                [imageChangedEvent]="imageChangedEvent"
                [onlyScaleDown]="true"
                [roundCropper]="false"
                [autoCrop]="true"
                [alignImage]="'center'"
                format="png"
                [aspectRatio]="2"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                [ngStyle]="{ 'max-height': this.width }"
            ></image-cropper>

            <!--Perfil de usuário-->
            <image-cropper
                *ngIf="!capturarFoto && !imagemLogoMarca"
                [imageChangedEvent]="imageChangedEvent"
                [resizeToWidth]="128"
                [cropperMinWidth]="128"
                [resizeToHeight]="128"
                [onlyScaleDown]="true"
                [roundCropper]="!!this.data.semBorda ? false : true"
                [autoCrop]="true"
                [alignImage]="'center'"
                format="png"
                (containWithAspectRatio)="(true)"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded()"
                [ngStyle]="{ 'max-height': this.width }"
                [ngClass]="{ 'tamanho-maximo': !!this.data.semBorda }"
            ></image-cropper>

            <app-capturar-foto *ngIf="capturarFoto"></app-capturar-foto>

            <div *ngIf="errorsCamera">
                <div style="text-align: center;">
                    <span
                        ><b>{{ 'MENSAGENS.CAMERA_NAO_ENCONTRADA' | translate }}</b></span
                    >
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>

            <div fxLayout="row wrap" [ngClass]="isMobile ? 'pr-12 pl-12 btns-1' : 'p-24 btns-1'" fxLayoutAlign="center center">
                <button (click)="setCaptura()" mat-flat-button fxFlex.lt-md="80" ngClass.gt-sm="mr-8" ngClass.lt-md="mb-4">
                    <mat-icon matSuffix>camera_alt</mat-icon>
                    {{ 'BOTOES.CAPTURAR_FOTO' | translate }}
                </button>
                <button mat-flat-button (click)="file.click()" fxFlex.lt-md="80">
                    <mat-icon matSuffix>attach_file</mat-icon>
                    {{ 'BOTOES.ESCOLHER_FOTO' | translate }}
                </button>
            </div>
            <div class="fileUpload">
                <input id="uploadBtn" class="upload" type="file" accept="image/*" #file (change)="fileChangeEvent($event)" />
            </div>
        </div>
    </div>
    <div fxLayout="row wrap" class="m-0" *ngIf="!!this.data.texto">
        <p fxFlex="100">{{ this.data.texto }}</p>
    </div>
</mat-dialog-content>

<div mat-dialog-actions fxLayoutAlign="end" class="mt-8">
    <button id="cancelar" (click)="dialogRef.close()" mat-stroked-button class="btn-cancelar">
        {{ 'BOTOES.FECHAR' | translate }}</button
    >&nbsp;
    <button id="salvar" (click)="uploadFoto()" color="accent" mat-raised-button>
        {{ 'BOTOES.SALVAR' | translate }}
    </button>
</div>
