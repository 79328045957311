import { Injectable } from '@angular/core';
import { AuthenticationService } from '..';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseControllerService } from './base-controller.service';
import { CaminhoImagem, ResultadoController } from 'app/_models';
import { CadastroConta, ConselhoDTO } from 'app/_models';
import { PerfilAfiliadoDto } from 'app/_models/alterar-perfil/perfil-afiliado-dto';
import { ResultadoAlterarPerfil } from 'app/_models/alterar-perfil/resultado-alterar-perfil';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

@Injectable({
    providedIn: 'root',
})
export class ContaColaboradorControllerService extends BaseControllerService {
    public idUsuario: number;
    public planoId: number;

    constructor(_http: HttpClient, _authenticationService: AuthenticationService) {
        super(_http, _authenticationService);
    }

    public selecionarContaColaborador(): Observable<PerfilAfiliadoDto> {
        return this.get<PerfilAfiliadoDto>(`ContaColaborador`);
    }

    public alterarContaColaboradorComPerfilAfiliado(colaborador: PerfilAfiliadoDto): Observable<ResultadoAlterarPerfil> {
        return this.put<ResultadoAlterarPerfil, PerfilAfiliadoDto>(`ContaColaborador/AlterarContaColaboradorComPerfilAfiliado`, colaborador);
    }

    public alterarSenha(senha: string): Observable<number> {
        const obj = {
            senha: senha,
        };
        return this.put<number, object>(`ContaColaborador/AlterarSenha`, obj);
    }

    public validarSenha(senha: string): Observable<boolean> {
        const dados = {
            senha: senha,
        };

        return this.post<boolean, object>(`ContaColaborador/VerificarSenha`, dados);
    }

    public incluirContaColaborador(usuario: CadastroConta): Observable<ResultadoController> {
        return this.post<ResultadoController, CadastroConta>(`ContaColaborador`, usuario);
    }

    public cpfEhValido(cpf: string): Observable<boolean> {
        return this.get<boolean>(`ContaColaborador/VerificarCpf/${cpf}`);
    }

    public emailEhValido(email: string): Observable<boolean> {
        return this.get<boolean>(`ContaColaborador/VerificarEmail/${email}`);
    }

    public termoUso(): Observable<{ termo: string }> {
        return this.get<{ termo: string }>(`ContaColaborador/TermoContrato`);
    }

    public enviarEmailParaConfirmacao(): Observable<ResultadoController> {
        return this.post<ResultadoController, null>(`ContaColaborador/EnviarEmailParaConfirmacao`, null);
    }

    public solicitarResgate(roleUsuarioMeuConsultorio?: RoleUsuario): Observable<ResultadoController> {
        let rota = 'ContaColaborador/SolicitarResgate';

        if (!!roleUsuarioMeuConsultorio) {
            rota += `?roleUsuarioMeuConsultorio=${roleUsuarioMeuConsultorio}`;
        }

        return this.post<ResultadoController, null>(rota, null);
    }

    public alterarEmailConfirmadoContaColaborador(codigo: string): Observable<ResultadoController> {
        return this.put<ResultadoController, null>(`ContaColaborador/AlterarEmailConfirmadoContaColaborador/${codigo}`, null);
    }

    public uploadImagem(formData: FormData, id: number): Observable<CaminhoImagem> {
        return this.post<CaminhoImagem, FormData>(`ContaColaborador/AlterarFoto`, formData);
    }
}
