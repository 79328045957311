import { Component } from '@angular/core';
import { TraducaoService } from 'app/_services';

@Component({
    selector: 'ajuda',
    templateUrl: './ajuda.component.html',
    styleUrls: ['./ajuda.component.scss']
})
export class AjudaComponent {
    constructor(private _i18n: TraducaoService) {}
}
