export const locale = {
    lang: 'pt-br',
    data: {
        NAV: {
            APPLICATIONS: 'Apps',
            INICIO: {
                TITLE: 'Início',
            },
            GRAFICOS: {
                TITLE: 'Gráficos',
            },
            FEEDBACK: {
                TITLE: 'Feedback',
            },
            AJUDA: {
                TITLE: 'Ajuda',
            },
            FECHAR: {
                TITLE: 'Fechar',
            },
            EXEMPLO: {
                TITLE: 'Exemplo',
            },
        },
    },
};
