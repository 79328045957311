export class User {
    identificacao: string;
    senha: string;
    nome: string;
    email: string;
    cpf: string;
    idUsuario: number;
    tipo: any;
    token?: string;
    caminhoFoto: string;
    perfil?: any;
    tokenRegistroFcm: string;
    customProperties: { [key: string]: any };
    dadosPagamento?: boolean;
}
