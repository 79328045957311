import { EventEmitter } from '@angular/core';

export class EmissorEventoGlobalService {
    private static emitters: {
        // tslint:disable-next-line: no-any
        [nomeEvento: string]: EventEmitter<any>;
    } = {};

    private static get<T>(nomeEvento: string, nomeEmissor: string): EventEmitter<T> {
        if (!this.emitters[nomeEvento]) {
            this.emitters[nomeEvento] = new EventEmitter<T>();
        }

        // tslint:disable-next-line: no-console
        console.info('ACESSANDO EVENTO GLOBAL [' + nomeEvento + '] A PARTIR DE [' + nomeEmissor + ']');
        return this.emitters[nomeEvento];
    }

    public static buscarProfisionalSelecionado(nomeEmissor: string): EventEmitter<void> {
        return this.get<void>('buscarProfisionalSelecionado', nomeEmissor);
    }

    public static getFoto(nomeEmissor: string): EventEmitter<string> {
        return this.get<string>('getFoto', nomeEmissor);
    }

    public static buscarLocalAtendimentoEstouEmSelecionado(nomeEmissor: string): EventEmitter<void> {
        return this.get<void>('buscarLocalAtendimentoEstouEmSelecionado', nomeEmissor);
    }

    public static atualizarLocaisAtendimentoEstouEm(nomeEmissor: string): EventEmitter<void> {
        return this.get<void>('atualizarLocaisAtendimentoEstouEm', nomeEmissor);
    }

    public static alterouData(nomeEmissor: string): EventEmitter<Date> {
        return this.get<Date>('alterouData', nomeEmissor);
    }

    public static recebeAlterouData(nomeEmissor: string): EventEmitter<void> {
        return this.get<void>('alterouData', nomeEmissor);
    }

    public static alterouHistoricoPaciente(nomeEmissor: string): EventEmitter<number> {
        return this.get<number>('alterouHistoricoPaciente', nomeEmissor);
    }
}
