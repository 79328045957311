import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CapturarFotoService {
    file: File;
    private _errorsCamera: Subject<any> = new Subject<any>();
    private _imageBase64: Subject<string> = new Subject<string>();
    public imageBase64: Observable<string> = this._imageBase64.asObservable();

    constructor() {}

    setImageBase64(imgBase64: string): void {
        this._imageBase64.next(imgBase64);
    }

    base64ToImageFile(base64: string): void {
        const base64Converted = base64.replace(/^data:image\/[a-z]+;base64,/, '');
        const imageName = new Date() + '.' + '.jpeg';
        const imageBlob = this.dataURItoBlob(base64Converted);
        const imageFile = new File([imageBlob], imageName, {
            type: 'image/jpeg'
        });

        this.file = imageFile;
    }

    dataURItoBlob(dataURI): any {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: 'image/jpeg' });
        return blob;
    }

    getFile(): File {
        return this.file;
    }

    cleanFile(): void {
        this.file = null;
    }

    getErrorsCamera(): Observable<any> {
        return this._errorsCamera.asObservable();
    }

    setErrorsCamera(errors): void {
        this._errorsCamera.next(errors);
    }
}
