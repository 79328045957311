<div class="page-layout carded fullwidth tabbed inner-scroll">
    <div class="center">
        <div class="my-12" fxLayout="row" fxLayoutAlign="start center">
            <h2 class="m-0 ml-12 tituloPagina">{{ 'Ajuda' | translate }}</h2>
        </div>

        <div class="logo">
            <img src="assets/images/logos/afiliados_128.png" />
        </div>

        <h2 class="title">Em desenvolvimento...</h2>

        <div class="subtitle">
            O software de afiliados do amplum está em constante evolução. <br />
            Em breve essa funcionalidade será liberada.
        </div>
    </div>
</div>

<!-- <card-simples class="w-100-p">
    <div class="icone-padrao">contact_support</div>
    <div class="titulo-padrao">
        {{ 'AJUDA.DUVIDAS' | translate }}
    </div>
    <div class="body-card-padrao m-12">
        <mat-accordion>
            <div class="m-12">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="primary-400">
                        <mat-panel-title>
                            {{ 'AJUDA.AGENDA' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ul class="lista-videos">
                        <div class="m-12">
                            <li>
                                <a
                                    href="https://youtu.be/wzF0XzRO9kM"
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.NOVO_AGENDAMENTO' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.NOVO_AGENDAMENTO' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/-PpttBV0Bpc"
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.CONFIRMAR_PACIENTE' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.CONFIRMAR_PACIENTE' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/3OmXt5T0YIs"
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.HORARIO_DISPONIVEL_AGENDA' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.HORARIO_DISPONIVEL_AGENDA' | translate }}
                                </a>
                            </li>
                            <li>
                                <a href="https://youtu.be/NOmWi0njLk8" target="_blank" matTooltip="{{ 'AJUDA.TOOLTIP.RECEPCAO' | translate }}">
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.RECEPCAO' | translate }}
                                </a>
                            </li>
                        </div>
                    </ul>
                </mat-expansion-panel>
            </div>
            <div class="m-12">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="primary-400">
                        <mat-panel-title>
                            {{ 'AJUDA.PRONTUARIO' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ul class="lista-videos">
                        <div class="m-12">
                            <li>
                                <a
                                    href="https://youtu.be/jx0-PzV5LwY"
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.CADASTRO_PACIENTE' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.CADASTRO_PACIENTE' | translate }}
                                </a>
                            </li>
                            <li>
                                <a href="https://youtu.be/VEk7VpHDCsE" target="_blank" matTooltip="{{ 'AJUDA.TOOLTIP.PRONTUARIO' | translate }}">
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.PRONTUARIO' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/i5FsWTFfDBY"
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.COMPARTILHAMENTO_PRONTUARIO' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.COMPARTILHAMENTO_PRONTUARIO' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/WRbAaObPxF0
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.ATESTADO_MEDICO' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.ATESTADO_MEDICO' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/84aMEw7h6_c
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.EVOLUCAO' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.EVOLUCAO' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/uibGx6g5ss0
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.RECEITA_MEDICA' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.RECEITA_MEDICA' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/Im2uTZAM5LI
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.SOLICITACAO_EXAMES' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.SOLICITACAO_EXAMES' | translate }}
                                </a>
                            </li>
                        </div>
                    </ul>
                </mat-expansion-panel>
            </div>

            <div class="m-12">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="primary-400">
                        <mat-panel-title>
                            {{ 'AJUDA.CONFIGURACOES' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ul class="lista-videos">
                        <div class="m-12">
                            <li>
                                <a
                                    href="https://youtu.be/AEdtEcJM2Go"
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.COMPLETANDO_CADASTRO' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.COMPLETANDO_CADASTRO' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/YP29qv5-DUU"
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.CONFIGURANDO_AGENDA' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.CONFIGURANDO_AGENDA' | translate }}
                                </a>
                            </li>
                            <li>
                                <a href="https://youtu.be/PdBXbiU3pGI" target="_blank" matTooltip="{{ 'AJUDA.TOOLTIP.CONVIDAR_MEDICO' | translate }}">
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.CONVIDAR_MEDICO' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/8ynJ447Ftt4

                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.CONVIDAR_SECRETARIA' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.CONVIDAR_SECRETARIA' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/srg79pY0Gdg
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.REGISTRO_PRONTUARIO' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.REGISTRO_PRONTUARIO' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/4DeiWorRsyc

                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.CADASTRO_PROCEDIMENTO' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.CADASTRO_PROCEDIMENTO' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/lmRRCRn8HwU
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.ROTINAS_EXAMES' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.ROTINAS_EXAMES' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/ylI4nwlKiDA
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.IMPORTACAO_PACIENTES' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.IMPORTACAO_PACIENTES' | translate }}
                                </a>
                            </li>
                        </div>
                    </ul>
                </mat-expansion-panel>
            </div>

            <div class="m-12">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="primary-400">
                        <mat-panel-title>
                            {{ 'AJUDA.FINANCEIRO' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ul class="lista-videos">
                        <div class="m-12">
                            <li>
                                <a
                                    href="https://youtu.be/mEKfgHhsuxs
                                    "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.CONTA_RECEBER' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.CONTA_RECEBER' | translate }}
                                </a>
                            </li>
                            <li>
                                <a href="https://youtu.be/yBRLAkJR91c" target="_blank" matTooltip="{{ 'AJUDA.TOOLTIP.CONTA_PAGAR' | translate }}">
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.CONTA_PAGAR' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/4Ko3_fcTD6s
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.CONTROLE_CAIXA' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.CONTROLE_CAIXA' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/9rVr3nvdRwQ

                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.FLUXO_CAIXA' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.FLUXO_CAIXA' | translate }}
                                </a>
                            </li>
                        </div>
                    </ul>
                </mat-expansion-panel>
            </div>

            <div class="m-12">
                <mat-expansion-panel>
                    <mat-expansion-panel-header class="primary-400">
                        <mat-panel-title>
                            {{ 'AJUDA.OUTROS' | translate }}
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ul class="lista-videos">
                        <div class="m-12">
                            <li>
                                <a
                                    href="https://youtu.be/F-0edAAIRCE
                                    "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.COMO_CONTRATAR' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.COMO_CONTRATAR' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/h8GtP0L8uEc
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.TELA_PRINCIPAL' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.TELA_PRINCIPAL' | translate }}
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://youtu.be/1yL2uzLwHCY
                                "
                                    target="_blank"
                                    matTooltip="{{ 'AJUDA.TOOLTIP.DASHBOARD' | translate }}"
                                >
                                    <mat-icon class="mr-12">videocam</mat-icon>
                                    {{ 'AJUDA.DASHBOARD' | translate }}
                                </a>
                            </li>
                        </div>
                    </ul>
                </mat-expansion-panel>
            </div>
        </mat-accordion>
    </div>
</card-simples> -->
