import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FuseSharedModule } from '@fuse/shared.module';
import { RegistrarComponent } from 'app/authentication/registrar/registrar.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatSelectModule } from '@angular/material/select';
import { TermoUsoComponent } from './termo-uso/termo-uso.component';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { environment } from 'environments/environment.hmr';
import { NgxMaskModule } from 'ngx-mask';
import { SocialLoginModule, FacebookLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';

const routes = [
    {
        path: 'registrar',
        component: RegistrarComponent,
    },
];

@NgModule({
    declarations: [RegistrarComponent, TermoUsoComponent],
    entryComponents: [TermoUsoComponent],
    providers: [
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebook.id),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
    ],
    imports: [
        RouterModule.forChild(routes),

        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatRadioModule,
        MatIconModule,
        MatInputModule,
        MatStepperModule,
        TranslateModule,
        CdkStepperModule,
        MatSelectModule,
        MatTooltipModule,
        MatDialogModule,
        AngularFireAuthModule,
        AngularFireModule.initializeApp(environment),
        NgxMaskModule.forRoot(),
        SocialLoginModule,
        MatSlideToggleModule,
        MatButtonToggleModule,

        FuseSharedModule,
    ],
})
export class RegistrarModule {}
