import { FuseNavigation } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { AuthenticationService } from 'app/_services';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

const menu: Array<FuseNavigation> = [
    {
        id: 'inicio',
        title: 'Início',
        translate: 'Início',
        type: 'item',
        icon: 'home',
        url: 'inicio',
        perfisAcesso: [RoleUsuario.ADMINISTRADOR_AFILIADO, RoleUsuario.AFILIADO],
    },
    {
        id: 'pagamentos',
        title: 'Pagamentos',
        translate: 'Pagamentos',
        type: 'item',
        icon: 'attach_money',
        url: 'pagamentos',
        perfisAcesso: [RoleUsuario.ADMINISTRADOR_AFILIADO],
    },
    {
        id: 'material',
        title: 'Material de Apoio',
        translate: 'Material de Apoio',
        type: 'item',
        icon: 'attach_file',
        url: 'material-apoio',
        perfisAcesso: [RoleUsuario.ADMINISTRADOR_AFILIADO, RoleUsuario.AFILIADO],
    },
];

export function setNavigationMenu(authenticationService: AuthenticationService, fuseNavigationService: FuseNavigationService): void {
    const menuFiltrado = new Array<FuseNavigation>();
    const perfil = authenticationService.currentUserValue ? authenticationService.currentUserValue.perfil : null;

    menu.forEach((item) => {
        if (!item.perfisAcesso || item.perfisAcesso.includes(perfil)) {
            menuFiltrado.push(item);
        }
    });

    if (fuseNavigationService.getNavigation('main')) {
        fuseNavigationService.unregister('main');
    }

    fuseNavigationService.register('main', menuFiltrado);
    fuseNavigationService.setCurrentNavigation('main');
}
