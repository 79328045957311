<mat-toolbar class="p-0 mat-elevation-z1">
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
        <div fxFlex="1 0 auto" fxFlex.xs="100" class="ajuste-tela-359 ajuste-tela-375" fxLayout="row" fxLayoutAlign="start center">
            <button
                mat-icon-button
                class="navbar-toggle-button"
                *ngIf="!hiddenNavbar && !rightNavbar"
                (click)="toggleSidebarOpen('navbar')"
                fxHide.gt-md
            >
                <mat-icon class="secondary-text">menu</mat-icon>
            </button>

            <div class="toolbar-separator"></div>
            <div class="bem-vindo-afiliados" fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="start center" *ngIf="!this.ehPerfilAfiliado()">
                <mat-label fxHide.xs>{{ 'LABELS.BEMVINDO_ADMINISTRADOR' | translate }}</mat-label>
            </div>

            <div
                fxFlex="1 1 auto"
                fxFlex.xs="100"
                class="ajuste-tela-359 ajuste-tela-375"
                fxLayout="row"
                fxLayoutAlign="start center"
                fxLayoutAlign.lt-md="start"
                *ngIf="this.ehPerfilAfiliado()"
            >
                <form id="form-codigo-link" [formGroup]="this.form" name="form" ngClass.lt-md="w-100-p">
                    <div fxHide.lt-md fxLayout="row wrap" fxLayoutAlign="end center">
                        <div (click)="this.copiarCodigoAfiliado()" class="meu-codigo mt-16 mr-4">
                            <mat-form-field appearance="outline" class="width-input-codigo padding-mat-form-field">
                                <mat-label class="label-codigo"> {{ 'LABELS.MEU_CODIGO' | translate }}</mat-label>
                                <input
                                    id="codigo-afiliado"
                                    [readonly]="true"
                                    class="color-input"
                                    matInput
                                    type="text"
                                    formControlName="codigoAfiliado"
                                />
                                <mat-icon matSuffix class="secondary-text cursor-pointer" [matTooltip]="this.selecionarCodigoAfiliado()">
                                    file_copy
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div (click)="this.copiarLinkDivulgacao()" class="ml-8 mr-4 mt-16">
                            <mat-form-field appearance="outline" class="width-input-link">
                                <mat-label class="label-codigo"> {{ 'LABELS.LINK_PARA_INDICACAO' | translate }}</mat-label>
                                <input
                                    id="link-divulgacao"
                                    [readonly]="true"
                                    class="color-input"
                                    matInput
                                    type="text"
                                    formControlName="linkDivulgacao"
                                />
                                <mat-icon matSuffix class="secondary-text cursor-pointer" [matTooltip]="this.selecionarLinkDivulgacao()">
                                    file_copy
                                </mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <div fxHide.gt-sm ngClass.lt-md="w-100-p">
                        <div fxHide.xs (click)="this.copiarCodigoAfiliado()" class="mt-16" fxFlex="100">
                            <mat-form-field appearance="outline">
                                <mat-label class="label-codigo"> {{ 'LABELS.MEU_CODIGO' | translate }}</mat-label>
                                <input
                                    id="codigo-afiliado"
                                    [readonly]="true"
                                    class="color-input"
                                    matInput
                                    type="text"
                                    formControlName="codigoAfiliado"
                                />
                                <mat-icon matSuffix class="secondary-text cursor-pointer" [matTooltip]="this.selecionarCodigoAfiliado()">
                                    file_copy
                                </mat-icon>
                            </mat-form-field>
                        </div>

                        <div (click)="this.copiarLinkDivulgacao()" class="ml-8 mr-4 mt-16" fxFlex="100">
                            <mat-form-field appearance="outline" ngClass.lt-md="w-100-p">
                                <mat-label class="label-codigo"> {{ 'LABELS.LINK_PARA_INDICACAO' | translate }}</mat-label>
                                <input
                                    id="link-divulgacao"
                                    class="color-input"
                                    [readonly]="true"
                                    matInput
                                    type="text"
                                    formControlName="linkDivulgacao"
                                />
                                <mat-icon matSuffix class="secondary-text cursor-pointer" [matTooltip]="this.selecionarLinkDivulgacao()">
                                    file_copy
                                </mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>

            <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
                <div class="logo ml-16">
                    <img class="logo-icon" src="assets/images/logos/fuse.svg" />
                </div>
            </div>
        </div>

        <div class="toolbar-separator"></div>

        <div class="toolbar-separator"></div>
        <!-- <button id="btn-configuracoes" mat-icon-button class="quick-panel-toggle-button" aria-label="Configurações" routerLink="/configuracoes">
            <mat-icon class="secondary-text">
                settings
            </mat-icon>
        </button>

        <div class="toolbar-separator"></div> -->

        <div fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign.lt-md="start center" fxLayoutAlign.gt-sm="end center">
            <button
                id="toolbar-btn"
                mat-button
                [matMenuTriggerFor]="userMenu"
                (click)="this.mostarBtbAlerta(this.mostrarBtnAlert)"
                class="user-button"
            >
                <div fxLayout="row" fxLayoutAlign="center center">
                    <span class="mr-sm-16">
                        <img fxFlex="100" class="profile-toolbar-img mr-0 h-32 w-32" [src]="this._logoUsuario" />
                        <img src="assets/icons/alert.png" class="icone-alerta" id="tool-icone-alerta" *ngIf="this.mostrarBtnAlert"
                    /></span>
                    <span class="username mr-12" fxHide fxShow.gt-sm>{{ !!this.usuario ? this.selecionarNomeUsuario() : 'Usuário' }}</span>
                    <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
                </div>
            </button>

            <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                <button mat-menu-item routerLink="meus-dados">
                    <mat-icon>account_circle</mat-icon>
                    <img src="assets/icons/alert.png" id="tool-icone-alerta-md" *ngIf="this.mostrarBtnAlert" />
                    <span>Meus dados</span>
                </button>
                <button id="logout" mat-menu-item class="" (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </button>
            </mat-menu>

            <div class="toolbar-separator"></div>
        </div>
    </div>
</mat-toolbar>
