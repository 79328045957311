<div class="page-layout carded fullwidth inner-scroll h-100-p">
    <div class="center">
        <!-- Titulo fora do card -->
        <div class="row-titulo-rota" fxLayout="row wrap">
            <mat-icon><ng-content select=".icone-padrao"></ng-content></mat-icon>
            <h2 class="titulo-pagina">
                <ng-content select=".titulo-padrao"></ng-content>
            </h2>
        </div>
        <!-- Card -->
        <div class="content-card card-padrao">
            <div class="menu-titulo-interno">
                <ng-content select=".sub-menu-padrao"></ng-content>
            </div>
            <div class="corpo-card-padrao-header-footer">
                <ng-content select=".body-card-padrao"></ng-content>
            </div>
            <div class="footer-card">
                <ng-content select=".footer-card-padrao"></ng-content>
            </div>
        </div>
    </div>
</div>
