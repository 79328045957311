import { IndicacoesPerfilBaseService } from 'app/main/inicio/indicacoes-conversoes/indicacoes/indicacoes-perfil-base.service';
import { IndicacoesConversoesBase } from 'app/_models/conta-profissional/indicacoes-conversoes-base';
import { ListaColunas, ListaAcoes } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { Indicadores } from 'app/main/componentes/tabela-com-filtro-indicadores/indicadores';
import { SubMenu } from 'app/_models/configuracoes/financeiro/submenus';
import { DadosParaExportacaoBasicoDto } from 'app/_models/exportacao/dados-para-exportacao-basico-dto';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { TraducaoService, ToastService, AuthenticationService } from 'app/_services';
import { ContaProfissionalControllerService } from 'app/_services/controllers/conta-profissional-controller.service';
import { ConversoesPerfilBaseService } from 'app/main/inicio/indicacoes-conversoes/conversoes/conversoes-perfil-base.service';
import { User } from 'app/_models/dominio/user';

export abstract class IndicacoesConversoesBaseComponent<TClass extends IndicacoesConversoesBase> {
    protected itens: Array<TClass>;
    protected indicadores: Array<Indicadores> = new Array<Indicadores>();
    protected colunas: Array<ListaColunas> = new Array<ListaColunas>();
    protected acoes: Array<ListaAcoes> = new Array<ListaAcoes>();
    protected subMenus: Array<SubMenu>;
    protected mensagemInstrucao: string;
    protected dadosParaExportacaoBasicoDto: DadosParaExportacaoBasicoDto;
    public carregamentoInicial: boolean;

    constructor(
        protected fuseProgressBarService: FuseProgressBarService,
        protected _i18n: TraducaoService,
        protected _contaProfissionalService: ContaProfissionalControllerService,
        protected _toastService: ToastService,
        protected _authenticationService: AuthenticationService
    ) {
        this.itens = new Array<TClass>();
        this.indicadores = new Array<Indicadores>();
        this.carregamentoInicial = true;
        this.subMenus = new Array<SubMenu>();
    }

    protected abstract listarDadosAdmin(): void;
    protected abstract listarDadosAfiliado(): void;

    protected get usuario(): User {
        return this._authenticationService.currentUserValue;
    }

    protected setValues<TService extends IndicacoesPerfilBaseService | ConversoesPerfilBaseService>(service: TService, itens: Array<TClass>): void {
        this.colunas = service.getColunas();
        this.subMenus = service.getDadosSubMenu();
        this.indicadores = service.getIndicadores();
        this.mensagemInstrucao = service.getMensagemInstrucao();
        this.itens = service.getItens(itens);
    }
}
