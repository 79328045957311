<form [formGroup]="this.diasDaSemanaForm" novalidate>
    <div fxLayout="row wrap" class="pl-4">
        <label>
            {{ 'CONFIGURACOES.AGENDAS.EDITAR.NAVTABS.TAB1.DIASEMANA' | translate }}
        </label>
    </div>
    <div fxLayout="row wrap">
        <div fxLayout="column" class="p-4" fxLayoutAlign="center center">
            <div>
                <mat-checkbox id="check-domingo" (change)="selecionarDia(0)" formControlName="domingo"> </mat-checkbox>
            </div>
            <div>
                D
            </div>
        </div>
        <div fxLayout="column" class="p-4" fxLayoutAlign="center center">
            <div>
                <mat-checkbox id="check-segunda" (change)="selecionarDia(1)" formControlName="segunda"> </mat-checkbox>
            </div>
            <div>
                S
            </div>
        </div>
        <div fxLayout="column" class="p-4" fxLayoutAlign="center center">
            <div>
                <mat-checkbox id="check-terca" (change)="selecionarDia(2)" formControlName="terca"> </mat-checkbox>
            </div>
            <div>
                T
            </div>
        </div>
        <div fxLayout="column" class="p-4" fxLayoutAlign="center center">
            <div>
                <mat-checkbox id="check-quarta" (change)="selecionarDia(3)" formControlName="quarta"> </mat-checkbox>
            </div>
            <div>
                Q
            </div>
        </div>
        <div fxLayout="column" class="p-4" fxLayoutAlign="center center">
            <div>
                <mat-checkbox id="check-quinta" (change)="selecionarDia(4)" formControlName="quinta"> </mat-checkbox>
            </div>
            <div>
                Q
            </div>
        </div>
        <div fxLayout="column" class="p-4" fxLayoutAlign="center center">
            <div>
                <mat-checkbox id="check-sexta" (change)="selecionarDia(5)" formControlName="sexta"> </mat-checkbox>
            </div>
            <div>
                S
            </div>
        </div>
        <div fxLayout="column" class="p-4" fxLayoutAlign="center center">
            <div>
                <mat-checkbox id="check-sabado" (change)="selecionarDia(6)" formControlName="sabado"> </mat-checkbox>
            </div>
            <div>
                S
            </div>
        </div>
    </div>
</form>
