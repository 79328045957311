<h2 mat-dialog-title class="title">
    {{ 'LABELS.ALTERAR_SENHA' | translate }}
</h2>

<div mat-dialog-content>
    <form id="alterar-senha-form" name="form" [formGroup]="form">
        <div fxLayout="column" fxLayoutAlign="center stretch" fxFlex="100">
            <!--Senha atual-->
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{ 'LABELS.SENHA_ATUAL' | translate }}</mat-label>
                <input
                    id="alterar-senha-senha-atual"
                    (keyup)="!this.validaSenhaForm ? (this.validaSenhaForm = true) : ''"
                    matInput
                    formControlName="senhaAtual"
                    type="password"
                    minlength="8"
                    required
                />

                <mat-icon matSuffix>vpn_key</mat-icon>
                <mat-error id="alterar-senha-senha-atual-obrigatorio" *ngIf="this.form.get('senhaAtual').hasError('required')">{{
                    'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate
                }}</mat-error>
            </mat-form-field>

            <!--Nova senha-->
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{ 'LABELS.NOVA_SENHA' | translate }}</mat-label>
                <input
                    id="alterar-senha-nova-senha"
                    matInput
                    formControlName="novaSenha"
                    type="password"
                    minlength="8"
                    required
                    (keyup)="valida()"
                    pattern="^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$"
                />

                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                <mat-error id="alterar-senha-nova-senha-obrigatorio" *ngIf="this.form.get('novaSenha').hasError('required')">{{
                    'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate
                }}</mat-error>
            </mat-form-field>

            <!--Confirmar senha-->
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>{{ 'LABELS.CONFIRMAR_NOVA_SENHA' | translate }}</mat-label>
                <input
                    id="alterar-senha-confirmar-senha"
                    (keyup)="this.valida()"
                    matInput
                    formControlName="confirmaSenha"
                    type="password"
                    minlength="8"
                    required
                />
                <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>

                <mat-error id="alterar-senha-confirmar-senha-obrigatorio" *ngIf="this.form.get('confirmaSenha').hasError('required')">{{
                    'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate
                }}</mat-error>
            </mat-form-field>

            <!-- Validação senha -->
            <div fxFlexLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start" fxLayoutAlign="center center">
                <small
                    id="alterar-senha-validacao-tamanho"
                    [ngClass]="
                        !this.form.get('novaSenha').hasError('minlength') && !this.form.get('novaSenha').hasError('required')
                            ? 'color-success'
                            : 'none'
                    "
                    >{{ 'LABELS.VALIDA_UM' | translate }}
                    -
                </small>
                <small
                    id="alterar-senha-validacao-letra"
                    [ngClass]="this.validaLetra && !this.form.get('novaSenha').hasError('required') ? 'color-success' : 'none'"
                    >{{ 'LABELS.VALIDA_DOIS' | translate }}
                    -
                </small>
                <small
                    id="alterar-senha-validacao-numero"
                    [ngClass]="this.validaNum && !this.form.get('novaSenha').hasError('required') ? 'color-success' : 'none'"
                    >{{ 'LABELS.VALIDA_TRES' | translate }}
                    -
                </small>
                <small
                    id="alterar-senha-validacao-igual"
                    [ngClass]="this.validaIgual && !this.form.get('novaSenha').hasError('required') ? 'color-success' : 'none'"
                    >{{ 'LABELS.VALIDA_QUATRO' | translate }}
                </small>
            </div>
            <mat-error
                id="alterar-senha-validacao-senha-atual"
                class="pt-12"
                *ngIf="!this.validaSenhaForm && !this.form.get('novaSenha').hasError('required')"
            >
                A senha atual está incorreta.
            </mat-error>
        </div>
    </form>
</div>

<salvar-cancelar-footer (cancelarEmitter)="this.dialogRef.close()" (salvarEmitter)="this.alterarSenha()" [disabledSave]="!this.formValid">
</salvar-cancelar-footer>
