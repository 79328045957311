import { Injectable } from '@angular/core';
import { BaseControllerService } from './base-controller.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '..';
import { Observable } from 'rxjs';
import { ResultadoController } from 'app/_models/global/resultado-controller';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

@Injectable({ providedIn: 'root' })
export class ContaUsuarioRolesControllerService extends BaseControllerService {
    constructor(_http: HttpClient, _authenticationService: AuthenticationService) {
        super(_http, _authenticationService);
    }

    public incluirRoleParaClienteMeuConsultorio(roleUsuarioMeuConsultorio: RoleUsuario): Observable<ResultadoController> {
        return this.post<ResultadoController, void>(`ContaUsuarioRoles/${roleUsuarioMeuConsultorio}`, null);
    }
}
