export class ColunaExportacao {
    constructor(
        public id: string,
        public descricao: string,
        public posicao: number,
        public widthCelula: number,
        public style: string = 'header',
        public alignment: string = 'center'
    ) {}

    get text(): string {
        return this.descricao;
    }
}
