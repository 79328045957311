import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'card-com-header-footer',
    templateUrl: './card-com-header-footer.component.html'
})
export class CardComHeaderFooterComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
