import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { first } from 'rxjs/operators';
import { AuthenticationService, VerificaApiService, ToastService } from 'app/_services';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as portugues } from 'app/_i18n/pt-br';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Component({
    selector: 'esqueceu-senha',
    templateUrl: './esqueceu-senha.component.html',
    styleUrls: ['./esqueceu-senha.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations
})
export class EsqueceuSenhaComponent implements OnInit {
    forgotPasswordForm: FormGroup;
    sucessoRecuperacaoSenha = false;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FormBuilder} _formBuilder
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _authenticationService: AuthenticationService,
        private _fuseProgressBarService: FuseProgressBarService,
        private _verificaApiService: VerificaApiService,
        private _toastService: ToastService,
        private _http: HttpClient
    ) {
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                },
                sidepanel: {
                    hidden: true
                }
            }
        };

        this._fuseTranslationLoaderService.loadTranslations(portugues);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.forgotPasswordForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
    }

    async onSubmit(): Promise<void> {
        this._fuseProgressBarService.show();

        if (!(await this._verificaApiService.verificarApiOnline())) {
            this._fuseProgressBarService.hide();
            this._toastService.mensagemError('Não foi possível logar ! A API está fora do ar.');
            return;
        }

        this._authenticationService
            .emailValido(this.forgotPasswordForm.get('email').value)
            .pipe(first())
            .subscribe(
                emailExistente => {
                    if (!emailExistente) {
                        this._fuseProgressBarService.hide();
                        this.forgotPasswordForm.get('email').setErrors({ 'email-existente': true });
                    } else {
                        this.recuperarSenha();
                    }
                },
                erro => {
                    console.log(erro);
                    this._fuseProgressBarService.hide();
                }
            );
    }

    recuperarSenha(): void {
        const email = this.forgotPasswordForm.get('email').value;
        this._authenticationService
            .recuperarSenha(email)
            .pipe(first())
            .subscribe(
                () => {
                    this.sucessoRecuperacaoSenha = true;
                    this._fuseProgressBarService.hide();
                },
                erro => {
                    console.log(erro);
                    this._fuseProgressBarService.hide();
                }
            );
    }
}
