import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class VerificaApiService {
    constructor(private _http: HttpClient) {}

    public async verificarApiOnline(): Promise<boolean> {
        try {
            return !!(await this._http.get<any>(`${environment.apiUrl}online`).toPromise());
        } catch {
            return new Promise(resolve => {
                resolve(false);
            });
        }
    }
}
