<h1 mat-dialog-title>{{ this.data.title }}</h1>
<div mat-dialog-content>
    <p>
        <span *ngIf="this.data.type === 'html'" [innerHTML]="this.data.html"></span>
        <span *ngIf="this.data.type === 'texto'">{{ this.data.msg }}</span>
    </p>
</div>
<footer mat-dialog-actions align="end">
    <button mat-button (click)="this.eventoNao()">{{ "LABELS.NAO" | translate }}</button>
    <button mat-button cdkFocusInitial mat-dialog-close (click)="this.eventoSim()">{{ "LABELS.SIM" | translate }}</button>
</footer>
