import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'foto',
    templateUrl: './foto.component.html',
    styleUrls: ['./foto.component.scss']
})
export class FotoComponent {
    constructor() {}

    @Input() src: string;
    @Output() alterarFoto = new EventEmitter();

    alterar(): void {
        this.alterarFoto.emit();
    }
}
