export const environment = {
    production: false,
    hmr: true,
    baseUrl: '/',
    appDominio: 'https://localhost:4200',
    apiUrl: 'https://localhost:5000/api/',
    quantidadeDiasPeriodoTeste: 14,
    appAgendamentoOnline: 'https://localhost:4200/',
    anonymousTypeControllers: {
        names: ['AgendaOnline']
    },
    firebase: {
        apiKey: 'AIzaSyCmfCknTokucqqUvt21w5EFfcsQHjq2vQo',
        authDomain: 'meu-consultorio-app.firebaseapp.com',
        databaseURL: 'https://meu-consultorio-app.firebaseio.com',
        projectId: 'meu-consultorio-app',
        storageBucket: 'meu-consultorio-app.appspot.com',
        messagingSenderId: '712106092529',
        server_key:
            // tslint:disable-next-line: max-line-length
            'AAAApczUp_E:APA91bFQEsDuaFlpArVKQO_L_05CoJL649YC-niseXAC9c2x5PCY3sXd1xsGG_QN9kafFwWvaNsHRFnxoT67slvQ8-svivCn15VEMcKYGMCflQNDEfGuPzPjFxK-HAYJTxrVVblUF0hO'
    },
    firebase_fcm: {
        apiPostFcmUrl: 'https://fcm.googleapis.com/fcm/send'
    },
    facebook: {
        id: '116345109816944'
    },
    pathImages: {
        logo: 'https://amplum.solutions/cdn/img/clinica/locaisAtendimentos/no-logo.png'
    },
    security: {
        chaveAesCartao: 'Wqg0umr1Ke3R44tTrPHsqy2QF7rgV1Bs',
        ivAesCartao: '9825432104687592',
        chaveAesTelemedicina: 'Z3VzdGF2byBwb2RlIHNlciBtZXUgYW1pZ28h',
        ivAesTelemedicina: '2581362248954780',
        chaveAesEmail: 'PSVJQRk9QTEpNVU1DWUZCRVFGV1VVT0=',
        ivAesEmail: '2314345645678765'
    }
};
