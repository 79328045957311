<div id="recuperar-senha" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
    <div id="recuperar-senha-intro" fxFlex fxHide fxShow.gt-xs>
        <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
            <img src="assets/images/logos/afiliados_branca.png" id="logo-meuconsultorio" [attr.alt]="'ALTS.LOGOTIPO' | translate" />
        </div>

        <div class="title" [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">
            {{ 'LABELS.BEMVINDO' | translate }}
        </div>

        <div class="description" [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }"></div>
        <!-- Rodapé -->
        <div class="container">
            <div fxLayout="row" fxLayout="100" fxLayoutAlign="space-around center" class="footerLogin">
                <a fxFlex class="btnFooter" href="https://amplum.solutions/app/#/seguranca">{{ 'LABELS.POLITICAPRIVACIDADE' | translate }}</a>
                <a fxFlex class="btnFooter text-center" target="_blank" href="https://amplum.solutions">{{ 'LABELS.AJUDA' | translate }}</a>
                <a fxFlex class="btnFooter text-center" target="_blank" href="https://amplum.solutions">{{ 'LABELS.GRUPOSYM' | translate }}</a>
            </div>
        </div>
    </div>

    <div id="recuperar-senha-form-wrapper" fusePerfectScrollbar [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
        <div id="recuperar-senha-form">
            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/afiliados_branca.png" id="logo-meuconsultorio" [attr.alt]="'ALTS.LOGOTIPO' | translate" />
            </div>

            <div class="title">
                {{ 'LABELS.REDEFINASUASENHA' | translate }}
            </div>

            <form name="resetPasswordForm" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()" novalidate>
                <mat-form-field appearance="outline" id="form-field-nova-senha">
                    <mat-label>{{ 'LABELS.NOVASENHA' | translate }}</mat-label>
                    <input matInput type="password" formControlName="novaSenha" (keyup)="valida()" minlength="8" maxlength="25" />
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                </mat-form-field>

                <div fxFlexLayout="row" fxLayout.xs="column" fxLayoutAlign.xs="start" fxLayoutAlign="center center">
                    <small
                        id="alterar-senha-validacao-tamanho"
                        [ngClass]="
                            !resetPasswordForm.get('novaSenha').hasError('minlength') && !resetPasswordForm.get('novaSenha').hasError('required')
                                ? 'success'
                                : 'none'
                        "
                        >{{ 'LABELS.VALIDA_UM' | translate }}
                        -
                    </small>
                    <small id="alterar-senha-validacao-letra" [ngClass]="this.validaLetra ? 'success' : 'none'"
                        >{{ 'LABELS.VALIDA_DOIS' | translate }} -
                    </small>
                    <small id="alterar-senha-validacao-numero" [ngClass]="this.validaNum ? 'success' : 'none'">
                        {{ 'LABELS.VALIDA_TRES' | translate }}
                    </small>
                </div>

                <mat-form-field appearance="outline">
                    <mat-label>{{ 'LABELS.CONFIRMACAO' | translate }}</mat-label>
                    <input matInput type="password" formControlName="confirmaSenha" minlength="8" maxlength="25" />
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="resetPasswordForm.get('confirmaSenha').hasError('required')">
                        {{ 'MENSAGENS.CONFIRMACAOSENHAOBRIGATORIA' | translate }}
                    </mat-error>
                    <mat-error
                        *ngIf="
                            !resetPasswordForm.get('confirmaSenha').hasError('required') &&
                            resetPasswordForm.get('confirmaSenha').hasError('senhasDiferentes')
                        "
                    >
                        {{ 'MENSAGENS.SENHASDIFERENTES' | translate }}
                    </mat-error>
                </mat-form-field>

                <!-- <button mat-raised-button class="submit-button" color="accent" aria-label="RESET MY PASSWORD" [disabled]="resetPasswordForm.invalid"> -->
                <button
                    mat-raised-button
                    class="submit-button"
                    color="accent"
                    [attr.aria-label]="'LABELS.REDEFINIRSENHA' | translate"
                    [disabled]="resetPasswordForm.invalid"
                >
                    {{ 'LABELS.REDEFINIRSENHA' | translate }}
                </button>
            </form>

            <div class="login" fxLayout="row" fxLayoutAlign="center center">
                <a class="link" [routerLink]="'/login'">{{ 'LABELS.VOLTARPARAOLOGIN' | translate }}</a>
            </div>
        </div>
        <div class="register" fxLayout="column" fxLayoutAlign="center center">
            <span class="text">{{ 'LABELS.NAOTEMCONTA' | translate }}</span>
            <a class="link" id="link-register" [routerLink]="'/registrar'">{{ 'LABELS.CADASTREAQUI' | translate }}</a>
        </div>
        <!-- Rodapé -->
        <div class="container" fxHide.gt-xs>
            <div fxLayout="row" fxLayout="100" fxLayoutAlign="space-around center" class="footerLoginMobile pr-16">
                <a fxFlex class="btnFooterMobile" href="https://amplum.solutions/app/#/seguranca">{{ 'LABELS.POLITICAPRIVACIDADE' | translate }}</a>
                <a fxFlex class="btnFooterMobile text-center" target="_blank" href="https://amplum.solutions">{{ 'LABELS.AJUDA' | translate }}</a>
                <a fxFlex class="btnFooterMobile text-center" target="_blank" href="https://amplum.solutions">{{
                    'LABELS.GRUPOSYM' | translate
                }}</a>
            </div>
        </div>
    </div>
</div>
