<div class="pl-8 pr-8">
    <div fxLayoutAlign="center center">
        <h4 *ngIf="this.noFilter" class="texto-sem-itens pl-16" id="texto-sem-itens">
            {{ 'MENSAGENS.PESQUISA_NAO_ENCONTRADA' | translate }}
        </h4>
    </div>

    <ng-container *ngIf="this.listaFiltrada && this.listaFiltrada.length > 0">
        <!-- TABLE -->
        <mat-table id="tabela-configuracoes" [dataSource]="this.listaFiltrada | orderBy: 'asc':'descricao'">
            <!-- Descricao Column -->
            <ng-container [matColumnDef]="nomeColunaDescricao">
                <mat-header-cell *matHeaderCellDef>{{ rotuloColunaValor }}</mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <p class="text-truncate font-weight-600">
                        <span [innerHTML]="item[nomeColunaDescricao]"></span>
                    </p>
                </mat-cell>
            </ng-container>
            <!-- Descricao Column -->

            <!-- Buttons Column -->
            <ng-container matColumnDef="acoes" *ngIf="habilitarAcoes">
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                <mat-cell *matCellDef="let item">
                    <div fxFlex="row" fxLayoutAlign="end center">
                        <button
                            id="tabela-configuracoes-btn-editar"
                            *ngIf="item.podeEditar"
                            mat-icon-button
                            matTooltip="Editar"
                            (click)="this.onEditar(item)"
                        >
                            <mat-icon>edit</mat-icon>
                        </button>
                        <mat-slide-toggle
                            *ngIf="item.podeAtivarInativar && !item.podeExcluir"
                            [(ngModel)]="item.ativo"
                            (change)="this.onAlterarAtivo(item)"
                        >
                        </mat-slide-toggle>
                        <button
                            id="tabela-configuracoes-btn-excluir"
                            *ngIf="item.podeExcluir"
                            mat-icon-button
                            matTooltip="Excluir"
                            (click)="this.onExcluir(item)"
                        >
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </mat-cell>
            </ng-container>
            <!-- Buttons Column -->

            <mat-header-row id="tabela-coluna" *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row id="tabela-configuracao-linhas" class="linha-ativa" *matRowDef="let item; columns: displayedColumns" matRipple> </mat-row>
        </mat-table>
    </ng-container>
</div>
