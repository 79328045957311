import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({ name: 'orderBy' })
export class OrderByPipe implements PipeTransform {
    transform(itens: Array<any>, ordenacao: string = '', coluna: string = ''): Array<any> {
        // Sem valor no Array.
        if (!itens || ordenacao === '' || !ordenacao) {
            return itens;
        }

        // Array unidimensional.
        if (!coluna || coluna === '') {
            return _.sortBy(itens);
        }

        // Array com apenas 1 item.
        if (itens.length <= 1) {
            return itens;
        }

        return _.orderBy(
            itens,
            itens.map(() => {
                return item => {
                    const valorParaOrdenar = item[coluna];
                    return valorParaOrdenar != null ? valorParaOrdenar.toString().toLowerCase() : '';
                };
            }),
            ordenacao === 'desc' ? 'desc' : 'asc'
        );
    }
}
