import { Injectable } from '@angular/core';
import { AuthenticationService } from '..';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseControllerService } from './base-controller.service';
import { ListaMaterialApoio } from '../../_models/material-apoio/resultados/lista-material-apoio';
import { ResultadoController } from 'app/_models';
import { MaterialApoioDto } from 'app/_models/material-apoio/material-apoio-dto';

@Injectable({ providedIn: 'root' })
export class MaterialApoioAfiliadosControllerService extends BaseControllerService {
    constructor(_http: HttpClient, _authenticationService: AuthenticationService) {
        super(_http, _authenticationService);
    }

    public listaMaterialApoio(): Observable<ListaMaterialApoio> {
        return this.get<ListaMaterialApoio>(`MaterialApoio/ListaMaterialApoio`);
    }

    public incluir(file: MaterialApoioDto): Observable<ResultadoController> {
        const formData = new FormData();
        formData.append(file.descricao, file.blob);
        return this.post(`MaterialApoio/Incluir?descricao=${file.descricao}&tipo=${file.tipoAnexo}`, formData);
    }

    public alterar(file: MaterialApoioDto, id: number): Observable<ResultadoController> {
        if (file.blob == null) {
            return this.post(`MaterialApoio/Alterar/${id}?descricao=${file.descricao}&tipo=${file.tipoAnexo}`, null);
        }
        const formData = new FormData();
        formData.append(file.descricao, file.blob);
        return this.post(`MaterialApoio/Alterar/${id}?descricao=${file.descricao}&tipo=${file.tipoAnexo}`, formData);
    }

    public excluir(id: number): Observable<ResultadoController> {
        return this.delete(`MaterialApoio/excluir/${id}`);
    }
}
