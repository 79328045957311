<h3 mat-dialog-title class="title mb-0">
    <div fxLayout="row">
        {{ 'LABELS.DADOS_PAGAMENTO' | translate }}
    </div>
</h3>
<div mat-dialog-content class="mb-24">
    <div fxLayout="row">
        {{ 'DADOS_PAGAMENTO.DESCRICAO' | translate }}
    </div>
    <form fxLayout="column" name="form" [formGroup]="form" fxLayoutAlign="start" class="mt-15">
        <div fxLayout="row wrap">
            <!-- BANCO -->
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="49" fxFlex.lt-md="100" class="mr-10 mat-form-field-no-padding">
                <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.BANCO' | translate }}</mat-label>
                <input id="banco" matInput autocomplete="off" maxlength="100" formControlName="banco" />
                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                <mat-error *ngIf="form.get('banco').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>
            <!-- OPERACAO -->
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="30" fxFlex.lt-md="100" class="mat-form-field-no-padding">
                <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.OPERACAO' | translate }}</mat-label>
                <input
                    id="operacao"
                    matInput
                    autocomplete="off"
                    placeholder="XXXX"
                    type="tel"
                    oninput="this.value=this.value.replace(/(?![0-9])./gmi,'')"
                    maxlength="30"
                    formControlName="operacao"
                />
                <mat-error *ngIf="form.get('operacao').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row wrap">
            <!-- AGENCIA -->
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="24" fxFlex.lt-md="100" class="mr-10 mat-form-field-no-padding">
                <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.AGENCIA' | translate }}</mat-label>
                <input
                    id="agencia"
                    matInput
                    autocomplete="off"
                    type="tel"
                    oninput="this.value=this.value.replace(/(?![0-9])./gmi,'')"
                    placeholder="XXXX"
                    maxlength="30"
                    formControlName="agencia"
                />
                <mat-error *ngIf="form.get('agencia').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>
            <!-- CONTA -->
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="24" fxFlex.lt-md="100" class="mr-10 mat-form-field-no-padding">
                <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.CONTA' | translate }}/dígito</mat-label>
                <input
                    id="conta"
                    matInput
                    autocomplete="off"
                    placeholder="XXXX"
                    type="tel"
                    oninput="this.value=this.value.replace(/(?![0-9_.-])./gmi,'')"
                    maxlength="30"
                    formControlName="conta"
                />
                <mat-error *ngIf="form.get('conta').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>
            <!-- PIX -->
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="49" fxFlex.lt-md="100" class="mat-form-field-no-padding">
                <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.PIX' | translate }}</mat-label>
                <input id="pix" (focusin)="this._removeErrors()" matInput autocomplete="off" maxlength="200" formControlName="pix" />
                <mat-error *ngIf="form.get('pix').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row wrap">
            <!-- NOME -->
            <mat-form-field appearance="outline" fxFlex="49" class="mr-10 mat-form-field-no-padding" fxFlex.lt-md="100">
                <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.NOME_COMPLETO' | translate }}</mat-label>
                <input id="nomeCompleto" matInput autocomplete="off" formControlName="nomeCompleto" maxlength="100" required />
                <mat-error *ngIf="form.get('nomeCompleto').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>
            <!-- CPF/CNPJ -->
            <mat-form-field appearance="outline" fxFlex="49" fxFlex.lt-md="100" class="mat-form-field-no-padding">
                <mat-label>{{ 'DADOS_PAGAMENTO.LABELS.CPF_TITULAR_CONTA' | translate }}</mat-label>
                <input
                    id="cpf-cadastro"
                    (focusin)="this.limparValidacaoCpfCnpj()"
                    (focusout)="this.setValidacaoCpfCnpj()"
                    matInput
                    autocomplete="off"
                    [mask]="this.mascaraCpfCnpj"
                    formControlName="cpfTitular"
                    required
                    maxlength="100"
                />
                <mat-error *ngIf="form.get('cpfTitular').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
                <mat-error
                    id="cpf-cnpj-cadastro-erro-obrigatorio"
                    *ngIf="form.get('cpfTitular').hasError('cpfInvalido') || form.get('cpfTitular').hasError('cnpjInvalido')"
                >
                    {{ 'FORM.VALIDACOES.CPF_INVALIDO' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>

<salvar-cancelar-footer
    (cancelarEmitter)="this.dialogRef.close()"
    (salvarEmitter)="this.salvar()"
    [disabledSave]="this.fuseProgressBarService.visibleValue"
>
</salvar-cancelar-footer>
