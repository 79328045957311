import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) {}
    // tslint:disable-next-line: no-any
    transform(value: any): SafeHtml {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}
