export const locale = {
    lang: 'pt-br',
    data: {
        TITULOS: {
            PAGAMENTOS: 'Pagamentos',
            DETALHES_PAGAMENTO: 'Detalhes do pagamento',
            MATERIAL_APOIO: 'Material de Apoio',
            ADICIONAR_MATERIAL: 'Adicionar material',
            EDITAR_MATERIAL: 'Editar material',
        },
        PLACEHOLDERS: {},
        LABELS: {
            DADOSPESSOAIS: 'Dados pessoais',
            DADOSPROFISSIONAIS: 'Dados profissionais',
            PROFISSIONAL: 'Profissional',
            EMAIL: 'E-mail',
            NAO_INFORMADO: 'Não informado',
            POLITICAPRIVACIDADE: 'Política de privacidade',
            CONTATO: 'Contato',
            AJUDA: 'Ajuda',
            GRUPOSYM: `©${new Date().getFullYear()} WMI Tecnologia Ltda`,
            FACALOGIN: 'Faça login no Afiliados Amplum',
            CLIQUE: 'Clique',
            AQUI: 'aqui',
            ALTERARTIPOLOGIN: 'para alterar o tipo de login para ',
            LEMBRARSENHA: 'Lembrar senha',
            ESQUECEUSENHA: 'Esqueceu a senha?',
            ENTRAR: 'ENTRAR',
            OU: 'OU',
            ENTARCOMGOOGLE: 'Entrar com o Google',
            ENTRARCOMFACEBOOK: 'Entrar com o Facebook',
            BEMVINDO: 'Bem-vindo ao afiliados amplum!',
            BEMVINDOAFILIADO: 'Bem-vindo à plataforma de afiliados do Software Amplum!',
            BEMVINDO_ADMINISTRADOR: 'Conta do Administrador',
            ENTRECOMSUACONTA: 'Entre com sua conta.',
            NAOTEMCONTA: 'Não tem conta?',
            CADASTREAQUI: 'Cadastre-se aqui',
            ESTOUEM: 'Estou em',
            DE: 'De',
            ATE: 'Até',
            CLICK_AQUI: 'Clique aqui',
            AGENDAS: 'Agendas',
            AGENDA: 'Agenda',
            ANEXOS: 'Anexos',
            ANOS: 'anos',
            CABECALHO_RODAPE: 'Cabecalho e rodapé',
            CID: 'CID',
            CNPJ: 'CNPJ',
            CONSULTORIO: 'Consultório',
            CONVENIO: 'Convênio',
            CONVENIOS: 'Convênios',
            CONVIDAR_USANDO_EMAIL: 'Convidar usando e-mail',
            CPF: 'CPF',
            DADOS_PACIENTE: 'Dados do paciente',
            DATA: 'Data',
            DATA_ATENDIMENTO: 'Data de atendimento',
            DATA_DE_COMPARTILHAMENTO: 'Data de compartilhamento',
            DATA_FIM: 'Data fim',
            DATA_INICIO: 'Data início',
            DATA_NASCIMENTO: 'Data de nascimento',
            DATA_DE_CADASTRO: 'Data de cadastro',
            DESCRICAO: 'Descrição',
            EMAIL_COLABORADOR: 'E-mail secretário(a)',
            ENDERECO: 'Endereço',
            ESCOLHA_O_TIPO_ANEXO: 'Escolha o tipo de anexo',
            EXIBIR_ASSINATURA_DIGITAL: 'Exibir assinatura digital',
            FILTRO: 'Filtro',
            FOTO: 'Foto',
            HISTORICO: 'Histórico',
            IDADE: 'Idade',
            INFORMACOES: 'Informações',
            MARGEM_INFERIOR: 'Margem inferior',
            MARGEM_SUPERIOR: 'Margem superior',
            M_INFERIOR: 'M. inferior (cm)',
            M_SUPERIOR: 'M. superior (cm)',
            MEDICO: 'Médico',
            MEDICOS: 'Médicos',
            NOME: 'Nome',
            NOME_MAE: 'Nome da mãe',
            NOME_PACIENTE: 'Nome do paciente',
            NUMERO_PRONTUARIO_PACIENTE: 'Número do prontuário eletrônico do paciente',
            OBSERVACAO: 'Observação',
            PARCELA: 'Parcela',
            PEP: 'PEP',
            PESQUISA_NAO_ENCONTRADA: 'Pesquisa com nenhum resultado encontrado.',
            PESSOA_FISICA: 'Pessoa Física',
            PESSOA_JURIDICA: 'Pessoa Jurídica',
            PREVIA: 'Prévia',
            PREVISTO_REALIZADO: 'Previsto/Realizado',
            RECEITUARIO: 'Receituário',
            REPETICAO: 'Repetição',
            RETIFICACOES: 'Retificacoes',
            RETIFICAR: 'Retificar',
            IMPRIMIR: 'Imprimir',
            WHATSAPP: 'WhatsApp',
            DUPLICAR: 'Duplicar',
            SECRETARIA: 'Secretária(o)',
            SEXO: 'Sexo',
            SOLICITACAO_EXAMES: 'Solicitação de Exames',
            TELEFONE: 'Telefone',
            TELEFONES: 'Telefones',
            PROFISSIONAL_OBRIGATORIO: 'Preenchimento dos profissionais é obrigatório.',
            PROCEDIMENTOS: 'Procedimentos',
            LOCAIS_ATENDIMENTO: 'Consultórios',
            ORDENAR_POR: 'Ordenar por',
            POR_AGENDAMENTO: 'Por agendamento',
            POR_CHEGADA: 'Por chegada',
            NAO_CADASTRADO: 'Não cadastrado.',
            VALOR_NEGATIVO: 'Valor não pode ser negativo',
            CONVITE: 'convite',
            DIGITE_CODIGO: 'Digite o código',
            FORMAS_DE_PAGAMENTO: 'Formas de pagamento',
            CONFIGURACOES_GERAIS: 'Configurações gerais',
            CONFIGURAR_HORARIOS: 'Configurar horarios',
            LINK_PARA_COMPARTILHAMENTO: 'Link para compartilhamento',
            SOBRE_MIM: 'Sobre mim',
            SERVICOS_PRESTADOS: 'Serviços prestados',
            TELEMEDICINA_DISPONIVEL: 'Telemedicina disponível.',
            DIFICULDADEACESSO: 'Está com dificuldades de acesso?',
            NAO_SE_PREOCUPE: 'Não se preocupe, você pode facilmente retomar o acesso ao sistema.',
            RECUPERESUASENHA: 'Recupere sua senha',
            RESETARSENHA: 'RECUPERAR SENHA',
            VOLTARPARAOLOGIN: 'Volte para o login',
            FECHAR: 'Fechar',
            SIM: 'Sim',
            NAO: 'Não',
            SENHA: 'Senha',
            NOME_COMPLETO: 'Nome completo',
            TELEFONE1: '1º Telefone',
            TELEFONE2: '2º Telefone',
            PACIENTE: 'Paciente',
            FORNECEDOR: 'Fornecedor',
            FORNECEDOR_REQUIRED: 'Fornecedor *',
            PROCEDIMENTO: 'Procedimento',
            CEP: 'CEP',
            LOGRADOURO: 'Logradouro',
            NUMERO: 'Nº',
            COMPLEMENTO: 'Complemento',
            BAIRRO: 'Bairro',
            UF: 'UF',
            CIDADE: 'Cidade',
            SIGLA_CONSELHO: 'Sigla do conselho',
            UF_CONSELHO: 'UF do conselho',
            NUMERO_CONSELHO: 'Número do conselho',
            ESPECIALIDADE: 'Especialidade',
            CPF_CNPJ: 'CPF - CNPJ',
            PROFISSIONAIS: 'Profissionais',
            LOCAIS_DE_ATENDIMENTO: 'Consultórios',
            SERVICOS_CADASTRADOS: 'Categorias',
            TIPOS_DE_ATENDIMENTO: 'Tipos de atendimento',
            FORNECEDORES: 'Fornecedores',
            SELECIONAR_TODOS: 'Selecionar Todos',
            PLANO: 'Plano',
            NUMERO_DA_CARTEIRINHA: 'Número da carteirinha',
            VALIDADE: 'Validade',
            INDETERMINADO: 'Indeterminado',
            ENDERECO_NOTA_FISCAL: 'Endereço para nota fiscal',
            REQUIRED: {
                CPF: 'CPF *',
                CNPJ: 'CNPJ *',
                TELEFONE: 'Telefone *',
                EMAIL: 'E-mail *',
                UF: 'UF *',
                CIDADE: 'Cidade *',
                NOME: 'Nome *',
                RAZAO_SOCIAL: 'Razão social *',
                CPF_CNPJ: 'CPF - CNPJ *',
            },
            SIGLACONSELHO: 'Sigla do conselho',
            UFCONSELHO: 'UF do conselho',
            NUMEROCONSELHO: 'Número do conselho',
            FIM: 'Fim',
            REDEFINASUASENHA: 'Redefina a sua senha aqui',
            NOVASENHA: 'Nova senha',
            CONFIRMACAO: 'Nova senha (Confirmação)',
            REDEFINIRSENHA: 'REDEFINIR A SENHA',
            VALIDA_UM: '8 caracteres',
            VALIDA_DOIS: '1 alfabético',
            VALIDA_TRES: '1 numérico',
            VALIDA_QUATRO: 'Confirmar nova senha',
            SENHA_ATUAL: 'Senha atual',
            NOVA_SENHA: 'Nova senha',
            CONFIRMAR_NOVA_SENHA: 'Confirmar nova senha',
            MEU_CODIGO: 'Meu código de indicação ',
            LINK_PARA_INDICACAO: 'Meu link para indicação',
            ATENCAO: 'Atenção',
            ROTINAINDISPONIVELTELA: 'Rotina indisponível para este tamanho de tela',
            RESGATADOS: 'Liberado',
            EM_ANALISE: 'Em análise',
            A_RECEBER: 'Não solicitado',
            PERDIDOS: 'Perdidos',
            CADASTRADOS: 'Cadastrados',
            EM_ATIVIDADE: 'Em atividade',
            A_EXPIRAR: 'A expirar',
            ALTERAR_SENHA: 'Alterar senha',
            ALTERAR_PERFIL: 'Alterar meus dados',
            DADOS_PAGAMENTO: 'Dados de pagamento',
            DATA_CADASTRO: 'Data de cadastro',
            SITUACAO: 'Situação',
            ULTIMO_ACESSO: 'Último acesso',
            VALOR_PLANO: 'Valor do plano',
            VALOR_BONUS: 'Valor do bônus',
            LIBERACAO_BONIFICACAO: 'Liberação da bonificação',
            DATA_COMPRA: 'Data da compra',
            MINHAS_CONVERSOES: 'Minhas conversões',
            MINHAS_INDICACOES: 'Minhas indicações',
            CONVERSOES: 'Conversões',
            INDICACOES: 'Indicações',
            AFILIADO: 'Afiliado',
            DADOS_CONSELHO: 'Dados do Conselho',
            DATA_PEDIDO: 'Data pedido',
            VALOR_TOTAL: 'Valor total',
            DATA_PAGAMENTO: 'Data do pagamento',
            FORMA_PAGAMENTO: 'Forma do pagamento',
            NOTA_FISCAL: 'Nota fiscal',
            PENDENTE: 'Pendente',
            PAGO: 'Pago',
            CONVERSOES_REALIZADAS: 'Conversões realizadas',
            TOTAL_BONUS: 'Total do bônus',
            DESCRICAO_ARQUIVO: 'Descrição do arquivo',
            DETALHES: 'Detalhes',
            DIAS_RESTANTES: 'Dias Restantes',
        },
        BOTOES: {
            SALVAR: 'Salvar',
            EXPORTAR: 'Exportar',
            SOLICITAR_RESGATE: 'Solicitar Resgate',
            ACOES: 'Ações',
            ANEXAR: 'Anexar',
            INSERIR: 'Inserir',
            ARQUIVO: 'Arquivo',
            ATENDIMENTOS_RECENTES: 'Atendimentos recentes',
            ATEND_RECENTES: 'Atend. recentes',
            AUDIO: 'Áudio',
            CANCELAR: 'Cancelar',
            COMPARTILHAR: 'Compartilhar',
            TELEMEDICINA: 'Telemedicina',
            ENCERRAR_CONSULTA: 'Encerrar consulta',
            CONFIRMAR: 'Confirmar',
            CONVIDAR: 'Convidar',
            DIARIO: 'Diário',
            DUPLICAR: 'Duplicar',
            EM_EDICAO: 'Em edição',
            ENVIAR_EMAIL: 'Enviar por e-mail',
            ENVIAR_WHATSAPP: 'Enviar por Whatsapp',
            EXCLUIR: 'Excluir',
            FECHAR: 'Fechar',
            GRAVAR: 'Gravar',
            GRAVAR_E_IMPRIMIR: 'Gravar e imprimir',
            HORARIOS_DISPONIVEIS: 'HORÁRIOS DISPONÍVEIS',
            IMAGEM: 'Imagem',
            IMPRIMIR: 'Imprimir',
            DESFAZER: 'Desfazer',
            MENSAL: 'Mensal',
            NAO: 'Não',
            NOVO_PACIENTE: 'Novo paciente',
            PREVIA: 'Prévia',
            PRONTUARIOS: 'Prontuários',
            SEMANAL: 'Semanal',
            SIM: 'Sim',
            VIDEO: 'Vídeo',
            VINCULAR: 'Vincular',
            VER_HORARIOS: 'Ver horários ',
            FECHAR_HISTORICO: 'Fechar histórico',
            VOLTAR: 'Voltar',
            CONTINUAR: 'Continuar',
            AVANCAR: 'Avançar',
            COMPLETAR_CADASTRO: 'Completar cadastro',
            TESTE_DE_ENVIO: 'Teste de envio',
            FAZER_ASSINATURA: 'Fazer assinatura',
            ESCOLHER_IMAGEM: 'Escolher imagem',
            VERIFICAR: 'Verificar',
            ENVIAR_DOCUMENTOS: 'Enviar documentos',
            ENVIAR_ARQUIVOS: 'Enviar arquivos',
            COMECAR_AGORA: 'Começar agora',
            MAIS_TARDE: 'Mais tarde',
            NAO_EXIBIR_NOVAMENTE: 'Não exibir novamente',
            LI_ACEITO: 'Li e aceito.',
            CADASTRARGOOGLE: 'Cadastrar com Google',
            CADASTRARFACEBOOK: 'Cadastrar com Facebook',
            SECRETARIO: 'Sou Secretário(a)',
            MEDICO: 'Sou Médico(a)',
            PROXIMO: 'Próximo',
            ANTERIOR: 'Anterior',
            CADASTRAR: 'Cadastrar',
            CPF: 'CPF',
            ALTERAR_SENHA: 'Alterar senha',
            ALTERAR_PERFIL: 'Alterar perfil',
            ESCOLHER_FOTO: 'Escolher foto',
            CAPTURAR_FOTO: 'Capturar foto',
            PAGAR: 'Pagar',
        },
        MENSAGENS: {
            AGENDA_EXCLUIDA_SUCESSO: 'Agenda excluída com sucesso',
            ASSOCIACAO_EXCLUIDA: 'Associação excluída com sucesso!',
            ASSINATURA_ALTERADA_SUCESSO: 'Assinatura alterada com sucesso',
            ASSINATURA_VAZIA: 'Faça a assinatura.',
            CIDJAADICIONADA: 'CID já adicionada.',
            CONFIRMACAOFECHAMENTO: 'Deseja realmente fechar?',
            CONFIRMACAOSENHAOBRIGATORIA: 'A confirmação da senha é obrigatória',
            CONFIRMAREXCLUSAO: 'Confirmar exclusão',
            CONVITE_ENVIADO_SUCESSO: 'Convite enviado com sucesso!',
            CPFINVALIDO: 'Por favor informe um CPF válido',
            CPFNAOINFORMADO: 'CPF não foi informado',
            DADOS_OBRIGATORIOS_INVALIDOS: 'Dados obrigatorios não preenchidos corretamente.',
            DADOSNAOSALVOS: 'Existem dados não salvos!',
            DADOSSALVOS: 'Os dados foram salvos com sucesso.',
            DATA_FIM_MENOR: 'Data fim menor',
            DESEJA_EXCLUIR_ASSOCIACAO: 'Deseja realmente excluir a associação com',
            DESEJAEXCLUIR: 'Deseja realmente excluir ',
            DESEJA_CONTINUAR_TROCAR_PAGINA: 'Você ira sair desta página, deseja continuar?',
            DESEJA_EXCLUIR_AGENDAMENTO_PACIENTE_TEMPORARIO:
                'Ao excluir este agendamento, o paciente e os registros de recebimentos vinculados a ele também serão excluídos. Deseja continuar?',
            DESEJA_EXCLUIR_PACIENTE_TEMPORARIO:
                'Ao excluir este paciente, os agendamentos e registros de recebimentos vinculados a ele também serão excluídos. Deseja continuar?',
            DESEJA_REALMENTE_EXCLUIR_CATEGORIA: 'Deseja realmente excluir a categoria?',
            DESEJASALVAR: 'Deseja realmente fechar?',
            EDITAR_CONFIGURACOES_AGENDA: 'Editar configurações da agenda',
            EMAIL_ENVIADO_SUCESSO: 'E-mail enviado com sucesso!',
            EMAIL_ALTERADO_SUCESSO: 'E-mail alterado com sucesso!',
            EMAIL_REENVIADO_SUCESSO: 'E-mail reenviado com sucesso!',
            EMAIL_ENVIADO: 'E-mail enviado.',
            EMAILINEXISTENTE: 'E-mail inexistente em nossa base de dados',
            MEDICO_NAO_ENCONTRADO: 'Médico não encontrado.',
            PACIENTE_NAO_ENCONTRADO: 'Paciente não encontrado.',
            COLABORADOR_NAO_ENCONTRADO: 'Secretário(a) não encontrado.',
            USUARIO_JA_ADICIONADO: 'Usuário já adicionado na lista.',
            MEDICOS_CONVIDADOS_COM_SUCESSO: 'Médicos convidados com sucesso!',
            EMAILINVALIDO: 'Por favor informe um e-mail válido',
            EMAILNAOINFORMADO: 'E-mail não foi informado',
            ENTREEMCONTATOCOMOGRUPOSYM: 'Erro! Entre em contato com o WMI!',
            EXAMEJACADASTRADO: 'Exame já cadastrado',
            EXCLUIR_AGENDAMENTO: 'Excluir agendamento',
            EXCLUSAOSUCESSO: 'Exclusão realizada com sucesso.',
            FEEDBACKENVIADO: 'Feedback enviado!',
            ENDERECO_AGENDAMENTO_INVALIDO: 'O endereço do agendamento não é válido',
            ENDERECO_AGENDAMENTO_INVALIDO_TEXTO: 'O endereço do agendamento não é válido. Verifique se foi digitado corretamente.',
            AGENDA_ONLINE_INDISPONIVEL: 'Agendamento online indisponível',
            NENHUM_ITEM_CADASTRADO: 'Nenhum item cadastrado.',
            NAOCONSEGUELOGAR: 'Não foi possível logar!',
            NAOFOIPOSSIVELFACE: 'Não foi possível realizar o login utilizando o Facebook',
            NAOFOIPOSSIVELFACECREDENCIAIS: 'Não foi possível logar com o Facebook. Verifique as credenciais informadas.',
            NAOFOIPOSSIVELGOOGLE: 'Não foi possível realizar o login utilizando sua conta Google.',
            NAOFOIPOSSIVELGOOGLECREDENCIAIS: 'Não foi possível logar com sua conta Google. Verifique as credenciais informadas.',
            NAOFOIPOSSIVELLOGARAPIFORADOAR: 'Não foi possível logar! A API está fora do ar.',
            NAOHADADOSSALVAR: 'Não há dados para salvar.',
            NAOTEMCADFACECADASTRAR: 'Conta Facebook não cadastrada no sistema, deseja realizar o cadastro agora?',
            NAOTEMCADGOOGLACADASTRAR: 'Conta Google não cadastrada no sistema, deseja realizar o cadastro agora?',
            PESQUISA_NAO_ENCONTRADA: 'Resultado de pesquisa não encontrado.',
            POR_FAVOR_SELECIONE_ASSINATURA: 'Por favor, selecionea uma imagem da assinatura',
            POP_UP_HABILITADO: 'Pop-up bloqueada! Habilite o pop-up para visualizar o documento. ',
            DUPLICAR_REGISTRO: 'Duplicar registro',
            JA_EXISTE_CATEGORIA_COM_ESTE_NOME: 'Já existe categoria com este nome.',
            PREENCHER_CONVITE: 'Preencher com pelo menos um convite.',
            SEMINFORMACAO: 'Nenhuma informação a exibir',
            CARREGANDO_INFORMACOES: 'Carregando informações...',
            SENHAOBRIGATORIA: 'A senha é obrigatória',
            SENHASDIFERENTES: 'As senhas digitadas não são iguais',
            USUARIOSENHAINVALIDOS: 'Não foi possível logar! Usuário e/ou senha inválidos',
            PACIENTE_EXCLUIDO_SUCESSO: 'Paciente excluido com sucesso',
            FUNCAO_NAO_HABILIDATA: 'Função não habilitada',
            PLANO_INVALIDO: 'O plano atual não possui essa funcionalidade',
            NAO_FOI_POSSIVEL_PROCESSAR_SOLICITACAO: 'Não foi possível processar a sua solicitação.',
            PROBLEMA_AO_PROCESSAR_SOLICITACAO: 'Houve um problema ao processar a sua solicitação.',
            FORAM_ENCONTRADOS_CARACTERES_INVALIDOS: 'Foram encontrados caracteres inválidos.',
            CARACTERES_FORAM_SUBSTITUIDOS: 'Estes caracteres foram substituídos por:',
            CORRIJA_TEXTO_TENTE_NOVAMENTE: 'Corrija o texto e tente salvar novamente.',
            NAO_PERMITIDO_AGENDAMENTO_DIA_RETROATIVO: 'Não é permitido agendar para dias retroativos.',
            DATA_PASSADA: 'Data passada',
            VALOR_INVALIDO: 'Valor inválido',
            CODIGO_AFILIADO_COPIADO: 'Seu código de indicação foi copiado com sucesso.',
            LINK_DIVULGACAO_COPIADO: 'Seu link para indicação foi copiado com sucesso.',
            DESEJA_REALMENTE_SAIR: 'Deseja realmente sair?',
            PERMITIDO_APENAS_UM_ARQUIVO: 'Para adicionar um novo arquivo, remova o já anexado.',
            APENAS_UM_ARQUIVO_ANEXADO: 'Apens um arquivo foi anexado.',
            ENDERECO_TELEMEDICINA_INVALIDO: 'Endereço para acesso a telemedicina inválido.',
            PACIENTES_ENCONTRADOS_MESCLAR:
                'Foram encontrados os pacientes abaixo com dados semelhantes. ' +
                'Selecione o paciente desejado ou clique em "Não mesclar paciente" caso seja um novo paciente.',
            NOVO_PLANO_ATIVADO: 'Novo plano ativado com sucesso.',
            BEMVINDO: 'Seja bem-vindo ao Afiliados Amplum!',
            ENTRARDADOS: 'Entre com seus dados pessoais e desfrute da melhor ferramenta de gerenciamento de consultórios',
            JACADASTRADO: 'Já tenho cadastro',
            TERMOUSO1: 'Ao finalizar o cadastro você concorda com o',
            TERMOUSO2: 'termo de uso',
            VOLTARLOGIN: 'Clique aqui',
            SUCESSOCADASTRO: 'Cadastro feito com sucesso!',
            EH_NECESSARIO_ACEITAR_TERMO: 'É necessário aceitar o termo de uso.',
            CEP_NAO_LOCALIZADO: 'O CEP informado não existe ou não foi localizado.',
            DADOS_SAO_OBRIGATORIOS_TITULO: 'Complete seu cadastro',
            DADOS_SAO_OBRIGATORIOS_MENSAGEM: 'Esses são dados obrigatórios. Deseja realmente sair?',
            SOLICITAR_RESGATE: 'Sua solicitação foi enviada ao administrativo e você receberá um contato em até 5 dias úteis.',
            CAMERA_NAO_ENCONTRADA: 'Não foi possível utilizar a sua câmera!',
            PAGAMENTO_NAO_PODE_SER_ALTERADO: 'Esse pagamento poderá ser alterado apenas um dia após a data de pedido do afiliado.',
            INSTRUCAO_INDICACOES_ADMIN:
                'Aqui você consegue ver todos os profissionais que realizaram o primeiro cadastro a partir de um link de indicação, ' +
                'e acompanhar como está sendo a taxa de utilização do sistema destes profissionais. <br /> <br />' +
                '<b>Cadastrados:</b> Quantidade de contas cadastradas no amplum através de um código de indicação ou link. <br /> <br />' +
                '<b>Perdidos:</b> Quantidade de contas cadastradas no amplum através de um código de indicação e que não se converteram até o término do período de teste.<br /> <br />' +
                '<b>Em atividade:</b> Quantidade de contas cadastradas no amplum através de um código de indicação e que estão utilizando o sistema dentro do período de testes e que não estão a dois dias do término do período de teste.<br /> <br />' +
                '<b>A expirar:</b> Quantidade de contas cadastradas no amplum através de um código de indicação e que estão a dois dias do término do período de teste. <br /> <br />',
            INSTRUCAO_INDICACOES_AFILIADO:
                'Aqui você consegue ver todos os profissionais que realizaram o primeiro cadastro a partir do seu link de indicação, ' +
                'e acompanhar como está sendo a taxa de utilização do sistema destes profissionais, para que você possa planejar as suas ações. <br /> <br />' +
                '<b>Cadastrados:</b> Quantidade de contas cadastradas no amplum através do meu código de indicação ou link. <br /> <br />' +
                '<b>Perdidos:</b> Quantidade de contas cadastradas no amplum através do meu link de indicação e que não se converteram até o término do período de teste. Obs: Em caso de contratação posterior, o convite ainda será válido e o bônus creditado.<br /> <br />' +
                '<b>Em atividade:</b> Quantidade de contas cadastradas no amplum através do meu link de indicação e que estão utilizando o sistema dentro do período de testes e que não estão a dois dias do término do período de teste.<br /> <br />' +
                '<b>A expirar:</b> Quantidade de contas cadastradas no amplum através do meu link de indicação e que estão a dois dias do término do período de teste. <br /> <br />',
            INSTRUCAO_CONVERCOES_AFILIADO:
                'Aqui você acompanha todos os profissionais que compraram o sistema, visualiza o resultado real, ' +
                'das suas indicações e as bonificações que você terá para resgatar. <br /> <br />' +
                '<b>Não solicitado:</b> Valor total de bônus liberado para a minha conta, e que ainda não foi solicitado o resgate. <br /> <br />' +
                '<b>Em análise:</b> Valor total de bônus liberado para a minha conta, e que foi solicitado o resgate. <br /> <br />' +
                '<b>Liberado:</b> Valor total de bônus liberado para a minha conta, e foi aprovado pela empresa.',
            INSTRUCAO_CONVERCOES_ADMIN:
                'Aqui você acompanha todos os profissionais que compraram o sistema, visualiza o resultado real das indicações ' +
                'e as bonificações a serem pagas. <br /> <br />' +
                '<b>Não solicitado:</b> Valor total de bônus conquistado pelos afiliados cujo resgate não foi solicitado. <br /> <br />' +
                '<b>Em análise:</b> Valor total de bônus conquistado pelos afiliados , cujo resgate foi solicitado e que está pendente de aprovação pela empresa. <br /> <br />' +
                '<b>Liberado:</b> Valor total de bônus conquistado pelos afiliados  que teve aprovação da  empresa.',
            CONFIRMAR_EXCLUSAO: 'Confirmar exclusão',
            CONFIRMACAO_EXCLUSAO: 'Deseja realmente excluir o material?',
            DADOS_PAGAMENTO_SUCESSO: 'Dados salvos com sucesso!',
        },

        INFO_BROWSER: {
            MENSAGEM: ' Para melhor utilização dos recursos do Afiliados Amplum, por favor acesse através do Chrome ou Firefox.',
            LINKS: 'Para realizar o download, basta clicar em um dos links:',
            CHROME: 'Chrome',
            FIREFOX: 'Firefox',
            OU: 'ou',
        },
        INFO_IOS: {
            MENSAGEM_AVISO_PACIENTE:
                'Para melhor utilização dos recursos do Afiliados Amplum, por favor acesse através de um computador ' +
                'com câmera ou dispositivo Android. Estamos trabalhando e em breve disponibilizaremos para todos os dispositivos.',
            MENSAGEM_AVISO_MEDICO:
                'Para melhor utilização dos recursos do Afiliados Amplum, por favor informe o paciente para acessar ' +
                'através de um computador com câmera ou dispositivo Android. Estamos trabalhando e em breve disponibilizaremos ' +
                'para todos os dispositivos.',
        },
        FORM: {
            VALIDACOES: {
                FORMULARIO_INVALIDO: 'Formulário inválido.',
                CAMPO_OBRIGATORIO: 'Campo obrigatório!',
                TIPO_INVALIDO: 'Tipo inválido',
                CPF_EXISTENTE: 'CPF já existente para outro usuário!',
                CNPJ_EXISTENTE: 'CNPJ já existente!',
                CPF_INVALIDO: 'CPF inválido!',
                CPF_NAO_PODE_ALTERAR: 'Este CPF não pode ser alterado!',
                CPF_CNPJ_INVALIDO: 'CPF/CNPJ inválido!',
                CNPJ_INVALIDO: 'CNPJ inválido!',
                CNPJ_MIN_14: 'CNPJ deve conter 14 caracteres!',
                EMAIL_EXISTENTE: 'E-mail já existente para outro usuário!',
                EMAIL_INVALIDO: 'E-mail inválido!',
                DATA_FIM_MENOR: 'Data fim menor que a data inical',
                MAXLENGTH: {
                    LENGTH_INVALIDO: 'Comprimento máximo não respeitado.',
                    MAX9: 'O campo deve conter no máximo 9 caracteres',
                    MAX50: 'O campo deve conter no máximo 50 caracteres',
                    MAX100: 'O campo deve conter no máximo 100 caracteres',
                    MAX130: 'O campo deve conter no máximo 130 caracteres',
                },
                MINLENGTH: {
                    MIN05: 'O campo deve conter no mínimo 5 caracteres',
                },
                SENHAUM: 'Mínimo 8 caracteres - ',
                SENHADOIS: '1 alfabético - ',
                SENHATRES: '1 numérico',
                ERROSIGLA: 'Sigla do conselho é obrigatória!',
                ERROUF: 'UF do conselho é obrigatória!',
                ERRONUMERO: 'Número do conselho inválido!',
                ERROTELEFONE: 'Telefone é obrigatório!',
                ERROCAMPO: 'Campo obrigatório!',
                ERROSENHA: 'Ter no mínimo 8 caracteres',
                ERROSENHAINVALIDA: 'Senha inválida',
                ERRONOME: 'Nome é obrigatório!',
                ERROEMAILUM: 'E-mail existente!',
                ERROEMAIDOIS: 'Favor informar um novo email.',
            },
        },
        DADOS_PAGAMENTO: {
            DESCRICAO: 'Informe a conta bancária ou PIX cujo bônus será recebido.',
            LABELS: {
                BANCO: 'Banco',
                OPERACAO: 'Operação',
                AGENCIA: 'Agência',
                CONTA: 'Conta',
                PIX: 'PIX',
                NOME_COMPLETO: 'Nome completo do titular da conta/PIX',
                CPF_TITULAR_CONTA: 'CPF do titular da conta',
            },
        },
    },
};
