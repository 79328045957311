<h2 mat-dialog-title class="title">
    <div id="modal-enviar-feedback-form-titulo" fxLayout="row">
        <span>{{ 'FEEDBACK.TITULO' | translate }}</span>
    </div>
</h2>
<form id="modal-enviar-feedback-form" name="form" [formGroup]="form">
    <div mat-dialog-content>
        <div fxLayout="row wrap" class="texto-informativo">
            <span>{{ 'FEEDBACK.TEXTOINFORMATIVOINICIO' | translate }}</span>
            <span>{{ 'FEEDBACK.TEXTOINFORMATIVOCOMPLEMENTO' | translate }}</span>
        </div>
        <div fxLayout="row wrap">
            <mat-form-field appearance="outline" fxFlex="100" fxFlex.sm="100" fxFlex.md="100" fxFlex.xs="100">
                <mat-label>{{ 'FORM.LABELS.DESCRICAO' | translate }}</mat-label>
                <textarea
                    id="modal-enviar-feedback-text-area-descricao"
                    matInput
                    maxlength="700"
                    formControlName="descricao"
                    matTextareaAutosize
                    matAutosizeMinRows="15"
                    matAutosizeMaxRows="15"
                    placeholder="{{ 'FEEDBACK.PLACEHOLDER' | translate }}"
                ></textarea>
                <mat-error id="modal-enviar-feedback-error-descricao-obrigatoria" *ngIf="form.get('descricao').hasError('required')">{{
                    'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate
                }}</mat-error>
            </mat-form-field>
        </div>
    </div>

    <salvar-cancelar-footer
        (cancelarEmitter)="this.dialogRef.close()"
        (salvarEmitter)="this.enviarFeedback()"
        [disabledSave]="this.fuseProgressBarService.visibleValue"
    >
    </salvar-cancelar-footer>
</form>
