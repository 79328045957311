import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'app/_services';

export abstract class BaseControllerService {
    constructor(private http: HttpClient, private _authenticationService: AuthenticationService) {}

    protected get idUsuario(): number {
        return this._authenticationService.currentUserValue.idUsuario;
    }

    protected get roleUsuario(): number {
        return this._authenticationService.currentUserValue.perfil;
    }

    protected get planoId(): number {
        return this._authenticationService.currentUserValue.customProperties['PlanoAtualId'];
    }

    protected get<T>(rota: string): Observable<T> {
        return this.http.get<T>(`${environment.apiUrl}${rota}`);
    }

    // tslint:disable-next-line: no-any
    protected post<T, TParam>(rota: string, body: TParam | any): Observable<T> {
        return this.http.post<T>(`${environment.apiUrl}${rota}`, body);
    }

    // tslint:disable-next-line: no-any
    protected put<T, TParam>(rota: string, body: TParam | any): Observable<T> {
        return this.http.put<T>(`${environment.apiUrl}${rota}`, body);
    }

    // tslint:disable-next-line: no-any
    protected patch<T, TParam>(rota: string, body: TParam | any): Observable<T> {
        return this.http.patch<T>(`${environment.apiUrl}${rota}`, body);
    }

    protected delete<T>(rota: string): Observable<T> {
        return this.http.delete<T>(`${environment.apiUrl}${rota}`);
    }
}
