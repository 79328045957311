import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'salvar-cancelar-footer',
    templateUrl: './salvar-cancelar-footer.component.html',
})
export class SalvarCancelarFooterComponent {
    @Input() public disabledSave: boolean;
    @Input() public disabledCancel: boolean;
    @Output() public cancelarEmitter: EventEmitter<any> = new EventEmitter();
    @Output() public salvarEmitter: EventEmitter<any> = new EventEmitter();

    constructor() {}

    public cancelarEmit(): void {
        this.cancelarEmitter.emit();
    }

    public salvarEmit(): void {
        this.salvarEmitter.emit();
    }
}
