import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/internal/operators';
import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { VerificaApiService, ToastService, AuthenticationService, DialogService } from 'app/_services';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetarSenhaDto } from 'app/_models';

@Component({
    selector: 'recuperar-senha',
    templateUrl: './recuperar-senha.component.html',
    styleUrls: ['./recuperar-senha.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: fuseAnimations,
})
export class RecuperarSenhaComponent implements OnInit, OnDestroy {
    resetPasswordForm: FormGroup;
    validaLetra: boolean;
    validaNum: boolean;
    validaIgual: boolean;
    formValid: boolean = false;
    idUsuario: number;
    role: number;
    token: string;
    // tslint:disable-next-line: no-any
    private _unsubscribeAll: Subject<any>;

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private _fuseProgressBarService: FuseProgressBarService,
        private _verificaApiService: VerificaApiService,
        private _toastService: ToastService,
        private _authenticationService: AuthenticationService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _dialogService: DialogService
    ) {
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true,
                },
                toolbar: {
                    hidden: true,
                },
                footer: {
                    hidden: true,
                },
                sidepanel: {
                    hidden: true,
                },
            },
        };

        this._unsubscribeAll = new Subject();
    }

    ngOnInit(): void {
        this.resetPasswordForm = this._formBuilder.group({
            novaSenha: ['', [Validators.required, Validators.pattern('^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$')]],
            confirmaSenha: ['', [Validators.required, confirmacaoSenhaValidator]],
        });

        this.resetPasswordForm
            .get('novaSenha')
            .valueChanges.pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.resetPasswordForm.get('confirmaSenha').updateValueAndValidity();
            });

        this.token = this._route.snapshot.queryParams['token'] || null;

        if (this.token === null) {
            this._router.navigate(['/']);
        } else {
            this.idUsuario = this._route.snapshot.queryParams['id'];
            this.role = this._route.snapshot.queryParams['role'];
        }
    }

    valida(): void {
        const novaSenha: string = this.resetPasswordForm.get('novaSenha').value;

        novaSenha.match('[A-Za-z]') ? (this.validaLetra = true) : (this.validaLetra = false);
        novaSenha.match('[0-9]{1}') ? (this.validaNum = true) : (this.validaNum = false);

        if (this.validaLetra && this.validaNum) {
            this.formValid = true;
        } else {
            this.formValid = false;
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    async onSubmit(): Promise<void> {
        if (this.resetPasswordForm.invalid) {
            return;
        }

        this._fuseProgressBarService.show();

        if (!(await this._verificaApiService.verificarApiOnline())) {
            this._fuseProgressBarService.hide();
            this._toastService.mensagemError('Não foi possível logar ! A API está fora do ar.');
            return;
        }

        const dadosReset = new ResetarSenhaDto();

        dadosReset.idUsuario = this.idUsuario;
        dadosReset.roleId = this.role;
        dadosReset.token = this.token;
        dadosReset.senha = btoa(this.resetPasswordForm.get('novaSenha').value);

        this._authenticationService
            .resetarSenha(dadosReset)
            .pipe(first())
            .subscribe(
                (retorno) => {
                    if (retorno.sucesso) {
                        this._dialogService.dialogInformacao(
                            'Atenção',
                            'Sua senha foi resetada com sucesso! Faça o login no afiliados amplum.',
                            () => {
                                this._fuseProgressBarService.hide();
                                this._router.navigate(['/']);
                            }
                        );
                    } else {
                        this._toastService.mensagemError(`Não foi possível resetar a sua senha. Detalhes: ${retorno.mensagemErro}`);
                        this._fuseProgressBarService.hide();
                        this._router.navigate(['/']);
                    }
                },
                (erro) => {
                    console.log(erro);
                    this._fuseProgressBarService.hide();
                }
            );
    }
}

export const confirmacaoSenhaValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    if (!control.parent || !control) {
        return null;
    }

    const senha = control.parent.get('novaSenha');
    const confirmacaoSenha = control.parent.get('confirmaSenha');

    if (!senha || !confirmacaoSenha) {
        return null;
    }

    if (confirmacaoSenha.value === '') {
        return null;
    }

    if (senha.value === confirmacaoSenha.value) {
        return null;
    }

    return { senhasDiferentes: true };
};
