import { Input, Output, OnInit, Component, EventEmitter, ViewChild, AfterViewInit, HostListener, DoCheck, IterableDiffers } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { ListaColunas, ListaAcoes } from './lista-colunas-acoes.model';
import { TipoComponenteAcao } from 'app/_models/enums/tipo-componente-acao';
import { ListaColunasAcoesEvento } from './lista-colunas-acoes-evento.model';
import { formatarTelefone } from 'app/_functions/formatar-telefone';
registerLocaleData(localePt);

@Component({
    selector: 'lista-colunas-acoes',
    templateUrl: './lista-colunas-acoes.component.html',
    styleUrls: ['./lista-colunas-acoes.component.scss'],
    providers: [{ provide: LOCALE_ID, useValue: 'pt-BR' }],
})

// tslint:disable: no-any
export class ListaColunasAcoesComponent implements AfterViewInit, OnInit, DoCheck {
    @Input() colunas: Array<ListaColunas>;
    @Input() acoes: Array<ListaAcoes> = new Array<ListaAcoes>();
    @Input() dados: Array<any>;
    @Input() index: string = 'id';
    @Input() funcaoAcoesLinha: CallableFunction;
    @Input() nomeColunaAtivoInativo: string;
    @Input() scroll: boolean = true;
    @Output() eventoAcao: EventEmitter<ListaColunasAcoesEvento> = new EventEmitter();

    @ViewChild(MatSort, { static: true }) sort: MatSort;

    private _larguraTela: number;
    private _differ: any;
    public matDados: MatTableDataSource<any> = new MatTableDataSource<any>();
    public tipoDado: typeof TipoDado = TipoDado;
    public tipoComponenteAcao: typeof TipoComponenteAcao = TipoComponenteAcao;
    public acoesLinhas: Map<string, Array<string>>;
    public codigoAcaoClickLinha: string;
    public possuiAcoes: boolean = false;
    public formatarTelefone: CallableFunction = formatarTelefone;

    constructor(differs: IterableDiffers) {
        this._differ = differs.find([]).create(null);
    }

    ngOnInit(): void {
        this._larguraTela = window.innerWidth;
    }

    ngDoCheck(): void {
        if (this._differ.diff(this.dados)) {
            this._setMatDados();
        }
    }

    ngAfterViewInit(): void {
        this._setMatDados();
        this.setCodigoAcaoClickLinha();
    }

    private setCodigoAcaoClickLinha(): void {
        if (!!this.acoes && this.acoes.length) {
            this.acoes.forEach((acao) => {
                if (acao.tipoComponenteAcao === TipoComponenteAcao.CLICK_LINE) {
                    this.codigoAcaoClickLinha = acao.codigo;
                }
            });
        }
    }

    private _setMatDados(): void {
        this.matDados = new MatTableDataSource(this.dados);
        this.matDados.sort = this.sort;
        this._setAcoesLinhas();
    }

    get colunasVisiveis(): Array<string> {
        const colunasVisiveis: Array<string> = new Array();

        this.colunas.forEach((coluna) => {
            if (this._larguraTela >= coluna.visibilidade) {
                colunasVisiveis.push(coluna.key);
            }
        });

        if (this.possuiAcoes) {
            colunasVisiveis.push('acoes');
        }

        return colunasVisiveis;
    }

    public onClickAcao(event: any, codigoBotao: string): void {
        this.eventoAcao.emit({
            acao: codigoBotao,
            dados: event,
        });
    }

    public onClickLinha(event: any): void {
        if (this.possuiAcao(event, this.codigoAcaoClickLinha)) {
            this.onClickAcao(event, this.codigoAcaoClickLinha);
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any): void {
        this._larguraTela = window.innerWidth;
    }

    private _setAcoesLinhas(): void {
        this.possuiAcoes = false;

        if (!this.dados || !this.funcaoAcoesLinha) {
            return;
        }
        this.acoesLinhas = new Map();

        this.dados.forEach((linha) => {
            const acoes: Array<string> = this.funcaoAcoesLinha(linha);
            if (acoes.length > 0) {
                this.possuiAcoes = true;
                this.acoesLinhas.set(linha[this.index], acoes);
            }
        });
    }

    public possuiAcao(linha: any, acao: string): boolean {
        if (!acao) {
            return false;
        }
        if (this.acoesLinhas.has(linha[this.index])) {
            const acoesLinha: Array<string> = this.acoesLinhas.get(linha[this.index]);
            if (acoesLinha.includes(acao)) {
                return true;
            }
        }
        return false;
    }

    public formatarTexto(colunaKey: string, maxLengthKey: number): string {
        if (!maxLengthKey) {
            return colunaKey;
        }

        if (!colunaKey) {
            return '';
        }

        return colunaKey.length > maxLengthKey ? colunaKey.substr(0, maxLengthKey - 3) + '..' : colunaKey;
    }
}
