import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { TipoComponenteAcao } from 'app/_models/enums/tipo-componente-acao';

export class ListaColunas {
    descricao: string;
    decricaoAlt?: string;
    visibilidade: TamanhosFlexLayout;
    key: string;
    tooltipKey?: string;
    maxLengthKey?: number;
    tipoDado?: TipoDado = TipoDado.TEXTO;
    width?: string = 'auto';
    position?: number;
}

export class ListaAcoes {
    codigo: string;
    icon: string;
    decricaoAlt?: string;
    tipoComponenteAcao: TipoComponenteAcao;
    ngModel?: string;
}

export function orderByColunasByPosition(itens: Array<ListaColunas>): Array<ListaColunas> {
    return itens.sort((a, b) => {
        if (a.position > b.position) {
            return 1;
        } else if (a.position < b.position) {
            return -1;
        } else {
            return 0;
        }
    });
}
