export function formatarTelefone(telefone: string): string {
    if (!telefone) {
        return;
    }
    const telefoneNumeros = telefone.replace(/\D/g, '');

    if (telefoneNumeros.length === 11) {
        return telefoneNumeros.replace(/(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
    }
    return telefoneNumeros.replace(/(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
}
