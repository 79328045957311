import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthenticationService, DialogService } from 'app/_services';

@Injectable({ providedIn: 'root' })
export class InicioGuard implements CanActivate {
    constructor(private router: Router, private _authenticationService: AuthenticationService, private _dialogService: DialogService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        const planoAtual = this._authenticationService.currentUserValue.customProperties.PlanoAtualId;
        if (planoAtual === 5) {
            this.router.navigate(['configuracoes/consultorios']);
        }

        return true;
    }
}
