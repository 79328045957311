import { AbstractControl, FormControl } from '@angular/forms';

export function removeErroForm(control: AbstractControl | FormControl, erro: string): void {
    const err = control.errors;
    if (err) {
        delete err[erro];
        if (!Object.keys(err).length) {
            control.setErrors(null);
        } else {
            control.setErrors(err);
        }
    }
}
