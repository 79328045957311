import { DatePipe } from '@angular/common';
import { TraducaoService } from 'app/_services';
import { ListaColunas } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { ColunaExportacao } from 'app/_models/exportacao/coluna-exportacao';
import { DadosParaExportacaoBasicoDto } from 'app/_models/exportacao/dados-para-exportacao-basico-dto';
import { Dictionary } from 'lodash';
import { IndicadoresIndicacoes } from 'app/_models/conta-profissional/indicadores-indicacoes';
import { Indicacoes } from 'app/_models/conta-profissional/indicacoes';
import { IndicacoesPerfilBaseService } from 'app/main/inicio/indicacoes-conversoes/indicacoes/indicacoes-perfil-base.service';
import { ExportarPdfService } from 'app/_services/exportacao/exportar-pdf.service';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { ExportaXLSXService } from 'app/_services/exportacao/exporta-xlsx.service';

export class IndicacoesAfiliadoService extends IndicacoesPerfilBaseService {
    constructor(
        protected _indicadores: IndicadoresIndicacoes,
        protected _indicacoes: Array<Indicacoes>,
        protected _i18n: TraducaoService,
        private _exportarPdfService: ExportarPdfService,
        private _exportaXLSXService: ExportaXLSXService,
        private datePipe: DatePipe
    ) {
        super(_indicadores, _i18n);
    }

    public getColunas(): Array<ListaColunas> {
        const colunasBase = this.getColunasBase();

        const colunasAfiliado = new Array<ListaColunas>({
            descricao: this._i18n.get('LABELS.EMAIL'),
            visibilidade: TamanhosFlexLayout.md,
            key: 'email',
            maxLengthKey: 30,
            position: 4,
            tipoDado: TipoDado.TEXTO,
        });

        return colunasAfiliado.concat(colunasBase);
    }

    public getMensagemInstrucao(): string {
        return `${this._i18n.get('MENSAGENS.INSTRUCAO_INDICACOES_AFILIADO')}`;
    }

    public exportarParaPdf(indicacoes: Array<Indicacoes>): void {
        this._indicacoesParaExportar = indicacoes;
        const dadosExportacao = this.getDadosParaExportacaoBasicoDto();
        this._exportarPdfService.gerarPdfAfiliados(dadosExportacao);
    }

    public formatarData(data: Date | string): string {
        return this.datePipe.transform(data, 'dd/MM/yyyy');
    }

    public exportarParaExcel(itens: Array<Indicacoes>): void {
        const retorno = new Array<Dictionary<string>>();
        itens.forEach((conversao) => {
            const dict = {
                'Nome': conversao.nome,
                'Data Cadastro': this.formatarData(conversao.dataCadastro),
                'E-mail': conversao.email,
                'Telefone': !!conversao.telefone ? conversao.telefone.toString() : '',
                'Plano': conversao.nomePlano,
                'Últ. acesso': this.formatarData(conversao.ultimoAcesso),
                'Situação': conversao.situacaoDescricao,
            };
            retorno.push(dict);
        });

        this._exportaXLSXService.exportAsExcelFile(retorno, 'indicacoes');
    }

    protected getDadosParaExportacaoBasicoDto(): DadosParaExportacaoBasicoDto {
        const colunasParaExportacao = [
            new ColunaExportacao('nome', 'Profissional', 1, 100),
            new ColunaExportacao('dataCadastro', 'Cadastro', 2, 50),
            new ColunaExportacao('email', 'Email', 3, 100),
            new ColunaExportacao('telefone', 'Telefone', 4, 70),
            new ColunaExportacao('nomePlano', 'Plano', 5, 60),
            new ColunaExportacao('ultimoAcesso', 'Últ. acesso', 6, 60),
            new ColunaExportacao('situacaoDescricao', 'Situação', 7, 50),
        ];

        const dadosParaExportacaoBasicoDto = new DadosParaExportacaoBasicoDto(
            colunasParaExportacao,
            this.mapearDadosPdf(),
            this._i18n.get('LABELS.MINHAS_INDICACOES')
        );

        return dadosParaExportacaoBasicoDto;
    }

    protected mapearDadosPdf(): Array<Dictionary<string>> {
        const retorno = new Array<Dictionary<string>>();

        this._indicacoesParaExportar.forEach((minhasInd) => {
            const dict = {
                nome: minhasInd.nome,
                dataCadastro: this.formatarData(minhasInd.dataCadastro),
                email: minhasInd.email,
                telefone: !!minhasInd.telefone ? minhasInd.telefone.toString() : '',
                nomePlano: minhasInd.nomePlano,
                ultimoAcesso: this.formatarData(minhasInd.ultimoAcesso),
                situacaoDescricao: minhasInd.situacaoDescricao,
            };

            retorno.push(dict);
        });

        return retorno;
    }
}
