import { Injectable } from '@angular/core';
import { Dictionary } from 'lodash';
import { DadosParaExportacaoBasicoDto } from 'app/_models/exportacao/dados-para-exportacao-basico-dto';
import { ListaColunas } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { SubMenu } from 'app/_models/configuracoes/financeiro/submenus';
import { Indicadores } from 'app/main/componentes/tabela-com-filtro-indicadores/indicadores';
import { TraducaoService } from 'app/_services/traducao.service';
import { IndicacoesConversoesBase } from 'app/_models/conta-profissional/indicacoes-conversoes-base';
import { CurrencyPipe, formatCurrency } from '@angular/common';
import { IndicadoresConversoes } from 'app/_models/conta-profissional/indicadores-conversoes';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { Conversoes } from 'app/_models/conta-profissional/conversoes';

@Injectable({
    providedIn: 'root',
})
export abstract class ConversoesPerfilBaseService {
    protected _conversoesParaExportar: Array<Conversoes>;

    constructor(protected _indicadores: IndicadoresConversoes, protected _i18n: TraducaoService, protected _currencyPipe: CurrencyPipe) {}

    public abstract getColunas(): Array<ListaColunas>;
    public abstract getMensagemInstrucao(): string;
    public abstract exportarParaPdf(itens: Array<Conversoes>): void;
    public abstract exportarParaExcel(itens: Array<Conversoes>): void;
    protected abstract getDadosParaExportacaoBasicoDto(): DadosParaExportacaoBasicoDto;
    protected abstract mapearDadosPdf(): Array<Dictionary<string>>;

    public getItens<TClass extends IndicacoesConversoesBase>(indicacoes: Array<TClass>): Array<TClass> {
        return indicacoes;
    }

    public getDadosSubMenu(): Array<SubMenu> {
        const subMenus = new Array<SubMenu>();

        const aReceber: SubMenu = {
            descricao: `${this._i18n.get('LABELS.A_RECEBER')}: ${formatCurrency(this._indicadores.aReceber, 'pt-BR', 'R$')}`,
            id: 0,
        };

        const pendentes: SubMenu = {
            descricao: `${this._i18n.get('LABELS.EM_ANALISE')}: ${formatCurrency(this._indicadores.pendentes, 'pt-BR', 'R$')}`,
            id: 0,
        };

        const resgatados: SubMenu = {
            descricao: `${this._i18n.get('LABELS.RESGATADOS')}: ${formatCurrency(this._indicadores.resgatados, 'pt-BR', 'R$')}`,
            id: 0,
        };

        subMenus.push(pendentes);
        subMenus.push(resgatados);
        subMenus.push(aReceber);

        return subMenus;
    }

    public getIndicadores(): Array<Indicadores> {
        const indicadores = new Array<Indicadores>();

        const aReceber: Indicadores = {
            descricao: `${this._i18n.get('LABELS.A_RECEBER')}`,
            valor: formatCurrency(this._indicadores.aReceber, 'pt-BR', 'R$'),
            cor: 'green',
        };

        const pendentes: Indicadores = {
            descricao: `${this._i18n.get('LABELS.EM_ANALISE')}`,
            valor: `${formatCurrency(this._indicadores.pendentes, 'pt-BR', 'R$')}`,
            cor: 'red',
        };

        const resgatados: Indicadores = {
            descricao: `${this._i18n.get('LABELS.RESGATADOS')}`,
            valor: `${formatCurrency(this._indicadores.resgatados, 'pt-BR', 'R$')}`,
            cor: 'gray',
        };

        indicadores.push(aReceber);
        indicadores.push(pendentes);
        indicadores.push(resgatados);

        return indicadores;
    }

    protected getColunasBase(): Array<ListaColunas> {
        let colunas = new Array<ListaColunas>();

        colunas = new Array<ListaColunas>(
            {
                descricao: this._i18n.get('LABELS.PROFISSIONAL'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'nomeFoto',
                tipoDado: TipoDado.INNER_HTML,
                tooltipKey: 'nome',
                position: 2,
            },
            {
                descricao: this._i18n.get('LABELS.TELEFONE'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'telefone',
                tipoDado: TipoDado.TELEFONE,
            },
            {
                descricao: this._i18n.get('LABELS.DATA_COMPRA'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'dataCompra',
                tipoDado: TipoDado.DATA,
            },
            {
                descricao: this._i18n.get('LABELS.VALOR_PLANO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'valorPlano',
                tipoDado: TipoDado.MONETARIO,
            },
            {
                descricao: this._i18n.get('LABELS.VALOR_BONUS'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'valorBonus',
                tipoDado: TipoDado.MONETARIO,
            },
            {
                descricao: this._i18n.get('LABELS.LIBERACAO_BONIFICACAO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'situacaoBonusDescricao',
                tipoDado: TipoDado.TEXTO,
            }
        );

        return colunas;
    }
}
