import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ToastService } from 'app/_services/toast.service';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { locale as portugues } from 'app/_i18n/pt-br';
import { FeedbackDTO } from 'app/_models/feedback/feedback-dto';
import { FeedbackControllerService } from 'app/_services/controllers/feedback-controller.service';
import { TraducaoService } from 'app/_services/traducao.service';

@Component({
    selector: 'modal-enviar-feedback',
    templateUrl: './modal-enviar-feedback.component.html',
    styleUrls: ['./modal-enviar-feedback.component.scss']
})
export class ModalEnviarFeedbackComponent implements OnInit {
    private _feedbackDTO: FeedbackDTO;
    public form: FormGroup;

    /**
     * Constructor
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     */
    constructor(
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _formBuilder: FormBuilder,
        private _toastService: ToastService,
        private _feedbackService: FeedbackControllerService,
        public dialogRef: MatDialogRef<ModalEnviarFeedbackComponent>,
        public fuseProgressBarService: FuseProgressBarService,
        private _i18n: TraducaoService
    ) {
        this._fuseTranslationLoaderService.loadTranslations(portugues);
    }
    ngOnInit(): void {
        this.construirFormulario();
    }
    construirFormulario(): void {
        this.form = this._formBuilder.group({
            descricao: ['', [Validators.required, Validators.maxLength(700)]]
        });
    }

    enviarFeedback(): void {
        if (!this.form.valid) {
            return;
        }

        this._feedbackDTO = Object.assign(this.form.value);

        this.fuseProgressBarService.show();
        this._feedbackService.incluirFeeback(this._feedbackDTO).subscribe(
            () => {
                this._toastService.mensagemSuccess(this._i18n.get('MENSAGENS.FEEDBACKENVIADO'));
                this.dialogRef.close();
                this.dialogRef.afterClosed().subscribe(() => this.fuseProgressBarService.hide());
            },
            erro => {
                this.fuseProgressBarService.hide();

                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOGRUPOSYM'));

                console.log(erro);
            }
        );
    }
}
