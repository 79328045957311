import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
    selector: 'dias-da-semana',
    templateUrl: './dias-da-semana.component.html',
    styleUrls: ['./dias-da-semana.component.css']
})
export class DiasDaSemanaComponent implements OnInit {
    @Output() public changeDias: any = new EventEmitter();
    public diasDaSemanaForm: FormGroup;
    public diasSemanas = [
        { id: 0, descricao: 'Dom', reduzido: 'D', checked: false },
        { id: 1, descricao: 'Seg', reduzido: 'S', checked: false },
        { id: 2, descricao: 'Ter', reduzido: 'T', checked: false },
        { id: 3, descricao: 'Qua', reduzido: 'Q', checked: false },
        { id: 4, descricao: 'Qui', reduzido: 'Q', checked: false },
        { id: 5, descricao: 'Sex', reduzido: 'S', checked: false },
        { id: 6, descricao: 'Sab', reduzido: 'S', checked: false }
    ];
    constructor(private _formBuilder: FormBuilder) {}

    ngOnInit() {
        this.diasDaSemanaForm = this._formBuilder.group({
            domingo: [false],
            segunda: [false],
            terca: [false],
            quarta: [false],
            quinta: [false],
            sexta: [false],
            sabado: [false]
        });
    }

    selecionarDia(id: number): void {
        this.diasSemanas[id].checked = !this.diasSemanas[id].checked;
        this.changeDias.emit();
    }
}
