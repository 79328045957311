export enum TipoAnexo {
    ANEXO = 0,
    AUDIO = 1,
    IMAGEM = 2,
    VIDEO = 3,
    LAUDO = 4,
    SOLICITACAO = 5,
    RECIBO = 6,
    IMPRESSO = 7,
    RECEITA = 8,
    TEXTO = 9
}
