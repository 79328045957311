import { Component, OnInit } from '@angular/core';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ListaAcoes, ListaColunas } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { AuthenticationService, ToastService, TraducaoService } from 'app/_services';
import { ContaProfissionalAfiliadoControllerService } from 'app/_services/controllers/conta-profissional-afiliado-controller.service';
import { ExportarPdfService } from 'app/_services/exportacao/exportar-pdf.service';
import { ExportaXLSXService } from 'app/_services/exportacao/exporta-xlsx.service';
import { SubMenu } from 'app/_models/configuracoes/financeiro/submenus';
import { Indicacoes } from 'app/_models/conta-profissional';
import { DadosBasicoUsuarioDTO } from 'app/_models/conta-profissional-afiliado/dados-basicos-usuario-dto';
import { TipoComponenteAcao } from 'app/_models/enums/tipo-componente-acao';
import { ListaColunasAcoesEvento } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes-evento.model';
import { ColunaExportacao } from 'app/_models/exportacao/coluna-exportacao';
import { DadosParaExportacaoBasicoDto } from 'app/_models/exportacao/dados-para-exportacao-basico-dto';
import { Dictionary } from 'lodash';
import { formatCurrency, formatDate, DatePipe } from '@angular/common';
import * as format from 'date-fns/format';

@Component({
    selector: 'app-afiliados',
    templateUrl: './afiliados.component.html',
    styleUrls: ['./afiliados.component.scss'],
})
export class AfiliadosComponent implements OnInit {
    public carregamentoInicial: boolean = true;
    public mostrarDetalhe: boolean = false;
    public colunas: Array<ListaColunas> = new Array<ListaColunas>();
    public acoes: Array<ListaAcoes> = new Array<ListaAcoes>();
    public itens: Array<DadosBasicoUsuarioDTO> = new Array<DadosBasicoUsuarioDTO>();
    public itensbkp: Array<DadosBasicoUsuarioDTO> = new Array<DadosBasicoUsuarioDTO>();
    public dados: DadosBasicoUsuarioDTO = new DadosBasicoUsuarioDTO();
    public subMenus: Array<SubMenu>;
    protected mensagemInstrucao: string = '';
    protected _conversoesParaExportar: Array<DadosBasicoUsuarioDTO>;

    constructor(
        public fuseProgressBarService: FuseProgressBarService,
        private _i18n: TraducaoService,
        private _contaProfissionalAfiliadoControllerService: ContaProfissionalAfiliadoControllerService,
        private _toastService: ToastService,
        private _authenticationService: AuthenticationService,
        private _exportarPdfService: ExportarPdfService,
        private _exportaXLSXService: ExportaXLSXService,
        private datePipe: DatePipe
    ) {}

    ngOnInit() {
        this._listarAfiliados();
        this._colunasBase();
        this._dadosSubMenu();

        this._setAcoes();
    }

    private _listarAfiliados(): void {
        this.fuseProgressBarService.show();
        this._contaProfissionalAfiliadoControllerService.listarAfiliados().subscribe(
            (res) => {
                if (res.sucesso) {
                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                    this.itens = res.afiliados;
                } else {
                    this.carregamentoInicial = false;
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOGRUPOSYM'));
                console.log(err);
            }
        );
    }

    private _dadosSubMenu(): void {
        this.subMenus = new Array<SubMenu>();
    }

    private _colunasBase(): void {
        this.colunas = new Array<ListaColunas>(
            {
                descricao: this._i18n.get('LABELS.AFILIADO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'nome',
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.CPF'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'cpf',
                tipoDado: TipoDado.CPF,
            },
            {
                descricao: this._i18n.get('LABELS.EMAIL'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'email',
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.TELEFONE'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'telefone',
                tipoDado: TipoDado.TELEFONE,
            },
            {
                descricao: this._i18n.get('LABELS.DATA_CADASTRO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'dataCadastro',
                tipoDado: TipoDado.DATA,
            }
        );
    }

    private _setAcoes(): void {
        this.acoes = [
            {
                codigo: 'CLICK',
                icon: '',
                tipoComponenteAcao: TipoComponenteAcao.CLICK_LINE,
            },
        ];
    }

    public getAcoesLinha(agenda: any): Array<string> {
        if (!agenda) {
            return [];
        }
        return ['CLICK'];
    }

    public eventoAcao(evento: ListaColunasAcoesEvento): void {
        if (evento.acao === 'CLICK') {
            this.mostrarDetalhe = true;
            this.dados = evento.dados;
        }
    }

    public acaoFechar(): void {
        this.dados = new DadosBasicoUsuarioDTO();
        this.carregamentoInicial = true;
        this._listarAfiliados();
        this.mostrarDetalhe = false;
    }

    public exportarParaPdf(itens: Array<DadosBasicoUsuarioDTO>): void {
        this._conversoesParaExportar = itens;
        const dadosExportacao = this.getDadosParaExportacaoBasicoDto();
        this._exportarPdfService.gerarPdfAfiliados(dadosExportacao);
    }

    public formatarData(data: Date | string): string {
        return this.datePipe.transform(data, 'dd/MM/yyyy');
    }

    protected getDadosParaExportacaoBasicoDto(): DadosParaExportacaoBasicoDto {
        const colunasParaExportacao = [
            new ColunaExportacao('nome', 'Afiliado', 1, 100),
            new ColunaExportacao('cpf', 'CPF', 2, 60),
            new ColunaExportacao('email', 'Email', 3, 100),
            new ColunaExportacao('telefone', 'Telefone', 4, 60),
            new ColunaExportacao('dataCadastro', 'Data de cadastro', 5, 50),
        ];

        const dadosParaExportacaoBasicoDto = new DadosParaExportacaoBasicoDto(
            colunasParaExportacao,
            this.mapearDadosPdf(),
            this._i18n.get('LABELS.MINHAS_CONVERSOES')
        );

        return dadosParaExportacaoBasicoDto;
    }
    protected mapearDadosPdf(): Array<Dictionary<string>> {
        const retorno = new Array<Dictionary<string>>();

        this._conversoesParaExportar.forEach((conversao) => {
            const dict = {
                nome: conversao.nome,
                cpf: conversao.cpf,
                email: conversao.email,
                telefone: !!conversao.telefone ? conversao.telefone.toString() : '',
                dataCadastro: this.formatarData(conversao.dataCadastro),
            };

            retorno.push(dict);
        });

        return retorno;
    }

    public exportarParaXLSX(itens: Array<DadosBasicoUsuarioDTO>): void {
        const retorno = new Array<Dictionary<string>>();
        itens.forEach((conversao) => {
            const dict = {
                'Nome': conversao.nome,
                'CPF': conversao.cpf,
                'E-mail': conversao.email,
                'Telefone': !!conversao.telefone ? conversao.telefone.toString() : '',
                'Data Cadastro': this.formatarData(conversao.dataCadastro),
            };
            retorno.push(dict);
        });

        this._exportaXLSXService.exportAsExcelFile(retorno, 'afiliados');
    }
}
