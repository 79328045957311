import { ResultadoAlterarPerfil } from 'app/_models/alterar-perfil/resultado-alterar-perfil';
import { Injectable } from '@angular/core';
import { AuthenticationService } from '..';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseControllerService } from './base-controller.service';
import { CaminhoImagem, ResultadoController, CadastroConta } from 'app/_models';
import { PerfilAfiliadoDto } from 'app/_models/alterar-perfil/perfil-afiliado-dto';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';
import { ResultadoListarIndicacoes } from 'app/_models/conta-profissional/resultados/resultado-listar-indicacoes';
import { ResultadoListarConversoes } from 'app/_models/conta-profissional/resultados/resultado-listar-minhas-conversoes';

@Injectable({
    providedIn: 'root',
})
export class ContaProfissionalControllerService extends BaseControllerService {
    public idUsuario: number;
    public planoId: number;

    constructor(_http: HttpClient, _authenticationService: AuthenticationService) {
        super(_http, _authenticationService);
    }

    public selecionarContaProfissional(): Observable<PerfilAfiliadoDto> {
        return this.get<PerfilAfiliadoDto>(`ContaProfissional`);
    }

    public alterarContaProfissionalComPerfilAfiliado(profissional: PerfilAfiliadoDto): Observable<ResultadoAlterarPerfil> {
        return this.put<ResultadoAlterarPerfil, PerfilAfiliadoDto>(`ContaProfissional/alterarContaProfissionalComPerfilAfiliado`, profissional);
    }

    public alterarSenha(senha: string): Observable<number> {
        const obj = {
            senha: senha,
        };
        return this.put<number, object>(`ContaProfissional/Alterarsenha`, obj);
    }

    public validarSenha(senha: string): Observable<boolean> {
        const dados = {
            senha: senha,
        };

        return this.post<boolean, object>(`ContaProfissional/VerificarSenha`, dados);
    }

    public incluirContaProfissional(usuario: CadastroConta): Observable<ResultadoController> {
        return this.post<ResultadoController, CadastroConta>(`ContaProfissional`, usuario);
    }

    public cpfEhValido(cpf: string): Observable<boolean> {
        return this.get<boolean>(`ContaProfissional/VerificarCpf/${cpf}`);
    }

    public emailEhValido(email: string): Observable<boolean> {
        return this.get<boolean>(`ContaProfissional/VerificarEmail/${email}`);
    }

    public termoUso(): Observable<{ termo: string }> {
        return this.get<{ termo: string }>(`ContaProfissional/TermoContrato`);
    }

    public listarIndicacoesPorAfiliado(roleUsuarioMeuConsultorio?: RoleUsuario): Observable<ResultadoListarIndicacoes> {
        let rota = 'ContaProfissional/ListarIndicacoesPorAfiliado';

        if (!!roleUsuarioMeuConsultorio) {
            rota += `?roleUsuarioMeuConsultorio=${roleUsuarioMeuConsultorio}`;
        }

        return this.get<ResultadoListarIndicacoes>(rota);
    }

    public listarIndicacoesPorAdministrador(): Observable<ResultadoListarIndicacoes> {
        return this.get<ResultadoListarIndicacoes>('ContaProfissional/ListarIndicacoesPorAdministrador');
    }

    public listarConversoesPorAfiliado(roleUsuarioMeuConsultorio?: RoleUsuario): Observable<ResultadoListarConversoes> {
        let rota = 'ContaProfissional/ListarConversoesPorAfiliado';

        if (!!roleUsuarioMeuConsultorio) {
            rota += `?roleUsuarioMeuConsultorio=${roleUsuarioMeuConsultorio}`;
        }

        return this.get<ResultadoListarConversoes>(rota);
    }

    public listarConversoesPorAdministrador(): Observable<ResultadoListarConversoes> {
        return this.get<ResultadoListarConversoes>('ContaProfissional/ListarConversoesPorAdministrador');
    }

    public enviarEmailParaConfirmacao(): Observable<ResultadoController> {
        return this.post<ResultadoController, null>(`ContaProfissional/EnviarEmailParaConfirmacao`, null);
    }

    public alterarEmailConfirmadoContaProfissional(codigo: string): Observable<ResultadoController> {
        return this.put<ResultadoController, null>(`ContaProfissional/AlterarEmailConfirmadoContaProfissional/${codigo}`, null);
    }

    public uploadImagem(formData: FormData, id: number): Observable<CaminhoImagem> {
        return this.post<CaminhoImagem, FormData>(`ContaProfissional/AlterarFoto`, formData);
    }
}
