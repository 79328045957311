import { MASCARA_CELULAR, MASCARA_TELEFONE_CELULAR, MASCARA_TELEFONE } from 'app/_constants/mascaras';

export function mascaraTelefone(texto: string): string {
    if (!texto) {
        return;
    }

    const match = texto.match(/\d/g);
    let numerosString = '';

    if (match) {
        numerosString = match.join('');
    }

    // Regra digito 9
    if (numerosString.length > 2) {
        if (numerosString[2] === '9') {
            return MASCARA_CELULAR;
        } else {
            return MASCARA_TELEFONE;
        }
    }

    // Regra a ser usada caso não faça verificação pelo dígito 9
    // if (numeros.length > 10) {
    //     return MASCARA_CELULAR;
    // }

    return MASCARA_TELEFONE_CELULAR;
}
