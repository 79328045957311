<h2 mat-dialog-title class="title">
    <div id="alterar-dados-titulo" fxLayout="row">
        {{ 'LABELS.ALTERAR_PERFIL' | translate }}
    </div>
</h2>

<div mat-dialog-content class="mb-24">
    <form id="alterar-dados-form" class="mr-8" fxLayout="row wrap" fxLayoutAlign="space-between center" name="form" [formGroup]="form">
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <div fxFlex.xs="100" fxFlex.gt-xs="49" fxLayoutAlign="center center">
                <foto fxFlex.xs="100" fxLayoutAlign="center center" (alterarFoto)="this.alterarFoto()" [src]="this.afiliado?.caminhoFoto"></foto>
            </div>
            <div fxLayout.xs="row wrap" fxLayout.gt-xs="column" fxFlex.xs="100" fxFlex.gt-xs="49">
                <!-- Radio CPF/CNPJ -->
                <mat-radio-group *ngIf="!this.ocultarOpcaoCpfCnpj()" fxFlex="100" formControlName="radioCpfCnpj">
                    <mat-radio-button value="cpf" id="check-cpf" class="pr-16">
                        {{ 'LABELS.PESSOA_FISICA' | translate }}
                    </mat-radio-button>
                    <mat-radio-button value="cnpj" id="check-cnpj">
                        {{ 'LABELS.PESSOA_JURIDICA' | translate }}
                    </mat-radio-button>
                </mat-radio-group>

                <!-- CPF/CNPJ -->
                <mat-form-field appearance="outline" fxFlex="49" fxFlex.lt-md="100" ngClass.gt-sm="mt-12">
                    <mat-label>{{ (isPessoaJuridica() ? 'LABELS.CNPJ' : 'LABELS.CPF') | translate }}</mat-label>
                    <input
                        id="cpf-cnpj-cadastro"
                        (focusin)="this.limparValidacaoCpfCnpj()"
                        (focusout)="this.setValidacaoCpfCnpj()"
                        matInput
                        [mask]="this.mascaraCpfCnpj"
                        formControlName="cpfcnpj"
                        required
                    />
                    <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                    <mat-error *ngIf="form.get('cpfcnpj').hasError('required')">
                        {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                    </mat-error>
                    <mat-error
                        id="cpf-cnpj-cadastro-erro-obrigatorio"
                        *ngIf="form.get('cpfcnpj').hasError('cpfInvalido') || form.get('cpfcnpj').hasError('cnpjInvalido')"
                    >
                        {{ (isPessoaJuridica() ? 'FORM.VALIDACOES.CNPJ_INVALIDO' : 'FORM.VALIDACOES.CPF_INVALIDO') | translate }}
                    </mat-error>
                </mat-form-field>

                <!-- NOME -->
                <mat-form-field appearance="outline" fxFlex="49" fxFlex.lt-md="100">
                    <mat-label>{{ 'LABELS.NOME_COMPLETO' | translate }}</mat-label>
                    <input id="alterar-dados-nome" matInput formControlName="nome" required />
                    <mat-icon matSuffix class="secondary-text">account_circle</mat-icon>
                    <mat-error> {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}</mat-error>
                </mat-form-field>
            </div>
        </div>
        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
            <!-- EMAIL -->
            <mat-form-field appearance="outline" fxFlex="49" fxFlex.lt-md="100">
                <mat-label>{{ 'LABELS.EMAIL' | translate }}</mat-label>
                <input
                    id="email"
                    matInput
                    type="email"
                    maxlength="100"
                    placeholder="Informe seu Email"
                    formControlName="email"
                    required
                    pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"
                    (keyup)="validaEmail()"
                />
                <mat-icon matSuffix class="secondary-text">email</mat-icon>
                <mat-error *ngIf="form.get('email').hasError('required')"> {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}</mat-error>
                <mat-error *ngIf="form.get('email').hasError('existente') && !form.get('email').hasError('required')">
                    <strong>Email já cadastrado!</strong> Favor informar um novo email.
                </mat-error>
                <mat-error *ngIf="!validarEmail && !form.get('email').hasError('existente') && !form.get('email').hasError('required')"
                    >Informe um email válido</mat-error
                >
            </mat-form-field>

            <!-- Telefone -->
            <mat-form-field appearance="outline" fxFlex="49" fxFlex.lt-md="100">
                <mat-label>{{ 'LABELS.TELEFONE' | translate }}</mat-label>
                <input
                    matInput
                    type="tel"
                    placeholder="Informe seu telefone"
                    formControlName="telefone"
                    required
                    id="telefoneCadastro"
                    [mask]="this.mascaraTelefone"
                />
                <mat-icon matSuffix class="secondary-text">local_phone</mat-icon>
                <mat-error> {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}</mat-error>
            </mat-form-field>

            <!-- Sexo -->
            <mat-form-field id="alterar-dados-sexo" appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                <mat-label>{{ 'LABELS.SEXO' | translate }}</mat-label>
                <mat-select formControlName="sexo">
                    <mat-option value="Feminino">Feminino</mat-option>
                    <mat-option value="Masculino">Masculino</mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text">face</mat-icon>
            </mat-form-field>

            <!-- Data nascimento -->
            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                <mat-label>{{ 'LABELS.DATA_NASCIMENTO' | translate }}</mat-label>
                <input
                    id="alterar-dados-data-nascimento"
                    matInput
                    [matDatepicker]="dp"
                    formControlName="dataNascimento"
                    startView="multi-year"
                    maxlength="20"
                    [max]="this.dataAtual"
                    (keypress)="this.onKeyPressData($event)"
                />
                <mat-datepicker-toggle matSuffix [for]="dp"></mat-datepicker-toggle>
                <mat-datepicker #dp></mat-datepicker>

                <mat-error id="alterar-dados-data-obrigatoria" *ngIf="this.form.get('dataNascimento').hasError('required')">{{
                    'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate
                }}</mat-error>
            </mat-form-field>

            <!-- Endereço -->
            <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxFlex.xs="100" fxFlex.sm="100" fxFlex.gt-sm="30">
                    <mat-form-field appearance="outline" fxFlex="85">
                        <mat-label>{{ 'LABELS.CEP' | translate }}</mat-label>
                        <input id="cep-cadastro" matInput formControlName="cep" type="text" mask="00.000-000" required />
                        <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                        <mat-error *ngIf="form.get('cep').hasError('required')">
                            {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <div class="mr-8 mt-12 ml-4" fxFlex="auto">
                        <button id="btn-buscar-cep" type="button" (click)="this.carregarEndereco()" mat-mini-fab color="primary" class="btn-interno">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="69" fxFlex.gt-sm="48">
                    <mat-label>{{ 'LABELS.LOGRADOURO' | translate }}</mat-label>
                    <input id="logradouro-cadastro" maxlength="100" matInput formControlName="logradouro" required />
                    <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                    <mat-error *ngIf="form.get('logradouro').hasError('required')">
                        {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                    </mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="29" fxFlex.gt-sm="18">
                    <mat-label>{{ 'LABELS.NUMERO' | translate }}</mat-label>
                    <input id="numero-cadastro" matInput formControlName="numero" mask="999999999" required />
                    <mat-error *ngIf="form.get('numero').hasError('required')">
                        {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                    </mat-error>
                </mat-form-field>
            </div>

            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                <mat-label>{{ 'LABELS.COMPLEMENTO' | translate }}</mat-label>
                <input id="complemento-cadastro" maxlength="100" matInput formControlName="complemento" />
                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                <mat-label>{{ 'LABELS.BAIRRO' | translate }}</mat-label>
                <input id="bairro-cadastro" maxlength="100" matInput formControlName="bairro" required />
                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                <mat-error *ngIf="form.get('bairro').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field id="uf-cadastro" appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                <mat-label>{{ 'LABELS.UF' | translate }}</mat-label>
                <mat-select formControlName="uf" (selectionChange)="this.listarCidadesPorUf($event.value)" required>
                    <mat-option *ngFor="let estado of this.estados" [value]="estado.id">{{ estado.uf }}</mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                <mat-error *ngIf="form.get('cidade').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>

            <mat-form-field id="cidade-cadastro" appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                <mat-label>{{ 'LABELS.CIDADE' | translate }}</mat-label>
                <mat-select formControlName="cidade" required>
                    <mat-option *ngFor="let cidade of this.cidades" [value]="cidade.id">{{ cidade.descricao }}</mat-option>
                </mat-select>
                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                <mat-error *ngIf="form.get('cidade').hasError('required')">
                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</div>

<salvar-cancelar-footer
    (cancelarEmitter)="this.fecharModalAlterarPerfil()"
    (salvarEmitter)="this.alterarUsuario()"
    [disabledSave]="this.fuseProgressBarService.visibleValue"
>
</salvar-cancelar-footer>
