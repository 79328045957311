import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { User, UserAuditoria, Auditoria, ResetarSenhaDto, ResultadoController } from 'app/_models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { adBlockHabilitado } from 'app/_helpers';
import { TipoLogin } from 'app/_models/enums/tipo-login';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private _currentUserSubject: BehaviorSubject<User>;
    public CurrentUser: Observable<User>;
    private iP: string;
    private http: HttpClient;

    constructor(handler: HttpBackend, private _deviceService: DeviceDetectorService) {
        this.http = new HttpClient(handler);
        this._currentUserSubject = new BehaviorSubject<User>(JSON.parse(sessionStorage.getItem('currentUser')));
        this.CurrentUser = this._currentUserSubject.asObservable();
        adBlockHabilitado().subscribe((habilitado) => {
            if (habilitado) {
                this.iP = '0.0.0.0';
            } else {
                this.http.get<{ ip: string }>('https://jsonip.com').subscribe((data) => {
                    this.iP = data.ip;
                });
            }
        });
    }

    public get currentUserValue(): User {
        return this._currentUserSubject.value;
    }

    public set currentUserValue(user: User) {
        this.setUser(user);
    }

    public set caminhoFoto(caminhoFoto: string) {
        this._currentUserSubject.subscribe((currentUser) => {
            currentUser.caminhoFoto = caminhoFoto;
        });
    }

    public login(identificacao: string, password: string, tipoLogin: TipoLogin, emailGoogleFacebook: string = ''): Observable<User> {
        const userAudit = new UserAuditoria();
        userAudit.usuario = new User();
        userAudit.usuario.identificacao = btoa(identificacao);
        userAudit.usuario.email = tipoLogin === TipoLogin.LoginFacebook || tipoLogin === TipoLogin.LoginGoogle ? emailGoogleFacebook : identificacao;
        userAudit.usuario.senha = btoa(password);
        userAudit.usuario.tipo = tipoLogin;

        const navigator = window.navigator;
        if (navigator != null) {
            userAudit.auditoria = new Auditoria();
            const deviceInfo = this._deviceService.getDeviceInfo();

            userAudit.auditoria.iP = this.iP;
            userAudit.auditoria.navegador = deviceInfo.browser;
            userAudit.auditoria.versaoNavegador = deviceInfo.browser_version;
        }

        return this.http.post<User>(`${environment.apiUrl}auth/login`, userAudit).pipe(
            map((user) => {
                this.setUser(user);
                return user;
            })
        );
    }

    public emailValido(email: string): Observable<boolean> {
        return this.http.get<boolean>(`${environment.apiUrl}auth/validarEmail/${email}`);
    }

    public usuarioTemPermissaoParaVisualizarLaudo(): boolean {
        return !!this.currentUserValue.cpf && !!this.currentUserValue.customProperties.EmailConfirmado;
    }

    public recuperarSenha(email: string): Observable<ResultadoController> {
        return this.http.post<ResultadoController>(`${environment.apiUrl}auth/recuperarSenha/${email}`, null);
    }

    public resetarSenha(dadosReset: ResetarSenhaDto): Observable<ResultadoController> {
        return this.http.patch<ResultadoController>(`${environment.apiUrl}auth/resetarSenha/`, dadosReset);
    }

    public logout(): void {
        // remove user from local storage to log user out
        sessionStorage.removeItem('currentUser');
        this._currentUserSubject.next(null);
    }

    private setUser(user: User): void {
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this._currentUserSubject.next(user);
    }

    private setAuditoria(user: User): void {
        sessionStorage.setItem('currentUser', JSON.stringify(user));
        this._currentUserSubject.next(user);
    }

    public updateAposAceitarTermoUso(): void {
        const usuario = this.currentUserValue;
        usuario.customProperties.ExibirTermoUso = false;

        this.setUser(usuario);
    }

    public updateAposValidarCadastro(): void {
        const usuario = this.currentUserValue;
        usuario.customProperties.ExibirConfirmacaoCadastro = false;

        this.setUser(usuario);
    }

    public atualizarToken(token: string): void {
        const usuario = this.currentUserValue;
        usuario.token = token;

        this.currentUserValue = usuario;
    }

    public updateAlertaDadosPagamento(alteracao: boolean = true): void {
        const usuario = this.currentUserValue;
        usuario.customProperties.TemDadosPagamento = alteracao;
        usuario.dadosPagamento = alteracao;
        document.getElementById('btn-icone-alerta').style.display = 'none';
        document.getElementById('tool-icone-alerta').style.display = 'none';
        this.setUser(usuario);
    }
}
