import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'politica-seguranca',
    templateUrl: './politica-seguranca.component.html'
})
export class PoliticaSegurancaComponent {
    constructor(public dialogRef: MatDialogRef<PoliticaSegurancaComponent>) {}
}
