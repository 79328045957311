import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { CalendarModule as AngularCalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseConfirmDialogModule } from '@fuse/components';
import { InicioComponent } from './inicio.component';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentesModule } from '../componentes/componentes.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { AppRoutingModule } from 'app/app.routing';
import { ListaSidebarComponent } from '../componentes/lista-sidebar/lista-sidebar.component';
import { ListaAcoesComponent } from '../componentes/lista-acoes/lista-acoes.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { IndicacoesComponent } from 'app/main/inicio/indicacoes-conversoes/indicacoes/indicacoes.component';
import { ConversoesComponent } from 'app/main/inicio/indicacoes-conversoes/conversoes/conversoes.component';
import { AfiliadosComponent } from 'app/main/inicio/afiliados/afiliados.component';
import { DetalheComponent } from 'app/main/inicio/afiliados/detalhe/detalhe.component';

@NgModule({
    declarations: [InicioComponent, IndicacoesComponent, ConversoesComponent, AfiliadosComponent, DetalheComponent],
    imports: [
        TranslateModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatTabsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        ComponentesModule,
        AppRoutingModule,
        ColorPickerModule,
        FuseSharedModule,
        FuseConfirmDialogModule,

        AngularCalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
    ],
    exports: [InicioComponent, ListaSidebarComponent, ListaAcoesComponent, ComponentesModule, FuseSharedModule],
    providers: [CurrencyPipe, DatePipe],
})
export class InicioModule {}
