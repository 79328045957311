<card-com-header-footer class="w-100-p">
    <div class="icone-padrao">attach_money</div>
    <div class="titulo-padrao">{{ 'TITULOS.PAGAMENTOS' | translate }}</div>
    <div class="sub-menu-padrao">
        <div fxLayout="row" fxLayoutAlign="space-between center">
            <data-intervalo
                [requiredFim]="true"
                [requiredInicio]="true"
                [valorInicialDataInicio]="this.filtroListagemPagamento.dataInicio"
                [valorInicialDataFim]="this.filtroListagemPagamento.dataFim"
                (dataInicio)="this.atualizarDataInicio($event)"
                (dataFim)="this.atualizarDataFim($event)"
                fxFlex="49"
                fxFlex.xs="100"
            ></data-intervalo>

            <select-all
                fxFlex="24"
                fxHide.xs
                [sourceList]="this.situacoesFiltro"
                [allSelected]="true"
                [groupName]="'LABELS.SITUACAO' | translate"
                [required]="true"
                (sourceListSelected)="this.onSelectItemSituacao($event)"
            ></select-all>

            <filtro-lista fxHide.xs fxFlex="24" [possuiBotaoPesquisa]="false" (textoInput)="this.setDadosFiltrados($event)"></filtro-lista>
        </div>
    </div>
    <div class="body-card-padrao">
        <lista-colunas-acoes
            fxFlex="100"
            [colunas]="this.colunas"
            [acoes]="this.acoes"
            [dados]="this.listagemPagamentosFiltrado"
            [funcaoAcoesLinha]="this.getAcoesLinha"
            (eventoAcao)="this.alterarPagamento($event)"
        ></lista-colunas-acoes>
    </div>
    <div class="footer-card-padrao">
        <div fxLayout="row">
            <div fxFlex="50">
                <button
                    type="button"
                    (click)="this.exportarParaXLSX()"
                    class="btn-cancelar mr-4"
                    ngClass.gt-xs="mr-8"
                    color="accent"
                    matTooltip="Exportar xlsx"
                    mat-stroked-button
                >
                    <img src="assets/icons/excel-icon.png" class="img-export" alt="Excel" />
                </button>
                <button
                    type="button"
                    (click)="this.exportarParaPdf()"
                    class="btn-cancelar mr-4"
                    ngClass.gt-xs="mr-8"
                    color="accent"
                    matTooltip="Exportar pdf"
                    mat-stroked-button
                >
                    <img src="assets/icons/pdf-icone.png" class="img-export" alt="PDF" />
                </button>
            </div>
        </div>
    </div>
</card-com-header-footer>
