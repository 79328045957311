import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthenticationService, DialogService, TraducaoService } from 'app/_services';

@Injectable({ providedIn: 'root' })
export class PerfilGuard implements CanActivate {
    constructor(private router: Router, private _authenticationService: AuthenticationService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
        if (!route.data || !route.data.perfis) {
            return true;
        }

        const perfilAtual = this._authenticationService.currentUserValue.perfil;
        const podeAcessar = route.data.perfis.includes(perfilAtual);

        if (!podeAcessar) {
            this.router.navigate(['']);
        }

        return podeAcessar;
    }
}
