import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'card-com-header',
    templateUrl: './card-com-header.component.html'
})
export class CardComHeaderComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
