export function converteDataParaTempoString(data: Date): string {
    return (
        data
            .getHours()
            .toString()
            .padStart(2, '0') +
        ':' +
        data
            .getMinutes()
            .toString()
            .padStart(2, '0')
    );
}
