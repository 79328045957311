<mat-form-field appearance="outline" fxFlex="100">
    <mat-label>{{ this.groupName }}</mat-label>
    <mat-select [(ngModel)]="this.selectedFilter" multiple [required]="this.required" [formControl]="this.select">
        <mat-select-trigger>
            {{ this.isItemSelected() ? this.getDescriptionFirstItemSelected() : '' }}
            <span *ngIf="this.itensSelectedCount() > 1" class="text-additional-selection">
                (+{{ this.itensSelectedCount() - 1 }} {{ this.itensSelectedCount() === 2 ? 'outro' : 'outros' }})
            </span>
        </mat-select-trigger>
        <mat-option [value]="0" (click)="this.selectAll(allSelect)" #allSelect>Selecionar Todos</mat-option>

        <mat-optgroup label="{{ this.groupName }}">
            <mat-option (click)="this.selectItem(selectList)" *ngFor="let item of this.sourceList" [value]="item.id" #selectList>
                {{ item.descricao }}
            </mat-option>
        </mat-optgroup>
    </mat-select>
    <mat-error><ng-content *ngIf="this.msgError && this.select.hasError('required')" select=".mat-error"></ng-content></mat-error>
</mat-form-field>
