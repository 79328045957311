import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { FuseProgressBarService } from '@fuse/components/progress-bar/progress-bar.service';
import { ModoSalvar } from 'app/_models';
import { TipoAnexo } from 'app/_models/enums/tipo-anexo';
import { DadosModalAlterarIncluirMaterialApoio } from 'app/_models/material-apoio/dados-modal-alterar-incluir-material-apoio';
import { MaterialApoioDto } from 'app/_models/material-apoio/material-apoio-dto';
import { DialogService, ToastService, TraducaoService } from 'app/_services';
import { MaterialApoioAfiliadosControllerService } from 'app/_services/controllers/material-apoio-afiliados-controller.service';

@Component({
    selector: 'app-alterar-incluir-material-apoio',
    templateUrl: './alterar-incluir-material-apoio.component.html',
    styleUrls: ['./alterar-incluir-material-apoio.component.scss'],
})
export class AlterarIncluirMaterialApoioComponent implements OnInit {
    public modoSalvar: typeof ModoSalvar = ModoSalvar;
    public formFile: FormGroup;
    public form: FormGroup;
    public itemAnexo: MaterialApoioDto;
    public mostrarImg: boolean = false;
    public nomeArquivo: string = '';
    public tipoAnexo: TipoAnexo;
    public tipoFile: typeof TipoAnexo = TipoAnexo;
    public showImg: string | SafeUrl = '';

    constructor(
        public dialogRef: MatDialogRef<AlterarIncluirMaterialApoioComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DadosModalAlterarIncluirMaterialApoio,
        private _toastService: ToastService,
        public fuseProgressBarService: FuseProgressBarService,
        private _formBuilder: FormBuilder,
        private _i18n: TraducaoService,
        private _materialApoioAfiliadosControllerService: MaterialApoioAfiliadosControllerService,
        private _dialogService: DialogService
    ) {}

    ngOnInit() {
        this.itemAnexo = new MaterialApoioDto();
        this.tipoAnexo = TipoAnexo.ANEXO;
        if (this.data.modoSalvar == this.modoSalvar.Put) {
            this.tipoAnexo = this.data.tipoAnexo;
            this.nomeArquivo = this.data.descricao;
            this.mostrarImg = true;
            this.showImg = this.data.caminhoAnexo;
        }
        this.formFile = this._formBuilder.group({
            file: [],
        });

        this.form = this._formBuilder.group({
            descricao: [this.data.descricao, [Validators.required, Validators.maxLength(100)]],
        });
    }

    public anexar(event: Event): void {
        const files = (event.target as HTMLInputElement).files;
        setTimeout(() => {
            if (files) {
                for (const file of Array.from(files)) {
                    if (this.validaArquivoAnexarArquivo(file)) {
                        const arquivo: MaterialApoioDto = {
                            blob: file,
                            descricao: file.name.replace(/\.[^/.]+$/, ''),
                            tipoAnexo: this.tipoAnexo,
                        };
                        this.itemAnexo = arquivo;
                        this.mostrarImg = true;
                        if (this.tipoAnexo == TipoAnexo.IMAGEM) {
                            this.tratarBase64(this.itemAnexo.blob);
                        }
                    }
                }
            }
            this.formFile.reset();
        });
    }

    private tratarBase64(blob: Blob): void {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            this.itemAnexo.base64 = reader.result;
            this.showImg = reader.result;
        };
    }

    public capturarBase64(): void {
        fetch(this.itemAnexo.caminhoAnexo)
            .then((res) => res.blob())
            .then((blob) => {
                this.tratarBase64(blob);
            });
    }

    private validaArquivoAnexarArquivo(file: File): boolean {
        if (file.size <= 20971520) {
            if (
                file.type === 'image/jpeg' ||
                file.type === 'image/png' ||
                file.type === 'image/bmp' ||
                file.type === 'application/pdf' ||
                file.name.toLowerCase().includes('.rar') ||
                file.type === 'application/x-zip-compressed'
            ) {
                this.tipoAnexo =
                    file.name.toLowerCase().includes('.rar') || file.type === 'application/pdf' || file.type === 'application/x-zip-compressed'
                        ? TipoAnexo.ANEXO
                        : TipoAnexo.IMAGEM;
                this.nomeArquivo = file.name;
                return true;
            }
            this.mostrarImg = false;
            this.nomeArquivo = '';
            return false;
        }
        this._toastService.mensagemError('Tamanho do arquivo deve ser menor que 20MB');
    }

    public anexarFile(): void {
        document.getElementById('material-apoio-file').click();
    }

    public excluir(): void {
        this._dialogService.dialogPergunta(
            this._i18n.get('MENSAGENS.CONFIRMAR_EXCLUSAO'),
            this._i18n.get('MENSAGENS.CONFIRMACAO_EXCLUSAO'),
            null,
            () => {
                this.excluirAnexo();
            },
            () => {}
        );
    }

    private get verificarForm(): boolean {
        this.form.markAllAsTouched();
        if (this.itemAnexo.blob == null) {
            this._toastService.mensagemError('Selecione um arquivo');
            return true;
        } else if (!this.form.valid) {
            return true;
        }
        return false;
    }

    public salvar(): void {
        this.itemAnexo.tipoAnexo = this.tipoAnexo;
        if (this.data.modoSalvar == this.modoSalvar.Put) {
            this.alterar();
            return;
        }

        if (this.verificarForm) {
            return;
        }

        this.fuseProgressBarService.show();
        this.itemAnexo.descricao = this.form.get('descricao').value;
        this._materialApoioAfiliadosControllerService.incluir(this.itemAnexo).subscribe(
            (res) => {
                if (res.sucesso) {
                    this.fuseProgressBarService.hide();
                    this.dialogRef.close(true);
                } else {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOGRUPOSYM'));
                console.log(err);
            }
        );
    }

    public alterar(): void {
        this.form.markAllAsTouched();
        if (!this.form.valid) {
            return;
        }
        this.fuseProgressBarService.show();
        this.itemAnexo.descricao = this.form.get('descricao').value;
        this._materialApoioAfiliadosControllerService.alterar(this.itemAnexo, this.data.id).subscribe(
            (res) => {
                if (res.sucesso) {
                    this.fuseProgressBarService.hide();
                    this.dialogRef.close(true);
                } else {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOGRUPOSYM'));
                console.log(err);
            }
        );
    }

    public excluirAnexo(): void {
        this.fuseProgressBarService.show();
        this.itemAnexo.descricao = this.form.get('descricao').value;
        this._materialApoioAfiliadosControllerService.excluir(this.data.id).subscribe(
            (res) => {
                if (res.sucesso) {
                    this.fuseProgressBarService.hide();
                    this.dialogRef.close(true);
                } else {
                    this.fuseProgressBarService.hide();
                    this._toastService.mensagemError(res.mensagemErro);
                }
            },
            (err) => {
                this.fuseProgressBarService.hide();
                this._toastService.mensagemError(this._i18n.get('MENSAGENS.ENTREEMCONTATOCOMOGRUPOSYM'));
                console.log(err);
            }
        );
    }
}
