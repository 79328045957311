import { DialogService } from './dialog.service';
import { DialogInformacaoComponent } from './dialog-informacao.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { DialogPerguntaComponent } from './dialog-pergunta.component';

@NgModule({
    declarations: [DialogInformacaoComponent, DialogPerguntaComponent],
    imports: [CommonModule, TranslateModule, MatButtonModule],
    providers: [DialogService],
    exports: [DialogInformacaoComponent, DialogPerguntaComponent],
    entryComponents: [DialogInformacaoComponent, DialogPerguntaComponent]
})
export class DialogModule {}
