import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentesModule } from 'app/main/componentes/componentes.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { ListaSidebarComponent } from 'app/main/componentes/lista-sidebar/lista-sidebar.component';
import { ListaAcoesComponent } from 'app/main/componentes/lista-acoes/lista-acoes.component';
import { MatTabsModule } from '@angular/material/tabs';
import { CurrencyPipe, CommonModule } from '@angular/common';
import { MccColorPickerModule } from 'material-community-components';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { NgxMaskModule } from 'ngx-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialApoioComponent } from 'app/main/material-apoio/material-apoio.component';
import { AlterarIncluirMaterialApoioComponent } from 'app/main/material-apoio/alterar-incluir-material-apoio/alterar-incluir-material-apoio.component';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        MatButtonModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatTabsModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        FlexLayoutModule,
        MatSlideToggleModule,
        MatToolbarModule,
        MatTooltipModule,
        MatSelectModule,
        FormsModule,
        MatTableModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        ComponentesModule,
        FusePipesModule,
        ColorPickerModule,
        NgxMaskModule.forRoot(),
        MccColorPickerModule.forRoot({
            empty_color: 'transparent',
            used_colors: ['#000000', '#FFF555'],
        }),
    ],
    declarations: [MaterialApoioComponent, AlterarIncluirMaterialApoioComponent],
    exports: [ListaSidebarComponent, ListaAcoesComponent, ComponentesModule],
    entryComponents: [AlterarIncluirMaterialApoioComponent],
    providers: [CurrencyPipe],
})
export class MaterialApoioModule {}
