import { IdDescricaoDto } from '../global/id-descricao-dto';
import { RoleUsuario } from '../_enums/role-usuario';
import { ConversoesPagamentoDto } from './conversoes-pagamento-dto';

export class DadosPagamentoDto {
    contaProfissionalAfiliadoId: number;
    afiliadoNome: string;
    afiliadoCpf: string;
    afiliadoId: number;
    roleUsuario: RoleUsuario;
    totalBonus: number;
    dataPagamento: Date;
    formasPagamento: Array<IdDescricaoDto>;
    formaPagamentoId?: number;
    notaFiscal: string;
    observacao: string;
    conversoes: Array<ConversoesPagamentoDto>;
}
