import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubMenu } from 'app/_models/configuracoes/financeiro/submenus';

@Component({
    selector: 'app-lista-sidebar',
    templateUrl: './lista-sidebar.component.html',
    styleUrls: ['./lista-sidebar.component.scss'],
})
export class ListaSidebarComponent implements OnInit {
    ativo: number;
    @Input() itens: SubMenu[];
    @Input() icon: string;
    @Output()
    // tslint:disable-next-line: typedef
    itemClick = new EventEmitter();

    ngOnInit(): void {
        this.ativo = 1;
    }

    // tslint:disable-next-line: no-any
    onItemClick(item: any): void {
        this.ativo = item.id;
        this.itemClick.emit(item);
    }

    public setAtivo(menu: number): void {
        setTimeout(() => {
            this.ativo = menu;
        });
    }
}
