import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlterarFotoComponent } from './alterar-foto/alterar-foto.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ImageCropperModule } from 'ngx-image-cropper';
import { WebcamModule } from 'ngx-webcam';
import { CapturarFotoComponent } from './capturar-foto/capturar-foto.component';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ListaSidebarComponent } from './lista-sidebar/lista-sidebar.component';
import { NgxMaskModule } from 'ngx-mask';
import { ListaAcoesComponent } from './lista-acoes/lista-acoes.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FotoComponent } from './foto/foto.component';
import { DataIntervaloComponent } from './datas-intervalo/data-intervalo.component';
import { TituloComQuebraComponent } from './titulo-com-quebra/titulo-com-quebra.component';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { ListaColunasAcoesComponent } from './lista-colunas-acoes/lista-colunas-acoes.component';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { CardSimplesComponent } from './card-simples/card-simples.component';
import { CardComHeaderComponent } from './card-com-header/card-com-header.component';
import { CardComHeaderFooterComponent } from './card-com-header-footer/card-com-header-footer.component';
import { FiltroListaComponent } from './filtro-lista/filtro-lista.component';
import { SelectAllComponent } from './select-all/select-all.component';
import { MatSelectModule } from '@angular/material/select';
import { SalvarCancelarFooterComponent } from './salvar-cancelar-footer/salvar-cancelar-footer.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { DiasDaSemanaComponent } from './dias-da-semana/dias-da-semana.component';
import { CardComHeaderFooterParaNavBarComponent } from 'app/main/componentes/card-com-header-footer-para-nav-bar/card-com-header-footer-para-nav-bar.component';
import { TabelaComFiltroIndicadoresComponent } from 'app/main/componentes/tabela-com-filtro-indicadores/tabela-com-filtro-indicadores.component';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        WebcamModule,
        MatDialogModule,
        ImageCropperModule,
        TranslateModule,
        MatTableModule,
        MatSortModule,
        MatFormFieldModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        MatTooltipModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatDatepickerModule,
        ScrollingModule,
        MatCardModule,
        MatMenuModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatSelectModule,
        FusePipesModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [
        FotoComponent,
        AlterarFotoComponent,
        ListaSidebarComponent,
        ListaAcoesComponent,
        TituloComQuebraComponent,
        DataIntervaloComponent,
        DiasDaSemanaComponent,
        ListaColunasAcoesComponent,
        CardSimplesComponent,
        CardComHeaderComponent,
        CardComHeaderFooterComponent,
        FiltroListaComponent,
        SelectAllComponent,
        SalvarCancelarFooterComponent,
        CardComHeaderFooterParaNavBarComponent,
        TabelaComFiltroIndicadoresComponent,
    ],
    declarations: [
        FotoComponent,
        AlterarFotoComponent,
        CapturarFotoComponent,
        ListaSidebarComponent,
        ListaAcoesComponent,
        TituloComQuebraComponent,
        DataIntervaloComponent,
        DiasDaSemanaComponent,
        ListaColunasAcoesComponent,
        CardSimplesComponent,
        CardComHeaderComponent,
        CardComHeaderFooterComponent,
        FiltroListaComponent,
        SelectAllComponent,
        SalvarCancelarFooterComponent,
        CardComHeaderFooterParaNavBarComponent,
        TabelaComFiltroIndicadoresComponent,
    ],
    entryComponents: [AlterarFotoComponent],
})
export class ComponentesModule {}
