import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseControllerService } from './base-controller.service';
import { AuthenticationService } from '..';
import { ResultadoController } from 'app/_models/global/resultado-controller';
import { RdStationTag } from 'app/_models/enums/rd-station-tag.enum';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

@Injectable({
    providedIn: 'root',
})
export class MeuConsultorioControllerService extends BaseControllerService {
    constructor(_http: HttpClient, _authenticationService: AuthenticationService) {
        super(_http, _authenticationService);
    }

    public incluirRegistroRdStation(rdStationTag: RdStationTag, roleUsuarioMeuConsultorio: RoleUsuario): Observable<ResultadoController> {
        return this.post<ResultadoController, null>(`MeuConsultorio/IncluirRegistroRdStation/${rdStationTag}/${roleUsuarioMeuConsultorio}`, null);
    }
}
