import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Component, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { CapturarFotoService } from './capturar-foto.service';
import { Platform } from '@angular/cdk/platform';

@Component({
    selector: 'app-capturar-foto',
    templateUrl: './capturar-foto.component.html',
    styleUrls: ['./capturar-foto.component.css']
})
export class CapturarFotoComponent implements OnInit {
    constructor(private _capturarFotoService: CapturarFotoService, private _platform: Platform) {
        if (this._platform.ANDROID || this._platform.IOS) {
            this.isMobile = true;
        }
    }

    // toggle webcam on/off
    public showWebcam = true;
    public allowCameraSwitch = true;
    public multipleWebcamsAvailable = false;
    public deviceId: string;
    public isMobile: boolean;
    public videoOptions: MediaTrackConstraints = {
        // width: {ideal: 1024},
        // height: {ideal: 576}
    };
    public errors: WebcamInitError[] = [];

    // latest snapshot
    public webcamImage: WebcamImage = null;

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
    shotAgain = true;

    public ngOnInit(): void {
        WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
            this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        });

        this.trigger.next();
    }

    public triggerSnapshot(): void {
        this.shotAgain = !this.shotAgain;
        this.trigger.next();
    }

    public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
        this._capturarFotoService.setErrorsCamera(error);
        this.errors.push(error);
    }

    public showNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;
        this._capturarFotoService.base64ToImageFile(webcamImage.imageAsBase64);
        this._capturarFotoService.setImageBase64(webcamImage.imageAsBase64);
    }

    public cameraWasSwitched(deviceId: string): void {
        this.deviceId = deviceId;
    }

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }
}
