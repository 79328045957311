import { Component, Input, Output, EventEmitter, OnInit, OnChanges } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { FormControl, Validators } from '@angular/forms';
import { IdDescricaoDto } from 'app/_models/global/id-descricao-dto';

@Component({
    selector: 'select-all',
    templateUrl: './select-all.component.html',
    styleUrls: ['./select-all.component.scss'],
})
export class SelectAllComponent implements OnInit, OnChanges {
    public select: FormControl;
    @Input() public sourceList: Array<IdDescricaoDto>;
    @Input() public required: boolean = false;
    @Input() public allSelected: boolean = false;
    @Input() public groupName: string = 'Itens';
    @Input() public msgError: boolean = false;
    @Input() public selectedFilter: Array<number> = [];
    @Output() public sourceListSelected: EventEmitter<Array<IdDescricaoDto>>;

    constructor() {
        this.sourceListSelected = new EventEmitter<Array<IdDescricaoDto>>();
    }

    public ngOnInit(): void {
        this.select = new FormControl('', Validators.required);
    }

    public ngOnChanges(): void {
        if (
            this.sourceList !== undefined &&
            this.sourceList.length > 0 &&
            this.allSelected &&
            (this.selectedFilter.length === 0 || this.selectedFilter.length === this.sourceList.length)
        ) {
            this.selectedFilter = [...this.sourceList.map((item) => item.id), 0];
        }

        if (this.msgError === true) {
            this.selectIsValid();
        }
    }

    public selectAll(event: MatOption): void {
        if (event.selected) {
            this.addItemSelectAllId();
        } else {
            this.selectedFilter = [];
        }

        this.sourceListSelected.emit(this.mappingSelectedSourceList());
    }

    public isItemSelected(): boolean {
        return this.itensSelectedCount() > 0 ? true : false;
    }

    public getDescriptionFirstItemSelected(): string {
        if (this.isItemSelected() && this.sourceList) {
            const firstItemSelectedId = this.selectedFilter[0];

            const result = this.sourceList.filter((item) => {
                return item.id === firstItemSelectedId;
            });

            return result ? result[0].descricao : '';
        }

        return '';
    }

    public selectItem(event: MatOption): void {
        if (event.selected === false) {
            this.removeItemSelectAllId();
        } else {
            if (this.itensSelectedCount() === this.sourceList.length) {
                this.addItemSelectAllId();
            }
        }

        this.sourceListSelected.emit(this.mappingSelectedSourceList());
    }

    public itensSelectedCount(): number {
        return this.selectedFilter.filter((item) => {
            return item !== 0;
        }).length;
    }

    private mappingSelectedSourceList(): Array<IdDescricaoDto> {
        return this.sourceList.filter((item) => {
            return this.selectedFilter.includes(item.id);
        });
    }

    private addItemSelectAllId(): void {
        this.selectedFilter = [...this.sourceList.map((item) => item.id), 0];
    }

    private removeItemSelectAllId(): void {
        this.selectedFilter = this.selectedFilter.filter((item) => {
            return item !== 0;
        });
    }

    public selectIsValid(): void {
        this.select.markAsTouched();
    }
}
