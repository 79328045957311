<div class="page-layout carded fullwidth tabbed inner-scroll">
    <div class="center">
        <div class="my-12" fxLayout="row" fxLayoutAlign="start center">
            <mat-icon>account_circle</mat-icon>
            <h2 class="m-0 ml-12 tituloPagina">{{ 'Meus Dados' | translate }}</h2>
        </div>
        <div class="content-card mb-12">
            <div id="corpo-card-inicio" class="corpo-card-config">
                <div ngClass.gt-xs="mt-28" fxLayout="column">
                    <div
                        ngClass.xs="mt-24 ml-0 container"
                        ngClass.lt-xl="mt-24 ml-28 container"
                        fxLayout="column"
                        fxLayout.xs="column"
                        fxLayoutAlign="start"
                        fxLayoutAlign.xs="center center"
                        fxLayout.gt-md="column"
                        fxFlex="100%"
                    >
                        <div fxLayout="row" fxLayout.xs="column" ngClass.xs.sm="mr-16" ngClass.gt-md="pl-32" fxFlexOffset.sm="5%">
                            <div
                                ngClass.xs.sm="mt-16"
                                ngClass.md="mt-16"
                                ngClass.gt-md="mt-48"
                                fxFlexOffset.sm.md="5%"
                                fxLayout="column"
                                fxLayoutAlign.xs="center center"
                            >
                                <div ngClass.xs.sm="user-info mt-12 thumbnail-wrapper" ngClass.gt-md="user-info mt-24 thumbnail-wrapper">
                                    <img id="img-edit" class="profile-image avatar huge" [src]="this.afiliado?.caminhoFoto" />
                                </div>
                            </div>

                            <div *ngIf="this.afiliado" ngClass.lt-lg="mt-24 mb-64 ml-36 mt-4 words" ngClass.gt-md="mt-76 ml-48 mt-4 words">
                                <span class="words h4 mb-8">
                                    <b>{{ this.afiliado.nome }}</b>
                                </span>

                                <div class="mt-16 words" *ngIf="this.exibirCodigo()">
                                    <span id="meus-dados-codigo-afiliado">
                                        <b>{{ 'LABELS.MEU_CODIGO' | translate }}:</b> {{ this.codigoAfiliado }}
                                    </span>
                                </div>

                                <div class="mt-16 mt-4 words">
                                    <span *ngIf="this.afiliado.cpf">
                                        <b>{{ this.isCnpj() ? ('LABELS.CNPJ' | translate) : ('LABELS.CPF' | translate) }}:</b>
                                        {{ this.afiliado.cpf | mask: this.mascaraCpfCnpj }}</span
                                    >
                                    <span *ngIf="!this.afiliado.cpf"
                                        ><b>{{ 'LABELS.CPF' | translate }}: </b> {{ 'LABELS.NAO_CADASTRADO' | translate }}</span
                                    >
                                </div>

                                <div *ngIf="this.afiliado.telefone" class="mt-4 words">
                                    <span
                                        ><b>{{ 'LABELS.TELEFONE' | translate }}:</b> {{ this.afiliado.telefone | mask: this.mascaraTelefone }}</span
                                    >
                                </div>

                                <div *ngIf="this.afiliado.email" class="mt-4 words">
                                    <span id="meus-dados-email"
                                        ><b>{{ 'LABELS.EMAIL' | translate }}:</b> {{ this.afiliado.email }}</span
                                    >
                                </div>

                                <div *ngIf="this.afiliado.logradouro" class="mt-4 words">
                                    <span
                                        ><b>{{ 'LABELS.ENDERECO' | translate }}: </b>{{ this.afiliado.logradouro }}, {{ this.afiliado.numero }} -
                                        {{ this.afiliado.bairro }},
                                        <span class="capitalize">{{ this.afiliado.descricaoCidade?.toLowerCase() }}</span> -
                                        <span class="capitalize">{{ this.afiliado.descricaoUf.toLowerCase() }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <footer class="p-8" fxLayout.xs="row" fxLayoutAlign="end end">
                <button
                    *ngIf="this.mostrarBtnDadosPagamento"
                    id="meus-dados-btn-dados-pagamento"
                    class="ml-12"
                    color="primary"
                    mat-raised-button
                    (click)="this.modalDadosPagamento()"
                >
                    <img src="assets/icons/alert.png" class="icone-btn-alerta" id="btn-icone-alerta" *ngIf="this.mostrarBtnAlert" />
                    <mat-icon>attach_money</mat-icon>
                    <span fxHide.xs="true">{{ 'LABELS.DADOS_PAGAMENTO' | translate }}</span></button
                >&nbsp;
                <button id="meus-dados-btn-alterar-perfil" class="ml-12" color="primary" mat-raised-button (click)="this.alterarPerfil()">
                    <mat-icon>edit</mat-icon>
                    <span fxHide.xs="true">{{ 'BOTOES.ALTERAR_PERFIL' | translate }}</span></button
                >&nbsp;
                <button id="meus-dados-btn-alterar-senha" class="ml-12" color="primary" mat-raised-button (click)="this.alterarSenha()">
                    <mat-icon>lock</mat-icon>
                    <span fxHide.xs="true">{{ 'BOTOES.ALTERAR_SENHA' | translate }}</span>
                </button>
            </footer>
        </div>
    </div>
</div>
