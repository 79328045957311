export class ConfiguracaoGlobal {
    constructor() {
        this.tipoLogin = 0;
        this.lembrarSenha = false;
        this.cfgLogin = '';
    }

    tipoLogin: any;
    lembrarSenha: boolean;
    cfgLogin: string;
    localAtendimentoSelecionado: any;
    agendaSelecionadaId?: number;
}
