import { Injectable } from '@angular/core';
import { Dictionary } from 'lodash';
import { IndicadoresIndicacoes } from 'app/_models/conta-profissional/indicadores-indicacoes';
import { DadosParaExportacaoBasicoDto } from 'app/_models/exportacao/dados-para-exportacao-basico-dto';
import { ListaColunas } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { SubMenu } from 'app/_models/configuracoes/financeiro/submenus';
import { Indicadores } from 'app/main/componentes/tabela-com-filtro-indicadores/indicadores';
import { TraducaoService } from 'app/_services/traducao.service';
import { IndicacoesConversoesBase } from 'app/_models/conta-profissional/indicacoes-conversoes-base';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { Indicacoes } from 'app/_models/conta-profissional';

@Injectable({
    providedIn: 'root',
})
export abstract class IndicacoesPerfilBaseService {
    protected _indicacoesParaExportar: Array<Indicacoes>;

    constructor(protected _indicadores: IndicadoresIndicacoes, protected _i18n: TraducaoService) {}

    public abstract getColunas(): Array<ListaColunas>;
    public abstract getMensagemInstrucao(): string;
    public abstract exportarParaPdf(itens: Array<Indicacoes>): void;
    public abstract exportarParaExcel(itens: Array<Indicacoes>): void;
    protected abstract getDadosParaExportacaoBasicoDto(): DadosParaExportacaoBasicoDto;
    protected abstract mapearDadosPdf(): Array<Dictionary<string>>;

    public getItens<TClass extends IndicacoesConversoesBase>(indicacoes: Array<TClass>): Array<TClass> {
        return indicacoes;
    }

    public getDadosSubMenu(): Array<SubMenu> {
        const subMenus = new Array<SubMenu>();

        const menuExpirar: SubMenu = {
            descricao: `${this._i18n.get('LABELS.A_EXPIRAR')}: ${this._indicadores.aExpirar}`,
            id: 0,
        };

        const menuPerdidos: SubMenu = {
            descricao: `${this._i18n.get('LABELS.PERDIDOS')}: ${this._indicadores.perdidos}`,
            id: 0,
        };

        const menuEmAtividade: SubMenu = {
            descricao: `${this._i18n.get('LABELS.EM_ATIVIDADE')}: ${this._indicadores.emAtividade}`,
            id: 0,
        };

        const menuCadastrados: SubMenu = {
            descricao: `${this._i18n.get('LABELS.CADASTRADOS')}: ${this._indicadores.cadastrados}`,
            id: 0,
        };

        subMenus.push(menuCadastrados);
        subMenus.push(menuEmAtividade);
        subMenus.push(menuPerdidos);
        subMenus.push(menuExpirar);

        return subMenus;
    }

    public getIndicadores(): Array<Indicadores> {
        const indicadores = new Array<Indicadores>();

        const menuExpirar: Indicadores = {
            descricao: `${this._i18n.get('LABELS.A_EXPIRAR')}`,
            valor: this._indicadores.aExpirar,
            cor: 'orange',
        };

        const menuPerdidos: Indicadores = {
            descricao: `${this._i18n.get('LABELS.PERDIDOS')}`,
            valor: this._indicadores.perdidos,
            cor: 'red',
        };

        const menuEmAtividade: Indicadores = {
            descricao: `${this._i18n.get('LABELS.EM_ATIVIDADE')}`,
            valor: this._indicadores.emAtividade,
            cor: 'green',
        };

        const menuCadastrados: Indicadores = {
            descricao: `${this._i18n.get('LABELS.CADASTRADOS')}`,
            valor: this._indicadores.cadastrados,
            cor: 'gray',
        };

        indicadores.push(menuExpirar);
        indicadores.push(menuEmAtividade);
        indicadores.push(menuPerdidos);
        indicadores.push(menuCadastrados);

        return indicadores;
    }

    protected getColunasBase(): Array<ListaColunas> {
        let colunas = new Array<ListaColunas>();

        colunas = new Array<ListaColunas>(
            {
                descricao: this._i18n.get('LABELS.PROFISSIONAL'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'nomeFoto',
                tipoDado: TipoDado.INNER_HTML,
                tooltipKey: 'nome',
                position: 2,
            },
            {
                descricao: this._i18n.get('LABELS.TELEFONE'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'telefone',
                tipoDado: TipoDado.TELEFONE,
            },
            {
                descricao: this._i18n.get('LABELS.PLANO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'nomePlano',
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.SITUACAO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'situacaoDescricao',
                tipoDado: TipoDado.TEXTO,
            },
            {
                descricao: this._i18n.get('LABELS.DATA_CADASTRO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'dataCadastro',
                tipoDado: TipoDado.DATA,
            },
            {
                descricao: this._i18n.get('LABELS.ULTIMO_ACESSO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'ultimoAcesso',
                tipoDado: TipoDado.DATA,
            }
        );

        return colunas;
    }
}
