import { Injectable } from '@angular/core';
import { BaseControllerService } from './base-controller.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '..';
import { Observable } from 'rxjs';
import { FiltroListarPagamentosDto } from 'app/_models/conta-profissional-afiliado/filtro-listar-pagamentos-dto';
import { ResultadoListarPagamentos } from 'app/_models/conta-profissional-afiliado/resultados/resultado-listar-pagamentos';
import { ResultadoListarAfiliados } from 'app/_models/conta-profissional-afiliado/resultados/resultado-listar-afiliados';
import { ResultadoSelecionarPagamento } from 'app/_models/conta-profissional-afiliado/resultados/resultado-selecionar-pagamento';
import { AlterarDadosPagamentoDto } from 'app/_models/conta-profissional-afiliado/alterar-dados-pagamento-dto';
import { ResultadoController } from 'app/_models/global/resultado-controller';
import { DadosBasicoUsuarioDTO } from 'app/_models/conta-profissional-afiliado/dados-basicos-usuario-dto';
import { ResultadoDadosAfiliado } from 'app/_models/conta-profissional-afiliado/resultados/resultado-dados-afiliado';
import { DadosDetalheAfiliado } from 'app/_models/conta-profissional-afiliado/dados-detalhe-afiliado';

@Injectable({ providedIn: 'root' })
export class ContaProfissionalAfiliadoControllerService extends BaseControllerService {
    constructor(_http: HttpClient, _authenticationService: AuthenticationService) {
        super(_http, _authenticationService);
    }

    public listarPagamentos(filtro: FiltroListarPagamentosDto): Observable<ResultadoListarPagamentos> {
        return this.post<ResultadoListarPagamentos, void>(`ContaProfissionalAfiliado/ListarPagamentos/PorAfiliado/DataPedido`, filtro);
    }

    public selecionarPagamento(contaProfissionalAfiliadoId: number): Observable<ResultadoSelecionarPagamento> {
        return this.get<ResultadoSelecionarPagamento>(
            `ContaProfissionalAfiliado/SelecionarPagamento/PorAfiliado/${contaProfissionalAfiliadoId}/PorDataPedido`
        );
    }

    public alterarPagamento(dados: AlterarDadosPagamentoDto): Observable<ResultadoController> {
        return this.put<ResultadoController, AlterarDadosPagamentoDto>(`ContaProfissionalAfiliado`, dados);
    }

    public listarAfiliados(): Observable<ResultadoListarAfiliados> {
        return this.get<ResultadoListarAfiliados>(`ContaProfissionalAfiliado/ListarAfiliados`);
    }

    public selecionarDadosAfiliado(dadosBasicoUsuarioDTO: DadosBasicoUsuarioDTO): Observable<ResultadoDadosAfiliado> {
        return this.post<ResultadoDadosAfiliado, DadosBasicoUsuarioDTO>(`ContaProfissionalAfiliado/SelecionarDadosAfiliado`, dadosBasicoUsuarioDTO);
    }
}
