import { NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeBr from '@angular/common/locales/pt-PT';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { InicioModule } from 'app/main/inicio/inicio.module';
import { AjudaModule } from 'app/main/ajuda/ajuda.module';
import { AppRoutingModule } from 'app/app.routing';
import { LoginModule } from 'app/authentication/login/login.module';
import { EsqueceuSenhaModule } from 'app/authentication/esqueceu-senha/esqueceu-senha.module';
import { RecuperarSenhaModule } from 'app/authentication/recuperar-senha/recuperar-senha.module';
import { LockModule } from 'app/authentication/lock/lock.module';
import { RegistrarModule } from 'app/authentication/registrar/registrar.module';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxMaskModule } from 'ngx-mask';
import { SocialLoginModule, FacebookLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { JwtInterceptor, ErrorInterceptor } from 'app/_helpers';
import { environment } from 'environments/environment';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AuthenticationService, GlobalConfigurationService, ToastService, DialogService } from 'app/_services';
import { DialogModule } from 'app/_services/dialogs/dialog.module';
import { ComponentesModule } from 'app/main/componentes/componentes.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { InfoBrowserModule } from 'app/authentication/info-browser/info-browser.module';
import { MeusDadosModule } from './main/meus-dados/meus-dados.module';
import { PagamentosModule } from 'app/main/pagamentos/pagamentos.module';
import { MaterialApoioModule } from 'app/main/material-apoio/material-apoio.module';

registerLocaleData(localeBr);

@NgModule({
    declarations: [AppComponent],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        NgxMaskModule.forRoot(),
        SocialLoginModule,
        DialogModule,

        TranslateModule.forRoot(),

        DeviceDetectorModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatCardModule,

        // Toast
        ToastrModule.forRoot(),

        // Máscara
        NgxMaskModule.forRoot(),

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        EditorModule,

        // App modules
        LayoutModule,

        // Autenticação
        LoginModule,
        EsqueceuSenhaModule,
        RecuperarSenhaModule,
        LockModule,
        RegistrarModule,
        InfoBrowserModule,

        ComponentesModule,
        InicioModule,
        AjudaModule,
        MeusDadosModule,
        AppRoutingModule,
        PagamentosModule,
        MaterialApoioModule,
    ],
    providers: [
        AuthenticationService,
        ToastService,
        DialogService,

        GlobalConfigurationService,

        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebook.id),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
