import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from 'app/_constants';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TraducaoService } from 'app/_services/traducao.service';
import { removeErroForm } from 'app/_functions/remove-erro-form';
import { validarDigitoData } from 'app/_functions/validar-digito-data';
import { debounce } from 'app/_functions/debounce';
import { format } from 'date-fns';

@Component({
    selector: 'data-intervalo',
    templateUrl: './data-intervalo.component.html',
    styleUrls: ['./data-intervalo.component.scss'],
    providers: [
        DatePipe,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: MAT_DATE_LOCALE, useValue: 'pt' }
    ]
})
export class DataIntervaloComponent implements OnInit, OnChanges {
    @Input() public labelInicio: string;
    @Input() public labelFim: string;
    @Input() public requiredFim: boolean;
    @Input() public requiredInicio: boolean;
    @Input() public erroDataFimMenor: string;
    @Input() public valorInicialDataInicio: Date;
    @Input() public valorInicialDataFim: Date;
    @Input() public mesmaLinha: boolean = true;
    @Input() public usaDebounce: boolean = true;
    @Input() public validarForm: boolean;
    @Output() public dataInicio: any = new EventEmitter();
    @Output() public dataFim: any = new EventEmitter();
    @Output() public formDataIntervaloValido: EventEmitter<boolean>;

    public form: FormGroup;

    constructor(private _formBuilder: FormBuilder, private _i18n: TraducaoService) {
        this.labelInicio = this.labelInicio ? this.labelInicio : 'Data início';
        this.labelFim = this.labelFim ? this.labelFim : 'Data fim';
        this.erroDataFimMenor = this.erroDataFimMenor ? this.erroDataFimMenor : this._i18n.get('FORM.VALIDACOES.DATA_FIM_MENOR');
        this.formDataIntervaloValido = new EventEmitter();
    }

    ngOnInit(): void {
        this.setFormulario();

        if (this.usaDebounce) {
            debounce(this.form.get('dataInicio') as FormControl, query => this.eventoDataInicio(query));
            debounce(this.form.get('dataFim') as FormControl, query => this.eventoDataFim(query));
        } else {
            this.form.get('dataInicio').valueChanges.subscribe(dataInicioMom => {
                this.eventoDataInicio(dataInicioMom);
            });

            this.form.get('dataFim').valueChanges.subscribe(dataFimMom => {
                this.eventoDataFim(dataFimMom);
            });
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.validarForm) {
            if (this.validarForm === true) {
                this.setValidacao();
            }
        }
    }

    setFormulario(): void {
        const dataInicio = this.getDataInicioFormatada(this.valorInicialDataInicio);
        const dataFim = this.getDataFimFormatada(this.valorInicialDataFim);

        this.form = this._formBuilder.group(
            {
                dataInicio: [dataInicio ? dataInicio : ''],
                dataFim: [dataFim ? dataFim : '']
            },
            {
                validator: (formGroup: FormGroup) => {
                    return this.validarDataFim(formGroup);
                }
            }
        );

        if (this.requiredInicio) {
            this.form.get('dataInicio').setValidators([Validators.required]);
        }

        if (this.requiredFim) {
            this.form.get('dataFim').setValidators([Validators.required]);
        }
    }

    eventoDataInicio(dataInicio: Date): void {
        this.validarDataInicio();
        this.dataInicio.emit(this.getDataInicioFormatada(dataInicio));
    }

    eventoDataFim(dataFim: Date): void {
        this.dataFim.emit(this.getDataFimFormatada(dataFim));
    }

    getDataInicioFormatada(dataInicio: Date): Date {
        return new Date(format(dataInicio, 'YYYY-MM-DD 00:00:00'));
    }

    getDataFimFormatada(dataFim: Date): Date {
        return new Date(format(dataFim, 'YYYY-MM-DD 23:59:59'));
    }

    validarDataFim(formGroup: FormGroup): any {
        const dataInicio = formGroup.get('dataInicio').value;
        const dataFim = formGroup.get('dataFim').value;

        removeErroForm(formGroup.get('dataFim'), 'datafimMenor');

        if (dataFim && dataInicio) {
            if (dataFim < dataInicio) {
                formGroup.get('dataFim').setErrors({ datafimMenor: true });
                return { datafimMenor: true };
            }
        }

        return null;
    }

    public onKeyPressDataInicio(event: KeyboardEvent): void {
        if (!validarDigitoData(event.key)) {
            event.preventDefault();
        }
    }

    public onKeyPressDataFim(event: KeyboardEvent): void {
        if (!validarDigitoData(event.key)) {
            event.preventDefault();
        }
    }

    private setValidacao(): void {
        Object.keys(this.form.controls).forEach(field => {
            const control = this.form.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            }
        });
        this.formDataIntervaloValido.emit(this.form.valid);
    }

    private dataValida(data: string): boolean {
        const pattern = /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/;
        return pattern.test(data);
    }

    private validarDataInicio(): void {
        const dataInicio = this.form.get('dataInicio').value;
        const dataFim = this.form.get('dataFim').value;

        if (dataInicio > dataFim) {
            this.form.get('dataFim').setErrors({ dataFimMenorDataInicio: true });
            this.form.get('dataFim').markAsTouched({ onlySelf: true });
        } else {
            this.form.get('dataFim').setErrors(null);
        }
    }
}
