import { Time } from '@angular/common';

export function converteStringParaTempo(time: string): Time {
    if (!time) {
        return;
    }

    const split: Array<string> = time.split(':');

    return {
        hours: parseInt(split[0], 10),
        minutes: parseInt(split[1], 10)
    };
}
