import { Observable, from } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { reject } from 'q';
import { resolve } from 'url';
import { AuthenticationService } from 'app/_services/authentication.service';

export function normalizarString(textoParaNormalizar: string): string {
    const texto = textoParaNormalizar.split('');
    const acentosRemocao = new Array();
    const tamanhoTextoAcentos = texto.length;
    const paraNormalizar = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
    const normalizado = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

    for (let y = 0; y < tamanhoTextoAcentos; y++) {
        if (paraNormalizar.indexOf(textoParaNormalizar[y]) !== -1) {
            acentosRemocao[y] = normalizado.substr(paraNormalizar.indexOf(textoParaNormalizar[y]), 1);
        } else {
            acentosRemocao[y] = textoParaNormalizar[y];
        }
    }

    let novoTexto = acentosRemocao.join('');
    novoTexto = novoTexto.replace(/[^a-z0-9\s]/gi, '').replace(/[-\s]/g, '_');
    return novoTexto;
}

export function adBlockHabilitado(): Observable<boolean> {
    const testURL = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

    const config: object = {
        method: 'HEAD',
        mode: 'no-cors'
    };

    const requisicao = new Request(testURL, config);
    const newPromise = new Promise<boolean>((res, _) => {
        fetch(requisicao)
            .then(() => {
                res(false);
            })
            .catch(() => {
                res(true);
            });
    });

    return from(newPromise);
}
