<card-com-header-footer-para-nav-bar class="w-100-p">
    <div class="sub-menu-padrao">
        <div fxLayout="row">
            <div fxFlex="49" fxFlex.sm="34" fxFlex.xs="80">
                <filtro-lista [possuiBotaoPesquisa]="false" (textoInput)="this.setIndicacoesFiltradas($event)"></filtro-lista>
            </div>

            <!-- Exibição indicadores para mobile -->
            <app-lista-sidebar fxFlex.xs="20" *ngIf="this.subMenus.length > 0" class="mt-8" fxHide.gt-xs [itens]="this.subMenus" [icon]="'equalizer'">
            </app-lista-sidebar>

            <div fxHide.xs fxLayoutAlign.lt-sm="space-around center" fxFlex="49" fxFlex.sm="64" class="indicadores-container">
                <div fxFlex="90">
                    <div *ngFor="let indicador of this.indicadores">
                        <div class="indicadores-container-item">
                            <div class="indicador-titulo">{{ indicador.descricao }}</div>
                            <span class="indicador-contador" [style.color]="!!indicador.cor ? indicador.cor : 'gray'">{{ indicador.valor }}</span>
                        </div>
                    </div>
                </div>
                <div fxFlex="10">
                    <div class="cursor-pointer" *ngIf="this.exibirBtnInstrucao">
                        <mat-icon class="align-icon ml-48 cursor-pointer" (click)="this.openInformacoesIndicadores()"> help_outline </mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="body-card-padrao">
        <lista-colunas-acoes
            class="h-100-p pr-8"
            [colunas]="this.colunas"
            [acoes]="this.acoes"
            [dados]="this.itensFiltrados"
            [funcaoAcoesLinha]="this.funcaoAcoesLinha"
            (eventoAcao)="this.eventoDeAcao($event)"
            index="id"
            fxFlex="100"
        ></lista-colunas-acoes>
    </div>

    <div class="footer-card-padrao mt-8">
        <div fxLayout="row">
            <div fxFlex="50" *ngIf="this.exibirBtnExportar">
                <button
                    type="button"
                    (click)="this.exportarParaExcel()"
                    class="btn-cancelar mr-4"
                    ngClass.gt-xs="mr-8"
                    color="accent"
                    matTooltip="Exportar xlsx"
                    mat-stroked-button
                >
                    <img src="assets/icons/excel-icon.png" class="img-export" alt="Excel" />
                </button>
                <button
                    type="button"
                    (click)="this.exportarParaPdf()"
                    class="btn-cancelar mr-4"
                    ngClass.gt-xs="mr-8"
                    color="accent"
                    matTooltip="Exportar pdf"
                    mat-stroked-button
                >
                    <img src="assets/icons/pdf-icone.png" class="img-export" alt="PDF" />
                </button>
            </div>

            <div fxFlex="50" class="text-right" *ngIf="this.exibirBtnResgate">
                <button id="btn-solicitar-resgate" (click)="this.solicitarResgate()" color="accent" mat-raised-button>
                    {{ 'BOTOES.SOLICITAR_RESGATE' | translate }}
                </button>
            </div>
        </div>
    </div>
</card-com-header-footer-para-nav-bar>
