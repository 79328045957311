import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { FuseSharedModule } from '@fuse/shared.module';

import { RecuperarSenhaComponent } from 'app/authentication/recuperar-senha/recuperar-senha.component';
import { TranslateModule } from '@ngx-translate/core';

const routes = [
    {
        path: 'recuperar-senha',
        component: RecuperarSenhaComponent
    }
];

@NgModule({
    declarations: [RecuperarSenhaComponent],
    imports: [RouterModule.forChild(routes), TranslateModule, MatButtonModule, MatFormFieldModule, MatIconModule, MatInputModule, FuseSharedModule]
})
export class RecuperarSenhaModule {}
