export enum TipoDado {
    DATA,
    TEXTO,
    MONETARIO,
    TELEFONE,
    INNER_HTML,
    INNER_HTML_CENTRALIZADO,
    SituacaoPagamentoTitulo,
    SituacaoRecebimentoTitulo,
    ValorSaida,
    CPF,
}
