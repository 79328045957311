export class CadastroConta {
    email: string;
    senha: string;
    nome: string;
    telefone: string;
    cpf: string;
    roleId: number;
    caminhoFoto: string;
    tipoCadastro: number;
    contaFacebookId: string;
    contaGoogleId: string;
    codigoAtivacao?: string;
    cep?: string;
    logradouro?: string;
    numero?: number;
    complemento: string;
    bairro: string;
    uf?: number;
    cidade?: number;
}
