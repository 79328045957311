import { NgModule } from '@angular/core';
import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { SafeHtmlPipe } from './safeHtml.pipe';

@NgModule({
    declarations: [KeysPipe, GetByIdPipe, HtmlToPlaintextPipe, FilterPipe, CamelCaseToDashPipe, OrderByPipe, SafeHtmlPipe],
    imports: [],
    exports: [KeysPipe, GetByIdPipe, HtmlToPlaintextPipe, FilterPipe, CamelCaseToDashPipe, OrderByPipe, SafeHtmlPipe]
})
export class FusePipesModule {}
