import { AbstractControl } from '@angular/forms';

// tslint:disable-next-line: no-any
export function cpfEhValido(control: AbstractControl): any {
    const cpf = control.value;

    if (!cpf) {
        return false;
    }

    if (cpf.length !== 11) {
        return { cpfInvalido: true };
    }

    if (
        cpf === '00000000000' ||
        cpf === '55555555555' ||
        cpf === '66666666666' ||
        cpf === '77777777777' ||
        cpf === '88888888888' ||
        cpf === '99999999999'
    ) {
        return { cpfInvalido: true };
    }

    // tslint:disable-next-line: no-any
    let numbers: any;
    // tslint:disable-next-line: no-any
    let digits: any;
    // tslint:disable-next-line: no-any
    let sum: any;
    // tslint:disable-next-line: no-any
    let i: any;
    // tslint:disable-next-line: no-any
    let result: any;

    numbers = cpf.substring(0, 9);
    digits = cpf.substring(9);
    sum = 0;
    for (i = 10; i > 1; i--) {
        sum += numbers.charAt(10 - i) * i;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(0))) {
        return { cpfInvalido: true };
    }
    numbers = cpf.substring(0, 10);
    sum = 0;

    for (i = 11; i > 1; i--) {
        sum += numbers.charAt(11 - i) * i;
    }
    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== Number(digits.charAt(1))) {
        return { cpfInvalido: true };
    }

    return true;
}

export function cpfEhValidoGeral(control: AbstractControl): any{
    const cpf = control.value;

    if (
        cpf === '00000000000' ||
        cpf === '11111111111' ||
        cpf === '22222222222' ||
        cpf === '33333333333' ||
        cpf === '44444444444'        
    ) {
        return { cpfInvalido: true };
    }

    return cpfEhValido(control);

}

// tslint:disable-next-line: no-any
export function cnpjEhValido(control: AbstractControl): any {
    const cnpj = control.value;

    if (!cnpj) {
        return false;
    }

    if (cnpj.length !== 14) {
        return { cnpjInvalido: true };
    }

    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    ) {
        return { cnpjInvalido: true };
    }

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== Number(digitos.charAt(0))) {
        return { cnpjInvalido: true };
    }

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i) * pos--);
        if (pos < 2) {
            pos = 9;
        }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== Number(digitos.charAt(1))) {
        return { cnpjInvalido: true };
    }

    return true;
}
