import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { CaminhoImagem, User } from 'app/_models';
import { IAlterarFotoService } from 'app/_models/alterar-foto/alterar-foto.interface';
import { AuthenticationService } from 'app/_services';
import { ContaColaboradorControllerService } from 'app/_services/controllers/conta-colaborador-controller.service';
import { ContaProfissionalControllerService } from 'app/_services/controllers/conta-profissional-controller.service';
import { PerfilAfiliadoDto } from 'app/_models/alterar-perfil/perfil-afiliado-dto';
import { stringParaEnum } from 'app/_functions/converte-string-para-enum';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

@Injectable({
    providedIn: 'root',
})
export class AlterarPerfilService implements IAlterarFotoService {
    private _alterouPerfil: Subject<PerfilAfiliadoDto> = new Subject<PerfilAfiliadoDto>();
    private _caminhoFoto: Subject<string> = new Subject<string>();

    constructor(
        private _authenticationService: AuthenticationService,
        private _contaProfissionalControllerService: ContaProfissionalControllerService,
        private _contaColaboradorControllerService: ContaColaboradorControllerService
    ) {}

    private get usuario(): User {
        return this._authenticationService.currentUserValue;
    }

    private get roleUsuarioMeuConsultorio(): RoleUsuario {
        if (!!this.usuario) {
            const stringValueEnum = this.usuario.customProperties.RoleMeuConsultorio;
            return stringParaEnum<typeof RoleUsuario, RoleUsuario>(RoleUsuario, stringValueEnum);
        }
    }

    public setFormData(formData: FormData): void {}

    public setImagem(caminhoFoto: string): void {
        this._caminhoFoto.next(caminhoFoto);
    }

    get foto(): Observable<string> {
        return this._caminhoFoto.asObservable();
    }

    public uploadImagem(formData: FormData, profissionalId: number): Observable<CaminhoImagem> {
        if (RoleUsuario.ADMIN === this.roleUsuarioMeuConsultorio) {
            return this._contaProfissionalControllerService.uploadImagem(formData, profissionalId);
        } else {
            return this._contaColaboradorControllerService.uploadImagem(formData, profissionalId);
        }
    }

    public setAlteracoes(afiliado: PerfilAfiliadoDto): void {
        if (!this._authenticationService.currentUserValue.cpf && afiliado.cpf) {
            this._authenticationService.currentUserValue.cpf = afiliado.cpf;
        }
        this._alterouPerfil.next(afiliado);
    }

    get alteracoesAfiliado(): Observable<PerfilAfiliadoDto> {
        return this._alterouPerfil.asObservable();
    }
}
