<div fxFlex="100" fxLayout="row">
    <div fxFlex="100" fxLayout="column" fxLayoutAlign="start" class="container body-card-padrao">
        <div fxFlex="100" fxLayout="row">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="33" fxFlex.lt-md="100" class="mr-15 mat-form-field-no-padding">
                <mat-label>Nome completo</mat-label>
                <input id="nome-completo" readonly matInput type="text" placeholder="Nome completo" [value]="this.informacaoConta.nome" />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="27" fxFlex.lt-md="100" class="mr-40 mat-form-field-no-padding">
                <mat-label>Telefone</mat-label>
                <input
                    id="telefone"
                    matInput
                    type="text"
                    readonly
                    placeholder="Telefone"
                    [value]="this.informacaoConta.telefone ? this.formatarTelefone(this.informacaoConta.telefone) : '--'"
                />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="40" fxFlex.lt-md="100" class="mat-form-field-no-padding">
                <mat-label>CPF</mat-label>
                <input
                    id="cpf"
                    matInput
                    type="text"
                    readonly
                    placeholder="CPF"
                    [value]="this.informacaoConta.cpf ? this.getCpfFormatado(this.informacaoConta.cpf) : '--'"
                />
            </mat-form-field>
        </div>

        <div fxFlex="100" fxLayout="row">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="33" fxFlex.lt-md="100" class="mr-15 mat-form-field-no-padding">
                <mat-label>Email</mat-label>
                <input id="email" readonly matInput type="text" placeholder="Email" [value]="this.informacaoConta.email" />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="18" fxFlex.lt-md="100" class="mr-15 mat-form-field-no-padding">
                <mat-label>CEP</mat-label>
                <input
                    id="cep"
                    matInput
                    type="text"
                    readonly
                    placeholder="CEP"
                    [value]="this.informacaoConta.cep ? this.formatarCep(this.informacaoConta.cep) : '--'"
                />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="27" fxFlex.lt-md="100" class="mr-40 mat-form-field-no-padding">
                <mat-label>Logradouro</mat-label>
                <input id="logradouro" matInput type="text" readonly placeholder="Logradouro" [value]="this.informacaoConta.logradouro" />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="16" fxFlex.lt-md="100" class="mat-form-field-no-padding">
                <mat-label>Nº</mat-label>
                <input id="numero" matInput type="text" readonly placeholder="Número" [value]="this.informacaoConta.numero" />
            </mat-form-field>
        </div>

        <div fxFlex="100" fxLayout="row">
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="33.5" fxFlex.lt-md="100" class="mr-15 mat-form-field-no-padding">
                <mat-label>Bairro</mat-label>
                <input id="nome-completo" readonly matInput type="text" placeholder="Bairro" [value]="this.informacaoConta.bairro" />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="33" fxFlex.lt-md="100" class="mr-15 mat-form-field-no-padding">
                <mat-label>UF</mat-label>
                <input id="uf" matInput type="text" readonly placeholder="Estado" [value]="this.informacaoConta.uf" />
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" fxFlex="33" fxFlex.lt-md="100" class="mat-form-field-no-padding">
                <mat-label>Cidade</mat-label>
                <input id="cidade" matInput type="text" readonly placeholder="Cidade" [value]="this.informacaoConta.cidade" />
            </mat-form-field>
        </div>
    </div>
</div>

<div class="column-table" fxLayout="column">
    <span class="title-afiliado">Indicações / Conversões</span>
    <div fxFlex="100" fxLayout="row" class="conteudo-table">
        <lista-colunas-acoes class="h-100-p pr-8" [colunas]="this.colunas" [dados]="this.itens" index="id" fxFlex="100"></lista-colunas-acoes>
    </div>
</div>
<div class="footer-card-padrao" fxLayout="row" fxFlex="100">
    <div fxLayout="row" fxLayoutAlign="end end" fxFlex="100">
        <div fxFlex="50" fxLayoutAlign="end end">
            <button id="btn-exportar" (click)="this.fechar()" color="accent" mat-raised-button>Fechar</button>
        </div>
    </div>
</div>
