import { ModoSalvar } from 'app/_models';
import { TipoAnexo } from '../enums/tipo-anexo';

export class DadosModalAlterarIncluirMaterialApoio {
    modoSalvar: ModoSalvar;
    id?: number;
    tipoAnexo?: TipoAnexo;
    caminhoAnexo?: string;
    descricao?: string;
}
