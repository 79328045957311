import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { LoginComponent } from 'app/authentication/login/login.component';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from 'environments/environment';
import { NgxMaskModule } from 'ngx-mask';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { SocialLoginModule, FacebookLoginProvider, SocialAuthServiceConfig } from 'angularx-social-login';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PoliticaSegurancaComponent } from 'app/authentication/login/politica-seguranca/politica-seguranca.component';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [LoginComponent, PoliticaSegurancaComponent],
    providers: [
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.facebook.id),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
    ],
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        RouterModule,
        FuseSharedModule,
        MatSnackBarModule,
        TranslateModule,
        MatDialogModule,
        AngularFireAuthModule,
        MatToolbarModule,
        AngularFireModule.initializeApp(environment),
        NgxMaskModule.forRoot(),
        SocialLoginModule,
    ],
    entryComponents: [PoliticaSegurancaComponent],
})
export class LoginModule {}
