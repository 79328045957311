<tabela-com-filtro-indicadores
    *ngIf="!this.carregamentoInicial && !this.mostrarDetalhe"
    [colunas]="this.colunas"
    [acoes]="this.acoes"
    [subMenus]="this.subMenus"
    [itens]="this.itens"
    [indicadores]="this.indicadores"
    [mensagemIntrucao]="this.mensagemInstrucao"
    [exibirBtnInstrucao]="false"
    [funcaoAcoesLinha]="this.getAcoesLinha"
    (exportarPdf)="this.exportarParaPdf($event)"
    (exportarExcel)="this.exportarParaXLSX($event)"
    (eventoAcao)="this.eventoAcao($event)"
>
</tabela-com-filtro-indicadores>

<div *ngIf="this.carregamentoInicial && !this.mostrarDetalhe">
    <div fxLayout="row" fxLayoutAlign="center center" class="mt-40">
        <mat-spinner></mat-spinner>
    </div>
</div>

<app-detalhe-afiliado fxLayout="column" *ngIf="this.mostrarDetalhe" (acaoFechar)="this.acaoFechar()" [dados]="this.dados"></app-detalhe-afiliado>
