<div id="registrar" class="inner-scroll" fxLayout="row" fxLayoutAlign="start">
    <div id="registrar-intro" fxFlex fxHide fxShow.gt-xs>
        <div class="logo" [@animate]="{ value: '*', params: { scale: '0.2' } }">
            <img src="assets/images/logos/afiliados_branca.png" id="logo-meuconsultorio" [attr.alt]="'ALTS.LOGOTIPO' | translate" />
        </div>

        <div class="title" [@animate]="{ value: '*', params: { delay: '50ms', y: '25px' } }">
            {{ 'MENSAGENS.BEMVINDO' | translate }}
        </div>

        <div class="description" [@animate]="{ value: '*', params: { delay: '100ms', y: '25px' } }">
            {{ 'MENSAGENS.ENTRARDADOS' | translate }}
        </div>
    </div>

    <div id="registrar-form-wrapper" fusePerfectScrollbar [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
        <div id="registrar-form">
            <div class="logo" fxHide.gt-xs>
                <img src="assets/images/logos/afiliados_128.png" id="logo-meuconsultorio" [attr.alt]="'ALTS.LOGOTIPO' | translate" />
            </div>
            <div class="title">
                Criar conta
            </div>
            <!-- {{ "MENSAGENS.JACADASTRADO" | translate }} -->
            <div>
                Já tem cadastro?
                <a id="cadastro-voltar-login" class="mt-16" [routerLink]="'/login'">{{ 'MENSAGENS.VOLTARLOGIN' | translate }}</a>
            </div>

            <!-- Formulário -->
            <mat-vertical-stepper #stepper linear>
                <!-- Primeiro -->
                <mat-step [stepControl]="emailForm" label="Step 1" state="email" errorMessage="Email incompleto">
                    <form name="emailForm" [formGroup]="emailForm" novalidate fxLayout="row wrap" autocomplete="off">
                        <ng-template matStepLabel>Email</ng-template>
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>{{ 'LABELS.EMAIL' | translate }}</mat-label>
                            <input
                                id="email"
                                matInput
                                type="email"
                                maxlength="100"
                                placeholder="Informe seu Email"
                                formControlName="email"
                                required
                                pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"
                                (keyup)="validaEmail()"
                            />
                            <mat-icon matSuffix class="secondary-text">email</mat-icon>
                            <mat-error *ngIf="emailForm.get('email').hasError('required')">
                                {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}</mat-error
                            >
                            <mat-error *ngIf="emailForm.get('email').hasError('existente') && !emailForm.get('email').hasError('required')">
                                <strong>Email já cadastrado!</strong> Favor informar um novo email.
                            </mat-error>
                            <mat-error
                                *ngIf="!validarEmail && !emailForm.get('email').hasError('existente') && !emailForm.get('email').hasError('required')"
                                >Informe um email válido</mat-error
                            >
                        </mat-form-field>
                        <div class="separator">
                            <span class="text">{{ 'LABELS.OU' | translate }}</span>
                        </div>
                        <div fxFlex="100" class="container" fxLayout="column" fxLayoutAlign="center center">
                            <button mat-button mat-raised-button class="button google" id="btnGoogleCadastro" (click)="google()">
                                <img src="assets/icons/google.svg" alt="Cadastro com Google" class="iconeButton mb-4" />
                                {{ 'BOTOES.CADASTRARGOOGLE' | translate }}
                            </button>
                            <button mat-button mat-raised-button class="button facebook" id="btnFacebookCadastro" (click)="facebook()">
                                <img src="assets/icons/facebook-app.svg" alt="Cadastro com Facebook" class="iconeButton mb-4" />
                                {{ 'BOTOES.CADASTRARFACEBOOK' | translate }}
                            </button>
                        </div>
                        <div class="mt-12" fxFlex="100" fxLayoutAlign="end" fxLayoutAlign.xs="center center">
                            <button
                                class="button secondary"
                                (click)="emailExistente(stepper); this.setMascaraCpfCnpj('cpf')"
                                mat-raised-button
                                id="btnContinuarCadastro"
                            >
                                {{ 'BOTOES.CONTINUAR' | translate }}
                            </button>
                        </div>
                    </form>
                </mat-step>

                <!-- Segundo D-->
                <mat-step [stepControl]="pessoalForm" label="Step 2" state="pessoal" errorMessage="Dados pessoais incompleto.">
                    <form name="pessoalForm" [formGroup]="pessoalForm" fxLayout="row wrap" fxLayoutAlign="space-between center">
                        <ng-template matStepLabel>{{ 'LABELS.DADOSPESSOAIS' | translate }}</ng-template>
                        <!-- Nome -->
                        <mat-form-field appearance="outline" fxFlex="100">
                            <mat-label>Nome</mat-label>
                            <input id="nome" matInput type="text" placeholder="Informe seu nome" maxlength="100" formControlName="nome" required />
                            <mat-icon matSuffix class="secondary-text">person</mat-icon>
                            <mat-error> {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}</mat-error>
                        </mat-form-field>

                        <!-- Senha -->
                        <mat-form-field appearance="outline" fxFlex="49" fxFlex.lt-md="100" ngClass.gt-sm="mt-12">
                            <mat-label>Senha</mat-label>
                            <input
                                id="senha"
                                matInput
                                [type]="hide ? 'password' : 'text'"
                                placeholder="Informe sua senha"
                                autocomplete="new-password"
                                formControlName="senha"
                                required
                                maxlength="50"
                                minlength="8"
                                (keyup)="validaSenha()"
                                pattern="^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9]{8,}$"
                            />
                            <button
                                mat-icon-button
                                matSuffix
                                (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'"
                                [attr.aria-pressed]="hide"
                                id="btnSenhaCadastro"
                            >
                                <mat-icon>{{ hide ? 'visibility_off' : 'visibility' }}</mat-icon>
                            </button>

                            <mat-error *ngIf="pessoalForm.get('senha').hasError('required')">
                                {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                            </mat-error>

                            <mat-error *ngIf="pessoalForm.get('senha').hasError('minLength') && !pessoalForm.get('senha').hasError('required')">
                                Ter no mínimo 8 caracteres
                            </mat-error>

                            <mat-error *ngIf="pessoalForm.get('senha').hasError('pattern')">Senha inválida</mat-error>

                            <mat-hint>
                                <!-- Validação da senha -->
                                <div fxLayout="row" class="mb-8" fxLayoutAlign="start">
                                    <small
                                        [ngClass]="{
                                            success: !pessoalForm.get('senha').hasError('minlength') && !pessoalForm.get('senha').hasError('required')
                                        }"
                                    >
                                        {{ 'FORM.VALIDACOES.SENHAUM' | translate }}
                                    </small>

                                    <small [ngClass]="{ success: validaLetra && !pessoalForm.get('senha').hasError('required') }">
                                        {{ 'FORM.VALIDACOES.SENHADOIS' | translate }}
                                    </small>

                                    <small [ngClass]="{ success: validaNum && !pessoalForm.get('senha').hasError('required') }">
                                        {{ 'FORM.VALIDACOES.SENHATRES' | translate }}
                                    </small>
                                </div>
                            </mat-hint>
                        </mat-form-field>

                        <!-- Telefone -->
                        <mat-form-field appearance="outline" fxFlex="49" fxFlex.lt-md="100">
                            <mat-label>Telefone</mat-label>
                            <input
                                matInput
                                type="tel"
                                placeholder="Informe seu telefone"
                                formControlName="telefone"
                                required
                                id="telefoneCadastro"
                                [mask]="this.mascaraTelefone"
                            />
                            <mat-icon matSuffix class="secondary-text">local_phone</mat-icon>
                            <mat-error> {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}</mat-error>
                        </mat-form-field>

                        <!-- Radio CPF/CNPJ -->
                        <mat-radio-group fxFlex="100" formControlName="radioCpfCnpj">
                            <mat-radio-button value="cpf" id="check-cpf" class="pr-16">
                                {{ 'LABELS.PESSOA_FISICA' | translate }}
                            </mat-radio-button>
                            <mat-radio-button value="cnpj" id="check-cnpj">
                                {{ 'LABELS.PESSOA_JURIDICA' | translate }}
                            </mat-radio-button>
                        </mat-radio-group>

                        <!-- CPF/CNPJ -->
                        <mat-form-field appearance="outline" fxFlex="49" fxFlex.lt-md="100" ngClass.gt-sm="mt-12">
                            <mat-label>{{ (isPessoaJuridica() ? 'LABELS.CNPJ' : 'LABELS.CPF') | translate }}</mat-label>
                            <input
                                id="cpf-cnpj-cadastro"
                                (focusin)="this.limparValidacaoCpfCnpj()"
                                (focusout)="this.setValidacaoCpfCnpj()"
                                matInput
                                [mask]="this.mascaraCpfCnpj"
                                formControlName="cpfcnpj"
                                required
                            />
                            <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                            <mat-error *ngIf="pessoalForm.get('cpfcnpj').hasError('required')">
                                {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                            </mat-error>
                            <mat-error
                                id="cpf-cnpj-cadastro-erro-obrigatorio"
                                *ngIf="pessoalForm.get('cpfcnpj').hasError('cpfInvalido') || pessoalForm.get('cpfcnpj').hasError('cnpjInvalido')"
                            >
                                {{ (isPessoaJuridica() ? 'FORM.VALIDACOES.CNPJ_INVALIDO' : 'FORM.VALIDACOES.CPF_INVALIDO') | translate }}
                            </mat-error>
                        </mat-form-field>

                        <!-- Endereço -->
                        <div fxFlex="100" fxLayout="row wrap" fxLayoutAlign="space-between center">
                            <div fxLayout="row" fxFlex.xs="100" fxFlex.sm="100" fxFlex.gt-sm="30">
                                <mat-form-field appearance="outline" fxFlex="85">
                                    <mat-label>{{ 'LABELS.CEP' | translate }}</mat-label>
                                    <input id="cep-cadastro" matInput formControlName="cep" type="text" mask="00.000-000" required />
                                    <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                                    <mat-error *ngIf="pessoalForm.get('cep').hasError('required')">
                                        {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                                    </mat-error>
                                </mat-form-field>

                                <div class="mr-8 mt-12 ml-4" fxFlex="auto">
                                    <button
                                        id="btn-buscar-cep"
                                        type="button"
                                        (click)="this.carregarEndereco()"
                                        mat-mini-fab
                                        color="primary"
                                        class="btn-interno"
                                    >
                                        <mat-icon>search</mat-icon>
                                    </button>
                                </div>
                            </div>

                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="69" fxFlex.gt-sm="48">
                                <mat-label>{{ 'LABELS.LOGRADOURO' | translate }}</mat-label>
                                <input id="logradouro-cadastro" maxlength="100" matInput formControlName="logradouro" required />
                                <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                                <mat-error *ngIf="pessoalForm.get('logradouro').hasError('required')">
                                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                                </mat-error>
                            </mat-form-field>

                            <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.sm="29" fxFlex.gt-sm="18">
                                <mat-label>{{ 'LABELS.NUMERO' | translate }}</mat-label>
                                <input id="numero-cadastro" matInput formControlName="numero" mask="999999999" required />
                                <mat-error *ngIf="pessoalForm.get('numero').hasError('required')">
                                    {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                            <mat-label>{{ 'LABELS.COMPLEMENTO' | translate }}</mat-label>
                            <input id="complemento-cadastro" maxlength="100" matInput formControlName="complemento" />
                            <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                        </mat-form-field>

                        <mat-form-field appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                            <mat-label>{{ 'LABELS.BAIRRO' | translate }}</mat-label>
                            <input id="bairro-cadastro" maxlength="100" matInput formControlName="bairro" required />
                            <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                            <mat-error *ngIf="pessoalForm.get('bairro').hasError('required')">
                                {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field id="uf-cadastro" appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                            <mat-label>{{ 'LABELS.UF' | translate }}</mat-label>
                            <mat-select formControlName="uf" (selectionChange)="this.listarCidadesPorUf($event.value)" required>
                                <mat-option *ngFor="let estado of this.estados" [value]="estado.id">{{ estado.uf }}</mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                        </mat-form-field>

                        <mat-form-field id="cidade-cadastro" appearance="outline" fxFlex.xs="100" fxFlex.gt-xs="49">
                            <mat-label>{{ 'LABELS.CIDADE' | translate }}</mat-label>
                            <mat-select formControlName="cidade" required>
                                <mat-option *ngFor="let cidade of this.cidades" [value]="cidade.id">{{ cidade.descricao }}</mat-option>
                            </mat-select>
                            <mat-icon matSuffix class="secondary-text">location_city</mat-icon>
                            <mat-error *ngIf="pessoalForm.get('cidade').hasError('required')">
                                {{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </form>

                    <!-- Botões -->
                    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="end">
                        <button
                            id="btn-anterior-cadastro"
                            class="button secondary mr-12 mr-md-12"
                            mat-raised-button
                            (click)="this.anterior(stepper)"
                            [disabled]="this.fuseProgressBarService.visibleValue"
                        >
                            {{ 'BOTOES.ANTERIOR' | translate }}
                        </button>
                        <button
                            id="btn-proximo-cadastro"
                            class="button secondary mt-md-0"
                            ngClass.xs="mt-12"
                            mat-raised-button
                            (click)="this.realizarCadastro()"
                            [disabled]="this.fuseProgressBarService.visibleValue"
                        >
                            {{ 'BOTOES.CADASTRAR' | translate }}
                        </button>
                    </div>
                </mat-step>

                <!-- Ícones -->
                <ng-template matStepperIcon="email">
                    <mat-icon>email</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="pessoal">
                    <mat-icon>featured_play_list</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="profissional">
                    <mat-icon>account_circle</mat-icon>
                </ng-template>
                <ng-template matStepperIcon="done">
                    <mat-icon>done</mat-icon>
                </ng-template>
            </mat-vertical-stepper>
            <!-- Fim formulário -->
        </div>
    </div>
</div>
