import { SafeUrl } from '@angular/platform-browser';
import { TipoAnexo } from '../enums/tipo-anexo';

export class MaterialApoioDto {
    descricao: string;
    url?: SafeUrl;
    editar?: boolean;
    base64?: SafeUrl;
    blob?: File | Blob;
    caminhoAnexo?: string;
    tipoAnexo?: TipoAnexo;
}
