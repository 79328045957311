import { TraducaoService } from 'app/_services';
import { ListaColunas } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { ColunaExportacao } from 'app/_models/exportacao/coluna-exportacao';
import { DadosParaExportacaoBasicoDto } from 'app/_models/exportacao/dados-para-exportacao-basico-dto';
import { Dictionary } from 'lodash';
import { IndicadoresConversoes } from 'app/_models/conta-profissional/indicadores-conversoes';
import { Conversoes } from 'app/_models/conta-profissional/conversoes';
import { ConversoesPerfilBaseService } from 'app/main/inicio/indicacoes-conversoes/conversoes/conversoes-perfil-base.service';
import { CurrencyPipe, formatCurrency, DatePipe } from '@angular/common';
import { ExportarPdfService } from 'app/_services/exportacao/exportar-pdf.service';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { ExportaXLSXService } from 'app/_services/exportacao/exporta-xlsx.service';

export class ConversoesAfiliadosService extends ConversoesPerfilBaseService {
    constructor(
        protected _indicadores: IndicadoresConversoes,
        protected _conversoes: Array<Conversoes>,
        protected _i18n: TraducaoService,
        protected _currencyPipe: CurrencyPipe,
        private _exportarPdfService: ExportarPdfService,
        private _exportaXLSXService: ExportaXLSXService,
        private datePipe: DatePipe
    ) {
        super(_indicadores, _i18n, _currencyPipe);
    }

    public getColunas(): Array<ListaColunas> {
        const colunasBase = this.getColunasBase();

        const colunasAfiliado = new Array<ListaColunas>({
            descricao: this._i18n.get('LABELS.EMAIL'),
            visibilidade: TamanhosFlexLayout.md,
            key: 'email',
            position: 3,
            maxLengthKey: 30,
            tipoDado: TipoDado.TEXTO,
        });

        return colunasAfiliado.concat(colunasBase);
    }

    public getMensagemInstrucao(): string {
        return this._i18n.get('MENSAGENS.INSTRUCAO_CONVERCOES_AFILIADO');
    }

    public exportarParaPdf(conversoes: Array<Conversoes>): void {
        this._conversoesParaExportar = conversoes;
        const dadosExportacao = this.getDadosParaExportacaoBasicoDto();
        this._exportarPdfService.gerarPdfAfiliados(dadosExportacao);
    }

    public formatarData(data: Date | string): string {
        return this.datePipe.transform(data, 'dd/MM/yyyy');
    }

    public exportarParaExcel(itens: Array<Conversoes>): void {
        const retorno = new Array<Dictionary<string>>();
        itens.forEach((conversao) => {
            const dict = {
                'Nome': conversao.nome,
                'Data compra': this.formatarData(conversao.dataCompra),
                'E-mail': conversao.email,
                'Telefone': !!conversao.telefone ? conversao.telefone.toString() : '',
                'Valor Plano': conversao.valorPlano as string,
                'Valor Bônus': conversao.valorBonus as string,
                'Liberação bonificação': conversao.situacaoBonusDescricao as string,
            };
            retorno.push(dict);
        });

        this._exportaXLSXService.exportAsExcelFile(retorno, 'conversoes');
    }

    protected getDadosParaExportacaoBasicoDto(): DadosParaExportacaoBasicoDto {
        const colunasParaExportacao = [
            new ColunaExportacao('nome', 'Profissional', 1, 100),
            new ColunaExportacao('dataCompra', 'Data compra', 2, 60),
            new ColunaExportacao('email', 'Email', 3, 100),
            new ColunaExportacao('telefone', 'Telefone', 4, 60),
            new ColunaExportacao('valorPlano', 'Valor Plano', 5, 50),
            new ColunaExportacao('valorBonus', 'Valor Bônus', 6, 50),
            new ColunaExportacao('situacaoDescricao', 'Liberação bonificação', 7, 70),
        ];

        const dadosParaExportacaoBasicoDto = new DadosParaExportacaoBasicoDto(
            colunasParaExportacao,
            this.mapearDadosPdf(),
            this._i18n.get('LABELS.MINHAS_CONVERSOES')
        );

        return dadosParaExportacaoBasicoDto;
    }

    protected mapearDadosPdf(): Array<Dictionary<string>> {
        const retorno = new Array<Dictionary<string>>();

        this._conversoesParaExportar.forEach((conversao) => {
            const dict = {
                nome: conversao.nome,
                dataCompra: this.formatarData(conversao.dataCompra),
                email: conversao.email,
                telefone: !!conversao.telefone ? conversao.telefone.toString() : '',
                valorPlano: formatCurrency(conversao.valorPlano as number, 'pt-BR', 'R$'),
                valorBonus: formatCurrency(conversao.valorBonus as number, 'pt-BR', 'R$'),
                situacaoDescricao: conversao.situacaoBonusDescricao,
            };

            retorno.push(dict);
        });

        return retorno;
    }
}
