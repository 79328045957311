<div class="sidebar-content pt-0" fxHide fxShow.gt-xs>
    <div class="card">
        <div class="content py-16" fusePerfectScrollbar>
            <div id="configuracoes-menu-config" class="nav material2">
                <div class="nav-item" aria-label="inbox" *ngFor="let item of itens">
                    <a
                        [id]="item.descricao.toLowerCase() + '-sidebar'"
                        class="nav-link"
                        matRipple
                        [ngClass]="{ 'active ativo': ativo === item.id }"
                        (click)="onItemClick(item)"
                    >
                        <mat-icon class="sidebar-icone-menu" *ngIf="item.icon">{{ item.icon }}</mat-icon>
                        <span> {{ item.descricao }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div fxHide.gt-xs fxFlexAlign="end" class="pt-4">
    <button mat-button [matMenuTriggerFor]="menu">
        <mat-icon>{{ !!this.icon ? this.icon : 'more_vert' }}</mat-icon>
    </button>

    <mat-menu #menu="matMenu">
        <div class="nav-item" aria-label="inbox" *ngFor="let item of itens">
            <button [id]="item.descricao.toLowerCase() + '-sidebar'" mat-menu-item (click)="onItemClick(item)">
                {{ item.descricao }}
            </button>
        </div>
    </mat-menu>
</div>
