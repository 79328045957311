import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from 'app/_services';
import { environment } from 'environments/environment';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    constructor(public _authenticationService: AuthenticationService) {}

    // tslint:disable-next-line: no-any
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this._authenticationService.currentUserValue;

        if (request.url.includes(environment.apiUrl)) {
            if (currentUser && currentUser.token) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${currentUser.token}`,
                        user_info: btoa(currentUser.idUsuario.toString())
                    }
                });
            }
        }

        return next.handle(request);
    }
}
