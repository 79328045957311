<div *ngIf="!(errors.length > 0)" style="text-align:center">
    <div fxFlexLayout="column">
        <webcam
            [height]="200"
            [width]="200"
            [trigger]="triggerObservable"
            (imageCapture)="handleImage($event)"
            *ngIf="shotAgain"
            [allowCameraSwitch]="allowCameraSwitch"
            [switchCamera]="nextWebcamObservable"
            [videoOptions]="videoOptions"
            [imageQuality]="1"
            (cameraSwitched)="cameraWasSwitched($event)"
            (initError)="handleInitError($event)"
        ></webcam>

        <div class="snapshot" *ngIf="!shotAgain">
            <img [src]="webcamImage.imageAsDataUrl" />
        </div>
    </div>

    <button [ngClass]="isMobile ? 'mt-8 mb-8' : 'mt-8'" id="capturarFoto" mat-mini-fab (click)="triggerSnapshot()">
        <mat-icon>camera_alt</mat-icon>
    </button>
</div>

<div *ngIf="errors.length > 0" style="text-align:center">
    <span> <b>Câmera não encontrada!</b></span>
    <br />
    <br />
    <br />
    <br />
    <br />
</div>
