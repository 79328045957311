export const MODAL_WIDTH_XS = '15%';
export const MODAL_WIDTH_SM = '30%';
export const MODAL_WIDTH_MD = '50%';
export const MODAL_WIDTH_LG = '70%';
export const MODAL_WIDTH_XL = '90%';

export const MODAL_HEIGHT_XS = '15%';
export const MODAL_HEIGHT_SM = '30%';
export const MODAL_HEIGHT_MD = '50%';
export const MODAL_HEIGHT_LG = '70%';
export const MODAL_HEIGHT_XL = '95%';
