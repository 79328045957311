<h1 mat-dialog-title>Política de segurança e privacidade WMI.</h1>
<mat-dialog-content>
    <div>
        <p>
            Sabemos que é importante para você conhecer nossa política de privacidade e segurança. Nós da WMI mantemos uma relação de confiança
            com nossos usuários e clientes. Portanto, este documento foi criado para garantir esta transparência, e através dele descrevemos quais
            informações coletamos e porque coletamos, quais informações compartilhamos e como as utilizamos nos sistemas em nuvem da WMI, além
            de como garantimos a segurança das suas informações e de todos os envolvidos. <br /><br />

            Entendemos que a transparência é importante na construção de uma relação de confiança entre empresa e clientes e, por isso, o acesso a
            este documento é público. Esperamos que todos os clientes e usuários dos nossos sistemas reservem um tempo para leitura deste documento.
        </p>
        <br /><br />

        <h4>Segurança das informações</h4>
        <ul>
            <li>
                <p>
                    <b>Nós nos preocupamos com sua privacidade, portanto não utilizamos cookies:</b> Cookies são pequenos arquivos que armazenam
                    preferências de usuário e outras informações. Devido aos cookies ficarem armazenados no computador do usuário, consideramos que o
                    acesso indevido a cookies com informações de nossos usuários poderia causar vulnerabilidade ao sistema.
                </p>
            </li>
            <li>
                <p>
                    <b>Requisições criptografadas e certificadas via HTTPS:</b> Durante o uso do sistema informações são trocadas entre usuário e
                    servidor. O protocolo HTTPS garante que as informações que transitam na rede sejam criptografadas e que não serão visualizadas por
                    terceiros.
                </p>
            </li>
            <li>
                <p>
                    <b>Criptografia irreversível WMI:</b> Informações importantes como senhas e links de acesso são criptografadas de forma que
                    não conseguiremos reverter aos valores descriptografados. Com isso esperamos que os usuários entendam que em alguns casos não será
                    possível consultar e/ou revelar informações.
                </p>
            </li>
            <li>
                <p>
                    <b>Servidor de aplicação:</b> Nós utilizamos serviços em nuvem da AWS que é subsidiária da Amazon.com,Inc. e portanto está sujeita
                    à
                    <a target="_blank" href="https://www.amazon.com/gp/help/customer/display.html/ref=footer_privacy?nodeId=468496"
                        >Notificação de Privacidade da Amazon.com</a
                    >, bem como a disponibilidade dos serviços de hospedagem. A Amazon.com,Inc. é uma multinacional e refêrencia em prestação de
                    serviços de nuvem.
                </p>
            </li>
            <li>
                <p>
                    <b>Acesso à informação:</b> Restringimos o acesso a informações pessoais a funcionários contratados e que precisam processá-la,
                    estes encontram-se sob rigorosas obrigações contratuais de confidencialidade, podendo ser processados ou dispensados se deixarem
                    de cumprir tais obrigações.
                </p>
            </li>
        </ul>
        <br />
        <p>
            Em acordo ao que foi descrito acima, a WMI não possui acesso a senha que é única e exclusiva do usuário, portanto é de
            responsabilidade do usuário manter a segurança desta senha. Algumas dicas são:
        </p>
        <ul>
            <li>
                <p>
                    Não armazenar de forma escrita em locais que possam ser acessados por terceiros.
                </p>
            </li>
            <li>
                <p>
                    Encerrar a sessão clicando no botão "Sair" ao se ausentar do sistema.
                </p>
            </li>
            <li>
                <p>
                    Não informar a terceiros ou permitir que os mesmos vejam sua senha.
                </p>
            </li>
            <li>
                <p>
                    Seguir recomendações de segurança do provedor de email onde possui conta.
                </p>
            </li>
        </ul>
        <br /><br />

        <h4>Informações que coletamos e por que</h4>
        <ul>
            <li>
                <p>
                    <b>Informações transmitidas pelo usuário</b>
                </p>
                <ul>
                    <li>
                        <p>
                            Quando um usuário realiza um cadastro em nosso sistema, recebemos informações pessoais como nome, cpf, email, que são
                            obrigatórias, e outras como telefone, sexo, e foto que são opcionais e registradas junto à conta do usuário.
                        </p>
                    </li>
                </ul>
            </li>
        </ul>
        <br />
        <p>
            Estas informações são utilizadas para fins cadastrais, diferenciam um usuário de outro e nos orientam sobre como destinar os conteúdos dos
            sistemas para os diferentes públicos atendidos.
        </p>
        <br />
        <ul>
            <li>
                <p>
                    <b>Informações obtidas através do uso</b>
                </p>
                <ul>
                    <li>
                        <p>
                            Quando um usuário utiliza nosso sistema, coletamos informações como IP, versão do navegador, a ação que está sendo
                            realizada e o usuário que está realizando.
                        </p>
                    </li>
                </ul>
            </li>
        </ul>
        <br />
        <p>
            Estas informações são utilizadas para segurança do usuário, do sistema e para estudos de utilização do sistema.
        </p>
        <br /><br />

        <h4>Como usamos as informações que coletamos</h4>
        <ul>
            <li>
                <p>
                    As informações de utilização do sistema poderão nos auxiliar em melhorias no produto, através da análise de tendências, tipos de
                    dados, navegadores, dispositivos e localização.
                </p>
            </li>
            <li>
                <p>
                    As ações realizadas no sistema são registradas com a finalidade de auditoria e podem ser consultadas em caso de necessidade de
                    comprovação ou análise de liberação ou bloqueio de usuário.
                </p>
            </li>
            <li>
                <p>
                    Informações pessoais como email e nome podem ser utilizadas em mensagens personalizadas para o usuário e sua equipe, formada
                    através de funcionalidades do sistema.
                </p>
            </li>
        </ul>
        <br /><br />

        <h4>Informações que compartilhamos</h4>
        <ul>
            <li>
                <p>
                    Compartilharemos informações pessoais com empresas, organizações ou indivíduos externos a WMI se acreditarmos, de boa-fé,
                    que o acesso, uso, conservação ou divulgação das informações seja razoavelmente necessário para:
                </p>
                <ul>
                    <li>
                        <p>
                            Cumprir qualquer legislação, regulação, processo formal ou solicitação governamental aplicável.
                        </p>
                    </li>
                    <li>
                        <p>
                            Investigação de possíveis violações.
                        </p>
                    </li>
                    <li>
                        <p>
                            Detectar, impedir ou abordar de alguma outra forma fraude, questões técnicas ou de segurança.
                        </p>
                    </li>
                    <li>
                        <p>
                            Proteger contra dano aos direitos, a propriedade ou a segurança da WMI, nossos usuários ou clientes, conforme
                            solicitado ou permitido por lei.
                        </p>
                    </li>
                </ul>
            </li>
        </ul>
        <br /><br />

        <h4>Aplicabilidade</h4>
        <p>
            Esta política de privacidade e segurança é aplicável ao Amplum e a outros sistemas da plataforma Web da WMI. Os demais
            produtos possuem políticas explicitadas em contrato.
        </p>
        <br /><br />
    </div>
</mat-dialog-content>

<mat-dialog-actions class="container" fxLayout="row" fxLayoutAlign="end" fxLayoutAlign.xs="center center">
    <button mat-raised-button [mat-dialog-close]="true" color="accent">OK, ENTENDI</button>
</mat-dialog-actions>
