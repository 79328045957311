import { Injectable } from '@angular/core';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Dictionary } from 'lodash';
import { TraducaoService } from '../traducao.service';
import { adBlockHabilitado } from 'app/_helpers';
import { DialogService } from '../dialogs';
import { DadosParaExportacaoBasicoDto } from 'app/_models/exportacao/dados-para-exportacao-basico-dto';
import { DadosParaExportacaoDuasTabelasDto } from 'app/_models/exportacao/dados-para-exportacao-duas-tabelas-dto';
import { getAlturaFooter, montarFooterPDFLocalAtendimento } from 'app/_functions/footer-header-impresso-pdf';

@Injectable({
    providedIn: 'root',
})
export class ExportarPdfService {
    public colunasWidth: Array<number>;
    public coluna1Width: Array<number>;
    public coluna2Width: Array<number>;
    public pdfmake: pdfMake;
    // tslint:disable-next-line: no-any
    public documentDefinition: any;

    constructor(private _i18n: TraducaoService, private _dialogService: DialogService) {}

    public gerarPdfAfiliados(dados: DadosParaExportacaoBasicoDto): void {
        this.verificaAdBlock(() => {
            this.colunasWidth = dados.colunas.map((c) => c.widthCelula);
            this.documentDefinition = {
                header: [
                    {
                        columns: [
                            {
                                text: 'Painel Afiliados',
                                alignment: 'center',
                                fontSize: 16,
                                margin: [0, 20, 0, 0],
                            },
                        ],
                    },
                    {
                        canvas: [{ type: 'rect', x: 20, y: 0, w: 555, h: 0 }],
                    },
                ],
                content: [
                    {
                        text: '',
                        alignment: 'right',
                        fontSize: 8,
                        margin: [0, 2, 0, 0],
                    },

                    {
                        text: dados.titulo,
                        alignment: 'center',
                        fontSize: 12,
                        margin: [0, 8, 0, 8],
                    },
                    {
                        style: 'table',
                        table: {
                            headerRows: 1,
                            widths: this.colunasWidth,
                            body: [dados.colunas],
                        },
                    },
                ],
                footer: montarFooterPDFLocalAtendimento(this._i18n, [20, 20, 20, 0]),
                styles: {
                    table: {},
                    header: {
                        alignment: 'center',
                        fontSize: 10,
                        bold: true,
                    },
                    row: { alignment: 'center', fontSize: 9 },
                },
                pageMargins: [20, 40, 20, getAlturaFooter() + 30],
            };
            this.getValoresTabela(dados);
            pdfMake.createPdf(this.documentDefinition).open();
        });
    }

    private verificaAdBlock(acao: CallableFunction): void {
        adBlockHabilitado().subscribe((habilitado) => {
            if (habilitado) {
                this._dialogService.dialogInformacao(this._i18n.get('GLOBAL.ATENCAO'), this._i18n.get('GLOBAL.MENSAGENS.ADBLOCK_ATIVADO_GERAR_PDF'));
            } else {
                acao();
            }
        });
    }

    public getValoresTabela(dados: DadosParaExportacaoBasicoDto): void {
        if (dados.dadosTabela.length > 0) {
            for (let i = 0; i < dados.dadosTabela.length; i++) {
                const dadosPdf = dados.dadosTabela[i];
                this.documentDefinition.content[2].table.body[1 + i] = this.preencherDadosTabela(i, dados, dadosPdf);
            }
        } else {
            this.documentDefinition.content[2].table.body[1] = [
                {
                    colSpan: dados.colunas.length,
                    text: this._i18n.get('MENSAGENS.SEMINFORMACAO'),
                    alignment: 'center',
                    margin: [0, 4, 0, 4],
                },
            ];
        }
    }

    private preencherDadosTabela(i: number, dados: DadosParaExportacaoBasicoDto, dadosPdf: Dictionary<string>): Array<object> {
        const retorno = new Array<object>();

        dados.colunas.forEach((coluna) => {
            retorno.push({ text: dadosPdf[coluna.id], style: 'row' });
        });

        return retorno;
    }

    public getValoresTabela1(dados: DadosParaExportacaoDuasTabelasDto): void {
        if (dados.dadosTabela1.length > 0) {
            for (let i = 0; i < dados.dadosTabela1.length; i++) {
                const dadosPdf = dados.dadosTabela1[i];
                this.documentDefinition.content[3].table.body[1 + i] = this.preencherDadosTabela1(i, dados, dadosPdf);
            }
            this.documentDefinition.content[3].table.body[dados.dadosTabela1.length + 1] = [
                {
                    colSpan: dados.coluna1.length,
                    text: this._i18n.get('FINANCEIRO.CAIXA.TABELA.TOTAL') + ': ' + dados.totalTabela1.toString(),
                    alignment: 'right',
                },
            ];
        } else {
            this.documentDefinition.content[3].table.body[1] = [
                {
                    colSpan: dados.coluna1.length,
                    text: this._i18n.get('MENSAGENS.SEMINFORMACAO'),
                    alignment: 'center',
                    margin: [0, 4, 0, 4],
                },
            ];
        }
    }

    private preencherDadosTabela1(i: number, dados: DadosParaExportacaoDuasTabelasDto, dadosPdf: Dictionary<string>): Array<object> {
        const retorno = new Array<object>();

        dados.coluna1.forEach((coluna) => {
            retorno.push({ text: dadosPdf[coluna.id], style: 'row' });
        });

        return retorno;
    }

    public getValoresTabela2(dados: DadosParaExportacaoDuasTabelasDto): void {
        if (dados.dadosTabela2.length > 0) {
            for (let i = 0; i < dados.dadosTabela2.length; i++) {
                const dadosPdf = dados.dadosTabela2[i];
                this.documentDefinition.content[5].table.body[1 + i] = this.preencherDadosTabela2(i, dados, dadosPdf);
            }
            this.documentDefinition.content[5].table.body[dados.dadosTabela2.length + 1] = [
                {
                    colSpan: dados.coluna2.length,
                    text: this._i18n.get('FINANCEIRO.CAIXA.TABELA.TOTAL') + ': ' + dados.totalTabela2.toString(),
                    alignment: 'right',
                },
            ];
        } else {
            this.documentDefinition.content[5].table.body[1] = [
                {
                    colSpan: dados.coluna2.length,
                    text: this._i18n.get('MENSAGENS.SEMINFORMACAO'),
                    alignment: 'center',
                },
            ];
        }
    }

    private preencherDadosTabela2(i: number, dados: DadosParaExportacaoDuasTabelasDto, dadosPdf: Dictionary<string>): Array<object> {
        const retorno = new Array<object>();

        dados.coluna2.forEach((coluna) => {
            retorno.push({ text: dadosPdf[coluna.id], style: 'row' });
        });

        return retorno;
    }

    public getValoresTotais(dados: DadosParaExportacaoDuasTabelasDto): void {
        this.documentDefinition.content[this.documentDefinition.content.length] = [
            {
                alignment: 'justify',
                columns: [
                    {
                        text: this._i18n.get('FINANCEIRO.CAIXA.TABELA.TOTAL'),
                        alignment: 'left',
                    },
                    {
                        text: dados.valorTotal.toString(),
                        alignment: 'right',
                    },
                ],
                margin: [0, 20, 0, 4],
            },
        ];
        this.documentDefinition.content[this.documentDefinition.content.length] = [
            {
                alignment: 'justify',
                columns: [
                    {
                        text: this._i18n.get('FINANCEIRO.CAIXA.TABELA.TOTAL_PREVISTO'),
                        alignment: 'left',
                    },
                    {
                        text: dados.valorPrevisto.toString(),
                        alignment: 'right',
                    },
                ],
                margin: [0, 4, 0, 4],
            },
        ];
    }
}
