<div mat-dialog-actions fxLayoutAlign="end" class="mt-8">
    <button
        type="button"
        class="btn-cancelar mr-4"
        ngClass.gt-xs="mr-8"
        color="accent"
        (click)="this.cancelarEmit()"
        [disabled]="this.disabledCancel"
        mat-stroked-button
    >
        {{ 'BOTOES.CANCELAR' | translate }}
    </button>
    <button type="button" color="accent" (click)="this.salvarEmit()" [disabled]="this.disabledSave" mat-raised-button>
        {{ 'BOTOES.SALVAR' | translate }}
    </button>
</div>
