import { FormGroup } from '@angular/forms';

/**
 * Marks all controls in a form group as touched
 * @param formGroup - The form group to touch
 */
export function markFormGroupTouched(formGroup: FormGroup): void {
    // tslint:disable-next-line: no-any
    (Object as any).values(formGroup.controls).forEach(control => {
        control.markAsTouched();

        if (control.controls) {
            return markFormGroupTouched(control);
        }
    });
}

// tslint:disable-next-line: no-any
export function markControlTouched(control: any): void {
    control.markAsTouched();

    if (control.controls) {
        return markFormGroupTouched(control);
    }
}
