import { ModoSalvar } from 'app/_models';

export class Foto<TService> {
    src: string;
    id: number;
    titulo: string;
    service: TService;
    logoMarca?: boolean;
    modoSalvar: ModoSalvar;
    semBorda?: boolean;
    width?: string;
    height?: string;
    texto?: string;
}
