import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Estado } from 'app/_models/domain/estado';
import { Cidade } from 'app/_models/domain/cidade';
import { CidadeEstadoDTO } from 'app/_models/domain/cidade-estado-DTO';

@Injectable({
    providedIn: 'root',
})
export class DomainService {
    constructor(private http: HttpClient) {}

    public listarEstados(): Observable<any> {
        return this.http.get<string>(`${environment.apiUrl}endereco/listarestado/`);
    }

    public listarEstadosCompleto(): Observable<Array<Estado>> {
        return this.http.get<Array<Estado>>(`${environment.apiUrl}endereco/ListarEstados`);
    }

    public listarCidadesPorId(uf: number): Observable<Array<Cidade>> {
        return this.http.get<Array<Cidade>>(`${environment.apiUrl}endereco/ListarCidadesPorUf/${uf}`);
    }

    public listarCidadeEstadoPorCodIbge(codIbge: number): Observable<CidadeEstadoDTO> {
        return this.http.get<CidadeEstadoDTO>(`${environment.apiUrl}endereco/Cep/${codIbge}`);
    }

    public enderecoPorCep(cep: string): Observable<any> {
        return this.http.get<any>(`https://app.lifesys.com.br/ws/api/cep/api/cep/cepIbge/${cep}`);
    }

    async verificarApiOnline(): Promise<boolean> {
        try {
            await this.http.get<string>(`${environment.apiUrl}`).toPromise();
            return true;
        } catch (e) {
            return false;
        }
    }
}
