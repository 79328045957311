export class DadosAfiliadoEnderecoDTO {
    bairro: string;
    caminhoFoto: string;
    cep: string;
    cidade: string;
    cpf: string;
    logradouro: string;
    email: string;
    nome: string;
    numero?: number;
    telefone: string;
    uf: string;
}
