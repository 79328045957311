export function getValorMonetario(valorUs: number): string {
    if (!valorUs) {
        return '0,00';
    }

    let valorBr = valorUs.toLocaleString();

    if (valorBr.length > 2 && valorBr.charAt(valorBr.length - 2) === ',') {
        valorBr += '0';
    } else if (valorBr.length < 3 || valorBr.charAt(valorBr.length - 3) !== ',') {
        valorBr += ',00';
    }

    return valorBr;
}
