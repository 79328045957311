import { Injectable } from '@angular/core';
import { AuthenticationService } from '..';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseControllerService } from './base-controller.service';
import { ResultadoController } from 'app/_models';
import { DadosPagamento } from 'app/_models/dados-pagamento/dados-pagamento';
import { ResultadoDadosPagamento } from 'app/_models/dados-pagamento/resultado/resultado-dados-pagamento';
import { RoleUsuario } from 'app/_models/_enums/role-usuario';

@Injectable({ providedIn: 'root' })
export class DadosPagamentoAfiliadosControllerService extends BaseControllerService {
    constructor(_http: HttpClient, _authenticationService: AuthenticationService) {
        super(_http, _authenticationService);
    }

    public selecionar(): Observable<ResultadoDadosPagamento> {
        return this.get(`DadosPagamentoAfiliados/Selecionar`);
    }

    public incluir(dados: DadosPagamento): Observable<ResultadoController> {
        return this.post(`DadosPagamentoAfiliados/Incluir`, dados);
    }

    public alterar(dados: DadosPagamento): Observable<ResultadoController> {
        return this.post(`DadosPagamentoAfiliados/Alterar`, dados);
    }

    public selecionarPorAdmin(usuarioId: number, roleUsuario: RoleUsuario): Observable<ResultadoDadosPagamento> {
        return this.get(`DadosPagamentoAfiliados/Selecionar/${usuarioId}/${roleUsuario}`);
    }
}
