<form [formGroup]="formFile">
    <input
        type="file"
        formControlName="file"
        accept="image/jpeg, image/png, image/bmp, .pdf, .rar, .zip"
        (change)="this.anexar($event)"
        style="display: none"
        id="material-apoio-file"
    />
</form>

<div mat-dialog-title class="title">
    <div id="editar-material-apoio" fxLayout="row" *ngIf="this.data.modoSalvar === this.modoSalvar.Put">
        <span>{{ 'TITULOS.EDITAR_MATERIAL' | translate }}</span>
    </div>
    <div id="add-material-apoio" fxLayout="row" *ngIf="this.data.modoSalvar === this.modoSalvar.Post">
        <span>{{ 'TITULOS.ADICIONAR_MATERIAL' | translate }}</span>
    </div>
</div>

<div mat-dialog-content>
    <div fxLayout="row wrap" fxLayoutAlign="start">
        <div class="container-anexar-file" *ngIf="this.tipoAnexo == this.tipoFile.ANEXO" (click)="this.anexarFile()">
            <span id="selecionar" *ngIf="!this.mostrarImg">Selecionar um arquivo</span>
            <span id="img" *ngIf="this.mostrarImg">
                <img src="assets/icons/pdf-icon.png" class="no-img" alt="{{ this.nomeArquivo }}" />
                <p>{{ this.nomeArquivo }}</p>
            </span>
        </div>
        <div class="container-anexar-img" *ngIf="this.tipoAnexo == this.tipoFile.IMAGEM" (click)="this.anexarFile()">
            <span id="img" *ngIf="this.mostrarImg">
                <img [src]="this.showImg" alt="{{ this.nomeArquivo }}" class="tem-img" />
                <p>{{ this.nomeArquivo }}</p>
            </span>
        </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="start">
        <div mat-dialog-title class="title mt-20" fxLayout="row">
            <div>
                <span>{{ 'LABELS.DETALHES' | translate }}</span>
            </div>
        </div>
        <form name="form" [formGroup]="this.form" fxFlex="100" fxLayout="row">
            <!-- Descrição -->
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label> {{ 'LABELS.DESCRICAO_ARQUIVO' | translate }} </mat-label>
                <input id="input-descricao" required matInput maxlength="100" type="text" formControlName="descricao" />
                <mat-error *ngIf="this.form.get('descricao').hasError('required')">{{ 'FORM.VALIDACOES.CAMPO_OBRIGATORIO' | translate }}</mat-error>
            </mat-form-field>
        </form>
    </div>
</div>

<div mat-dialog-actions fxLayoutAlign="start" class="mt-8">
    <div fxFlex="30">
        <button
            type="button"
            color="accent"
            mat-raised-button
            style="width: 1rem"
            (click)="this.excluir()"
            *ngIf="this.data.modoSalvar === this.modoSalvar.Put"
        >
            <img src="assets/icons/lixeira.png" alt="lixeira" />
        </button>
    </div>
    <div fxLayoutAlign="end" fxFlex="70">
        <button type="button" class="btn-cancelar mr-4" (click)="this.dialogRef.close(false)" color="accent" mat-stroked-button>
            {{ 'BOTOES.CANCELAR' | translate }}
        </button>
        <button type="button" color="accent" (click)="this.salvar()" mat-raised-button [disabled]="this.fuseProgressBarService.visibleValue">
            <span *ngIf="!this.fuseProgressBarService.visibleValue">{{ 'BOTOES.SALVAR' | translate }}</span>
            <span *ngIf="this.fuseProgressBarService.visibleValue" class="spinner-btn"><mat-spinner></mat-spinner></span>
        </button>
    </div>
</div>
