<div class="w-100-p p-100">
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="text-center">
        <img src="assets/images/logos/afiliados.png" alt="Logo Afiliados Amplum" />
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center center" class="mt-24">
        <div fxFlex="40" fxFlex.xs="100">
            <p>
                {{ 'INFO_BROWSER.MENSAGEM' | translate }}
            </p>
            <p>
                {{ 'INFO_BROWSER.LINKS' | translate }}
                <a class="text-decoration-none" href="https://www.google.com.br/chrome/">
                    {{ 'INFO_BROWSER.CHROME' | translate }}
                </a>
                {{ 'INFO_BROWSER.OU' | translate }}
                <a class="text-decoration-none" href="https://www.mozilla.org/pt-BR/firefox/new/">
                    {{ 'INFO_BROWSER.FIREFOX' | translate }}
                </a>
            </p>
        </div>
    </div>
</div>
