import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ItemAcaoSelecionada } from './item-acao-selecionada.model';
import { AuthenticationService } from 'app/_services';

@Component({
    selector: 'lista-acoes',
    templateUrl: './lista-acoes.component.html',
    styleUrls: ['./lista-acoes.component.scss']
})
export class ListaAcoesComponent implements OnInit {
    @Input() set setTextoFiltro(texto: string) {
        this.filtrar(texto);
    }

    @Input() set setItensLista(itens: Array<any>) {
        this.listaOriginal = itens;
        this.filtrar(this._textoFiltro);
    }

    @Input() habilitarAcoes: boolean = false;
    @Input() nomeColunaDescricao: string = 'descricao';
    @Input() AcaoSelecionada: ItemAcaoSelecionada;
    @Input() rotuloColunaValor: string = 'Descrição';
    @Input() placeholderFiltro: string = 'Filtrar';
    @Input() tamanhoCard: number;
    @Output() alterarAtivo: EventEmitter<any> = new EventEmitter();
    @Output() editar: EventEmitter<any> = new EventEmitter();
    @Output() excluir: EventEmitter<any> = new EventEmitter();

    private _textoFiltroAnterior: string;
    public listaOriginal: Array<any>;
    public listaFiltrada: Array<any>;
    abaExame: boolean = false;
    public noFilter: boolean = false;
    inputFiltro: FormControl;
    displayedColumns: Array<any> = [];
    private _textoFiltro: string;

    constructor(private _authenticationService: AuthenticationService) {}

    ngOnInit(): void {
        this.setDisplayedColunns();
    }

    get idUsuario(): number {
        return this._authenticationService.currentUserValue.idUsuario;
    }

    filtrar(filterValue: string): void {
        this._textoFiltro = filterValue;

        if (this._textoFiltroAnterior === this._textoFiltro && this.listaFiltrada === this.listaOriginal) {
            return;
        }

        this._textoFiltroAnterior = this._textoFiltro;

        if (!this.listaOriginal || !this._textoFiltro) {
            this.listaFiltrada = this.listaOriginal;
            return;
        }

        filterValue = filterValue.trim();
        filterValue = filterValue.toLowerCase();
        filterValue = this.ignorarAcentos(filterValue);
        this.listaFiltrada = this.listaOriginal.filter(item => this.ignorarAcentos(item.descricao.toLowerCase()).includes(filterValue));
        this.listaFiltrada.length > 0 ? (this.noFilter = false) : (this.noFilter = true);
    }

    setDisplayedColunns(): void {
        this.displayedColumns.push(this.nomeColunaDescricao);

        if (this.habilitarAcoes) {
            this.displayedColumns.push('acoes');
        }
    }

    onAlterarAtivo(item: any): void {
        this.alterarAtivo.emit(item);
    }

    onEditar(item: any): void {
        this.editar.emit(item);
    }

    onExcluir(item: any): void {
        this.excluir.emit(item);
    }

    private ignorarAcentos(texto: string): string {
        return texto.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
}
