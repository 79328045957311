import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'termo-uso',
    templateUrl: './termo-uso.component.html',
    styleUrls: ['./termo-uso.component.scss'],
})
export class TermoUsoComponent {
    public termoAceito: boolean = false;

    constructor(public dialogRef: MatDialogRef<TermoUsoComponent>) {}
}
