import { TraducaoService, AuthenticationService } from 'app/_services';
import { format } from 'date-fns';
import { TipoAnexo } from 'app/_models/enums/tipo-anexo';

const bordaDebug = false;
const semBorda: Array<boolean> = [bordaDebug, bordaDebug, bordaDebug, bordaDebug];
const bordaTopo: Array<boolean> = [bordaDebug, true, bordaDebug, bordaDebug];

export function montarFooterPDFLocalAtendimento(
    i18n: TraducaoService,
    margens: Array<number> = [0, 0],
    paginaAtual: number = null,
    totalPaginas: string = null
): object {
    const linhasTabelas = new Array<Array<object>>();

    linhasTabelas.push([
        {
            text: 'Gerado em: amplum.solutions/afiliados/app -' + new Date().toLocaleString(),
            alignment: 'left',
            fontSize: 6,
            border: semBorda
        },
        paginaAtual
            ? {
                  text: paginaAtual.toString() + '/' + totalPaginas,
                  alignment: 'right',
                  fontSize: 8,
                  border: semBorda
              }
            : celulaVazia()
    ]);

    return {
        table: {
            widths: ['*', 'auto'],
            body: linhasTabelas
        },
        margin: margens
    };
}

export function montarHeaderPdfLocalAtendimento(
    i18n: TraducaoService,
    authenticationService: AuthenticationService,
    via: number = null,
    data: Date,
    margens: Array<number>,
    tipoPdf?: TipoAnexo
): object {
    let possuiLogo = false;
    const logoHeight = 50;
    const linhasTabelas = new Array<Array<object>>();

    linhasTabelas.push([
        {
            colSpan: 3,
            text: format(data, 'DD/MM/YYYY - HH:mm'),
            fontSize: 8,
            alignment: 'right',
            border: bordaTopo
        },
        {},
        {}
    ]);

    return {
        table: {
            widths: [possuiLogo ? 'auto' : -10, '*', via ? 'auto' : -10],
            body: linhasTabelas
        },
        margin: margens,
        fontSize: 10
    };
}

function celulaVazia(): object {
    return { text: '', border: semBorda };
}

export function getAlturaHeader(possuiDadosLocal: boolean, possuidadosPaciente: boolean): number {
    const alturaDadosLocal = 70;
    const alturaDadosPaciente = 60;
    const alturaLinhaData = 20;

    let alturaHeader = 0;

    if (possuiDadosLocal) {
        alturaHeader += alturaDadosLocal;
    }

    if (possuidadosPaciente) {
        alturaHeader += alturaDadosPaciente;
    }

    if (possuiDadosLocal || possuidadosPaciente) {
        alturaHeader += alturaLinhaData;
    }

    return alturaHeader;
}

export function getAlturaFooter(
    possuiDadosLocal: boolean = true,
    possuiDadosProfissional: boolean = false,
    possuiAssinatura: boolean = false
): number {
    const alturaDadosLocal = 40;
    const alturaDadosProfissional = 85;
    const alturaLinhaData = 20;
    const alturaAssinatura = 60;

    let alturaFooter = 0;

    if (possuiDadosLocal) {
        alturaFooter += alturaDadosLocal;
    }

    if (possuiDadosProfissional) {
        alturaFooter += alturaDadosProfissional;
    }

    if (possuiAssinatura) {
        alturaFooter += alturaAssinatura;
    }

    if (possuiDadosLocal || possuiDadosProfissional) {
        alturaFooter += alturaLinhaData;
    }
    return alturaFooter;
}
