import { Injectable } from '@angular/core';
import { BaseControllerService } from './base-controller.service';
import { HttpClient } from '@angular/common/http';
import { AuthenticationService } from '..';
import { Observable } from 'rxjs';
import { FeedbackDTO } from 'app/_models/feedback/feedback-dto';
import { ResultadoController } from 'app/_models/global/resultado-controller';

@Injectable({ providedIn: 'root' })
export class FeedbackControllerService extends BaseControllerService {
    constructor(_http: HttpClient, _authenticationService: AuthenticationService) {
        super(_http, _authenticationService);
    }

    public incluirFeeback(feedbackDTO: FeedbackDTO): Observable<ResultadoController> {
        return this.post<ResultadoController, FeedbackDTO>(`Feedback`, feedbackDTO);
    }
}
