import { FiltroData } from '../global/filtro-data';

export class FiltroListarPagamentosDto extends FiltroData {
    situacoesPagamento: Array<number>;

    constructor(situacoesPagamento: Array<number>) {
        super();

        this.situacoesPagamento = situacoesPagamento;
    }
}
