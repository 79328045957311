<div class="page-layout carded fullwidth inner-scroll h-100-p">
    <div class="center">
        <!-- Titulo fora do card -->
        <div class="row-titulo-rota" fxLayout="row wrap">
            <mat-icon>
                <ng-content select=".icone-padrao"></ng-content>
            </mat-icon>
            <h2 class="titulo-pagina">
                <ng-content select=".titulo-padrao"></ng-content>
            </h2>
        </div>
        <!-- Card -->
        <div class="content-card card-padrao">
            <div class="corpo-card-simples ">
                <ng-content select=".body-card-padrao"></ng-content>
            </div>
        </div>
    </div>
</div>
