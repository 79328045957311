import { DatePipe } from '@angular/common';
import { TraducaoService } from 'app/_services';
import { ListaColunas } from 'app/main/componentes/lista-colunas-acoes/lista-colunas-acoes.model';
import { TamanhosFlexLayout } from 'app/_models/enums/tamanhos-flex-layout';
import { TipoDado } from 'app/_models/enums/tipo-dado';
import { ColunaExportacao } from 'app/_models/exportacao/coluna-exportacao';
import { DadosParaExportacaoBasicoDto } from 'app/_models/exportacao/dados-para-exportacao-basico-dto';
import { Dictionary } from 'lodash';
import { IndicadoresIndicacoes } from 'app/_models/conta-profissional/indicadores-indicacoes';
import { Indicacoes } from 'app/_models/conta-profissional/indicacoes';
import { IndicacoesPerfilBaseService } from 'app/main/inicio/indicacoes-conversoes/indicacoes/indicacoes-perfil-base.service';
import { ExportarPdfService } from 'app/_services/exportacao/exportar-pdf.service';
import { ExportaXLSXService } from 'app/_services/exportacao/exporta-xlsx.service';

export class IndicacoesAdministradorService extends IndicacoesPerfilBaseService {
    constructor(
        protected _indicadores: IndicadoresIndicacoes,
        protected _indicacoes: Array<Indicacoes>,
        protected _i18n: TraducaoService,
        private _exportarPdfService: ExportarPdfService,
        private _exportaXLSXService: ExportaXLSXService,
        private datePipe: DatePipe
    ) {
        super(_indicadores, _i18n);
    }

    public getColunas(): Array<ListaColunas> {
        const colunasBase = this.getColunasBase();

        const colunasAdmin = new Array<ListaColunas>(
            {
                descricao: this._i18n.get('LABELS.AFILIADO'),
                visibilidade: TamanhosFlexLayout.xs,
                key: 'nomeAfiliado',
                maxLengthKey: 17,
                tipoDado: TipoDado.TEXTO,
                position: 1,
            },
            {
                descricao: this._i18n.get('LABELS.CPF'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'cpf',
                tipoDado: TipoDado.CPF,
                position: 3,
            },
            {
                descricao: this._i18n.get('LABELS.DADOS_CONSELHO'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'dadosConselho',
                tipoDado: TipoDado.INNER_HTML,
                position: 4,
            },
            {
                descricao: this._i18n.get('LABELS.EMAIL'),
                visibilidade: TamanhosFlexLayout.md,
                key: 'email',
                position: 5,
                maxLengthKey: 13,
                tipoDado: TipoDado.TEXTO,
            }
        );

        const colunasAdminAdicionais = new Array<ListaColunas>({
            descricao: this._i18n.get('LABELS.DIAS_RESTANTES'),
            visibilidade: TamanhosFlexLayout.xs,
            key: 'diasRestantes',
            tipoDado: TipoDado.INNER_HTML_CENTRALIZADO,
        });

        return colunasAdmin.concat(colunasBase).concat(colunasAdminAdicionais);
    }

    public getMensagemInstrucao(): string {
        return `${this._i18n.get('MENSAGENS.INSTRUCAO_INDICACOES_ADMIN')}`;
    }

    public exportarParaPdf(indicacoes: Array<Indicacoes>): void {
        this._indicacoesParaExportar = indicacoes;
        const dadosExportacao = this.getDadosParaExportacaoBasicoDto();
        this._exportarPdfService.gerarPdfAfiliados(dadosExportacao);
    }

    public formatarData(data: Date | string): string {
        return this.datePipe.transform(data, 'dd/MM/yyyy');
    }

    public exportarParaExcel(itens: Array<Indicacoes>): void {
        const retorno = new Array<Dictionary<string>>();
        itens.forEach((conversao) => {
            const dict = {
                'Afiliado': conversao.nomeAfiliado,
                'Profissional': conversao.nome,
                'Data Cadastro': this.formatarData(conversao.dataCadastro),
                'CPF': conversao.cpf,
                'Dados Conselho': conversao.dadosConselho,
                'E-mail': conversao.email,
                'Telefone': !!conversao.telefone ? conversao.telefone.toString() : '',
                'Plano': conversao.nomePlano,
                'Situação': conversao.situacaoDescricao,
                'Últ. acesso': this.formatarData(conversao.ultimoAcesso),
            };
            retorno.push(dict);
        });

        this._exportaXLSXService.exportAsExcelFile(retorno, 'indicacoes');
    }

    protected getDadosParaExportacaoBasicoDto(): DadosParaExportacaoBasicoDto {
        const colunasParaExportacao = [
            new ColunaExportacao('nomeAfiliado', 'Afiliado', 1, 50),
            new ColunaExportacao('nome', 'Profissional', 1, 50),
            new ColunaExportacao('dataCadastro', 'Cadastro', 2, 50),
            new ColunaExportacao('cpf', 'CPF', 3, 50),
            new ColunaExportacao('dadosConselho', 'Dados Conselho', 3, 50),
            new ColunaExportacao('email', 'Email', 3, 60),
            new ColunaExportacao('telefone', 'Telefone', 4, 50),
            new ColunaExportacao('nomePlano', 'Plano', 5, 40),
            new ColunaExportacao('situacaoDescricao', 'Situação', 7, 25),
            new ColunaExportacao('ultimoAcesso', 'Últ. acesso', 6, 50),
        ];

        const dadosParaExportacaoBasicoDto = new DadosParaExportacaoBasicoDto(
            colunasParaExportacao,
            this.mapearDadosPdf(),
            this._i18n.get('LABELS.INDICACOES')
        );

        return dadosParaExportacaoBasicoDto;
    }

    protected mapearDadosPdf(): Array<Dictionary<string>> {
        const retorno = new Array<Dictionary<string>>();

        this._indicacoesParaExportar.forEach((indicacao) => {
            const dict = {
                nomeAfiliado: indicacao.nomeAfiliado,
                dadosConselho: indicacao.dadosConselho,
                cpf: indicacao.cpf,
                nome: indicacao.nome,
                dataCadastro: this.formatarData(indicacao.dataCadastro),
                email: indicacao.email,
                telefone: !!indicacao.telefone ? indicacao.telefone.toString() : '',
                nomePlano: indicacao.nomePlano,
                ultimoAcesso: this.formatarData(indicacao.ultimoAcesso),
                situacaoDescricao: indicacao.situacaoDescricao,
            };

            retorno.push(dict);
        });

        return retorno;
    }
}
